import React from 'react';
// import Icon from '@core/Utils/Icons';
import { appButtonTypes } from '@utils/globalConstant';
import { ButtonPrimary } from '../ButtonPrimary';
import { Modal } from '../Utils/Modal';
import './PopupAutotopUpMoreInfo.scss';

export function PopupAutotopUpMoreInfo({
  isOpen,
  title,
  bodyText,
  buttonLabel,
  onClose
}) {
  // const imgPath = appImages[image];

  return (
    <Modal isOpen={isOpen} onClose={onClose} modalClass="popup-image">
      <div className="container-md">
        <div className="row">
          <div className="mx-auto col-lg-8 col-md-8 col-sm-7 col-xs-12 px-6 px-sm-0 px-md-3">
            <div className="modal-main-content mx-auto">
              <div className="modal-header border-0 mt-4 pt-4">
                <h3 className="modal-title" dangerouslySetInnerHTML={{ __html: title }} />
              </div>
              <div className="modal-body" dangerouslySetInnerHTML={{ __html: bodyText }} />
              <div className="modal-button mt-4 pt-4 pb-8">
                <ButtonPrimary
                  buttonType={appButtonTypes.PRIMARY.DARK}
                  label={buttonLabel || 'Button'}
                  onClick={onClose}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PopupAutotopUpMoreInfo;
