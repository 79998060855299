/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { useActivation } from '@context/MobileOne';
import { appTariffBulletGroup, appTariffPriceType, appButtonTypes } from '@utils/globalConstant';
import { TariffProduct } from '@part/TariffProduct';
import { ButtonPrimary } from '@core/index';

import './Step3.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export function ActivationStep3() {
  const sliderRef = useRef(null);
  const [tariffDetail, setTariffDetail] = useState(null);
  const { t, mediaPdf } = useStaticContent();
  const {
    bookableTariffs,
    selectedTariffId,
    setSelectedTariffId,
    tariffActivationFormSubmit,
    setCurrentSubStep3,
    currentSubStep3,
    tariffActivationForm,
    setTariffActivationForm
  } = useActivation();

  // const [selectedTariff, setSelectedTariff] = useState({
  //   chosenTariffId: 0,
  //   chosenTariffName: '',
  //   tariffColor: ''
  // });

  const settings = {
    dots: true,
    arrows: false,
    speed: 500,
    infinite: false,
    centerMode: true, // centers active slide
    centerPadding: 0, // removes padding around center slide
    variableWidth: true,
    initialSlide: 0,
    focusOnSelect: true,
    beforeChange: (current, next) => {
      if (bookableTariffs && bookableTariffs.length > 0 && bookableTariffs[next]) {
        const {
          id = 0,
          name = '',
          additionalInfo: { primaryColor } = { primaryColor: '' }
        } = bookableTariffs[next];
        setTariffActivationForm({
          chosenTariffId: id,
          chosenTariffName: name,
          tariffColor: primaryColor
        });
      }
    }
  };

  // Functions
  const getTariffPDF = () => {
    const tariff = bookableTariffs.find(({ id }) => Number(id) === Number(selectedTariffId));
    const tariffPdfId = tariff
      ? tariff.additionalInfo
        ? tariff.additionalInfo.document
          ? tariff.additionalInfo.document.productInformation
            ? tariff.additionalInfo.document.productInformation.id
              ? tariff.additionalInfo.document.productInformation.id
              : 0
            : 0
          : 0
        : 0
      : 0;
    const infoPdf = mediaPdf.find(({ id }) => Number(id) === tariffPdfId);
    return infoPdf?.media_url;
  };

  // Hooks
  useEffect(() => {
    if (bookableTariffs && tariffActivationForm.chosenTariffId !== 0) {
      const tariff = bookableTariffs.find(({ id }) => id === tariffActivationForm.chosenTariffId);
      setTariffDetail(tariff);
    }
  }, [tariffActivationForm]);

  useEffect(() => {
    if (bookableTariffs && selectedTariffId !== 0 && !tariffDetail) {
      const tariff = bookableTariffs.find(({ id }) => id === selectedTariffId);
      if (tariff && tariff.additionalInfo) {
        setTariffDetail(tariff);
        setTariffActivationForm({
          chosenTariffId: tariff.id || 0,
          chosenTariffName: tariff.name || '',
          tariffColor: tariff.additionalInfo.primaryColor || ''
        });
      }
    } else {
      const tariff = bookableTariffs.find(({ id }) => id);
      if (tariff && tariff.additionalInfo) {
        setTariffDetail(tariff);
        setTariffActivationForm({
          chosenTariffId: tariff.id || 0,
          chosenTariffName: tariff.name || '',
          tariffColor: tariff.additionalInfo.primaryColor || ''
        });
      }
    }
  }, []);

  return (
    <div className="tariffs-slider-page animate__animated animate__slideInRight">
      <div className="container-md pb-1">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
            <h3 className="nc-doomsday-h3">{t('nc_reg_step3_hdl')}</h3>
          </div>
        </div>
      </div>

      {currentSubStep3 === 0 && (
        <div className="container-fluid px-0">
          <div className="tariff-activation-slider">
            <Slider {...settings} className="" ref={sliderRef}>
              {bookableTariffs.map((tariff) => (
                <div className="mx-3"  key={`tariff-activation-product-${tariff.id}`}>
                  <TariffProduct
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showRibbonText={tariff.recommended}
                    showBulletGroup={[
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE
                    ]}
                  />
                </div>
              ))}
            </Slider>
          </div>

          <div className="text-center mt-12">
            <ButtonPrimary
              label={t('nc_reg_step3_btn')}
              icon="arrowright"
              buttonType={appButtonTypes.PRIMARY.DEFAULT}
              disabled={tariffActivationForm.chosenTariffId === 0}
              onClick={() => {
                setSelectedTariffId(tariffActivationForm.chosenTariffId);
                setCurrentSubStep3(currentSubStep3 + 1);
                // tariffActivationFormSubmit(selectedTariff);
              }}
            />
          </div>
        </div>
      )}
      {currentSubStep3 === 1 && tariffDetail && (
        <div className="container-md d-flex flex-column align-items-center">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-10 col-xs-12 mx-auto slick-active slick-current">
              <div className="d-flex justify-content-center">
                <TariffProduct
                  sizeVariant="large"
                  tariffInfo={tariffDetail.additionalInfo}
                  priceType={appTariffPriceType.PRICE}
                  showRibbonText={tariffDetail.recommended}
                  showBulletGroup={[
                    appTariffBulletGroup.DATA,
                    appTariffBulletGroup.DESCRIPTION_ONE
                  ]}
                />
              </div>
            </div>
            <div className="text-center mt-8">
              <Link to={getTariffPDF()} className="nc-doomsday-footnote-link">
                {t('nc_reg_step3_detail_txt')}
              </Link>
            </div>
          </div>

          <div className="text-center pt-12">
            <ButtonPrimary
              label={t('nc_reg_step3_detail_btn')}
              icon="arrowright"
              buttonType={appButtonTypes.PRIMARY.DEFAULT}
              disabled={tariffActivationForm.chosenTariffId === 0}
              onClick={() => {
                setSelectedTariffId(tariffActivationForm.chosenTariffId);
                tariffActivationFormSubmit(tariffActivationForm);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ActivationStep3;
