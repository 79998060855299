/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { ButtonPrimary } from '@core/ButtonPrimary';
import { appButtonTypes } from '@utils/globalConstant';
import { NavigationPageTitle } from '../NavigationPageTitle';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import './PdfScreen.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export function PdfScreen({ pdf, onBackClick }) {
  // States
  const [numPages, setNumPages] = useState();
  const [displayUrl, setDisplayUrl] = useState();
  // const [pageNumber, setPageNumber] = useState(1);
  // Contexts
  const { t, mediaPdf } = useStaticContent();

  // Functions
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Hooks
  useEffect(() => {
    setDisplayUrl(mediaPdf?.find(({ id }) => Number(id) === pdf.pdfId)?.media_url_display)
  }, [pdf])

  return (
    <div>
      <NavigationPageTitle title={pdf.name} isBackButton onButtonClick={onBackClick} variant="lg" />
      <div className="row">
        <div className="col-12 text-center py-8">
          <ButtonPrimary
            label={t('nc_reg_step5_dwnld_btn') || 'Download'}
            buttonType={appButtonTypes.PRIMARY.DEFAULT}
            onClick={() => window.open(pdf.redirectionURL, '_blank')}
          />
        </div>
      </div>
      <div>
        {/* Show PDF using iframe */}
        <Document
          file={displayUrl}
          externalLinkRel="noopener noreferrer"
          externalLinkTarget="_blank"
          onLoadSuccess={onDocumentLoadSuccess}
          loading={t('nc_reg_step5_pdf_txt')}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              size="A4"
              renderTextLayer={false}
              renderAnnotationLayer={false}
              loading={t('nc_reg_step5_pdf_txt')}
              renderMode='canvas'
              // customRenderer={({ canvasContext, viewport, renderTextLayer }) => {
              //   console.log('canvasContext', canvasContext);
              // }}
            />
          ))}
        </Document>
        {/* {[...Array(numPages)]
            .map((x, i) => i + 1)
            .map((page) => (
              <Page
                key={page} // Added key prop
                style={styles}
                size="A4"
                pageNumber={page}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            ))} */}

        {/* <iframe
          id="iframeTag"
          // src="https://www.openstreetmap.org/export/embed.html?bbox=-0.004017949104309083%2C51.47612752641776%2C0.00030577182769775396%2C51.478569861898606&layer=mapnik"
          src={pdf.url}
          width="100%"
          height={window.innerHeight - 200}
          title={pdf.name}
        /> */}
        {/* <object
          src="https://www.openstreetmap.org/export/embed.html?bbox=-0.004017949104309083%2C51.47612752641776%2C0.00030577182769775396%2C51.478569861898606&layer=mapnik"
          // src={pdf.url}
          width="100%"
          height={window.innerHeight - 200}
          title={pdf.name}
        >
          <p>
            Your browser does not support PDF files.
            <a target='_blank' rel='noreferrer' href={pdf.url}>Download the file instead</a>
          </p>
        </object> */}
        {/* <embed
          src={pdf.url}
          frameBorder="0"
          width="100%"
          height={window.innerHeight - 200}
        /> */}
      </div>
    </div>
  );
}

export default PdfScreen;
