import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button, DropDown, Text } from '@core/Inputs';
import { useActivation } from '@context/MobileOne';
import EditIcon from '@theme/assets/img/bearbeiten.svg';
import { appTextFieldLabelClass } from '@utils/globalConstant';
// import { appTextFieldLabelClass } from '@utils/globalConstant';

export function YourData({
  // salutation,
  firstName,
  lastName,
  birthDate,
  street,
  houseNumber,
  zip,
  city,
  emailAddress,
  // identityType,
  // identityNumber,
  // nationality,
  // placeOfIssue,
  // validUntil,
  handleBlur,
  handleChange,
  errors,
  touched,
  isEditable,
  setIsEditable,
  countryCode
}) {
  // Context
  const { countries } = useActivation(); // salutations
  const { t } = useStaticContent();
  const selsectedCountry = countries.find((country) => country.id === countryCode);

  // States
  const [selectedCountry, setSelectedCountry] = useState(selsectedCountry);

  return (
    <>
      <div className="card mx-auto">
        <div className="card-body">
          <div className="card-edit d-flex justify-content-between">
            <h4>{t('ek_ap_overview_subheader3')}</h4>

            <Button type="button" className="float-end" onClick={() => setIsEditable(!isEditable)}>
              <img src={EditIcon} alt="editicon" />
            </Button>
          </div>
          {/* <div className="col-md-12">
            <Select
              label="Anrede"
              labelCustomClass={appTextFieldLabelClass.V1}
              id="salutation"
              name="salutation"
              options={salutations}
              value={salutation}
              onChange={(e) =>
                handleChange({
                  target: {
                    id: 'salutation',
                    name: 'salutation',
                    value: e.target.value
                  }
                })
              }
              getOptionId={(option) => option.id}
              getOptionName={(option) => option.value}
              isValid={touched.salutation && !errors.salutation}
              isInvalid={!!touched.salutation && !!errors.salutation}
              onBlur={handleBlur}
              error={touched.salutation && errors.salutation ? errors.salutation : null}
              isDisabled={!isEditable}
            />
          </div> */}

          <div className="col-md-12">
            <Text
              type="text"
              labelCustomClass={appTextFieldLabelClass.V1}
              label={t('ek_ap_personal_first-name')}
              id="firstName"
              name="firstName"
              onChange={handleChange}
              value={firstName}
              placeholder={t('ek_ap_personal_first-name_input')}
              isValid={touched.firstName && !errors.firstName}
              isInvalid={!!touched.firstName && !!errors.firstName}
              onBlur={handleBlur}
              error={touched.firstName && errors.firstName ? errors.firstName : null}
              isDisabled={!isEditable}
            />
          </div>
          <div className="col-md-12">
            <Text
              type="text"
              labelCustomClass={appTextFieldLabelClass.V1}
              label={t('ek_ap_personal_last-name')}
              id="lastName"
              name="lastName"
              onChange={handleChange}
              value={lastName}
              placeholder={t('ek_ap_personal_last-name_input')}
              isValid={touched.lastName && !errors.lastName}
              isInvalid={!!touched.lastName && !!errors.lastName}
              onBlur={handleBlur}
              error={touched.lastName && errors.lastName ? errors.lastName : null}
              isDisabled={!isEditable}
            />
          </div>

          <div className="col-md-12">
            <Text
              type="date"
              labelCustomClass={appTextFieldLabelClass.V1}
              label={t('ek_ap_personal_birth-date')}
              inputCustomClass="editable"
              id="birthDate"
              name="birthDate"
              onChange={handleChange}
              value={birthDate}
              placeholder={t('ek_ap_personal_birth-date_input')}
              isValid={touched.birthDate && !errors.birthDate}
              isInvalid={!!touched.birthDate && !!errors.birthDate}
              onBlur={handleBlur}
              error={touched.birthDate && errors.birthDate ? errors.birthDate : null}
              isDisabled={!isEditable}
            />
          </div>

          <div className="col-md-12">
            <Text
              type="text"
              labelCustomClass={appTextFieldLabelClass.V1}
              label={t('ek_ap_personal_street')}
              id="street"
              name="street"
              onChange={handleChange}
              value={street}
              placeholder={t('ek_ap_personal_street_input')}
              isValid={touched.street && !errors.street}
              isInvalid={!!touched.street && !!errors.street}
              onBlur={handleBlur}
              error={touched.street && errors.street ? errors.street : null}
              isDisabled={!isEditable}
            />
          </div>

          <div className="col-md-12">
            <Text
              type="text"
              labelCustomClass={appTextFieldLabelClass.V1}
              label={t('ek_ap_personal_house-no')}
              id="houseNumber"
              name="houseNumber"
              onChange={handleChange}
              value={houseNumber}
              placeholder={t('ek_ap_personal_house-no_input')}
              isValid={touched.houseNumber && !errors.houseNumber}
              isInvalid={!!touched.houseNumber && !!errors.houseNumber}
              onBlur={handleBlur}
              error={touched.houseNumber && errors.houseNumber ? errors.houseNumber : null}
              isDisabled={!isEditable}
            />
          </div>
          <div className="col-md-12">
            <Text
              type="text"
              labelCustomClass={appTextFieldLabelClass.V1}
              label={t('ek_ap_personal_zip')}
              id="zip"
              name="zip"
              onChange={handleChange}
              value={zip}
              placeholder={t('ek_ap_personal_zip_input')}
              isValid={touched.zip && !errors.zip}
              isInvalid={!!touched.zip && !!errors.zip}
              onBlur={handleBlur}
              error={touched.zip && errors.zip ? errors.zip : null}
              isDisabled={!isEditable}
            />
          </div>

          <div className="col-md-12">
            <Text
              type="text"
              labelCustomClass={appTextFieldLabelClass.V1}
              label={t('ek_ap_personal_town')}
              id="city"
              name="city"
              onChange={handleChange}
              value={city}
              placeholder={t('ek_ap_personal_town_input')}
              isValid={touched.city && !errors.city}
              isInvalid={!!touched.city && !!errors.city}
              onBlur={handleBlur}
              error={touched.city && errors.city ? errors.city : null}
              isDisabled={!isEditable}
            />
          </div>

          <div className="col-md-12">
            <Text
              type="text"
              labelCustomClass={appTextFieldLabelClass.V1}
              inputCustomClass="not-editable"
              label={t('ek_ap_personal_email1')}
              id="emailAddress"
              name="emailAddress"
              onChange={handleChange}
              value={emailAddress}
              placeholder={t('ek_ap_personal_email1_input')}
              isValid={touched.emailAddress && !errors.emailAddress}
              isInvalid={!!touched.emailAddress && !!errors.emailAddress}
              onBlur={handleBlur}
              error={touched.emailAddress && errors.emailAddress ? errors.emailAddress : null}
              isDisabled
            />
          </div>

          <div className="d-flex justify-content-between pt-4 mt-2 custom_dropdown">
            <div className="w-40">
              <label className="form-label" htmlFor="countryCode">
                {t('ek_ap_personal_country')}
              </label>
            </div>
            <div className="toggle-dropdown">
              <DropDown
                label={t('ek_ap_personal_country_input')}
                id="countryCode"
                name="countryCode"
                apiData={countries}
                setSelectedItem={(e) => {
                  setSelectedCountry(e);
                  handleChange({
                    target: {
                      id: 'countryCode',
                      name: 'countryCode',
                      value: e.id
                    }
                  });
                }}
                selectedItem={selectedCountry}
                // isInvalid={!!touched.countryCode && !!errors.countryCode}
                error={touched.countryCode && errors.countryCode ? errors.countryCode : null}
                isDisabled={!isEditable}
              />
            </div>
          </div>
          {/* <br />
        <Col xs={6}>
          <Select
            label={t('ek_ap_personal_id')}
            id="identityType"
            name="identityType"
            defaultOption={t('ek_ap_personal_id_input')}
            options={identityTypes}
            value={identityType}
            onChange={(e) =>
              handleChange({
                target: {
                  id: 'identityType',
                  name: 'identityType',
                  value: e.target.value
                }
              })
            }
            getOptionId={(option) => option.id}
            getOptionName={(option) => option.value}
            isValid={touched.identityType && !errors.identityType}
            isInvalid={!!touched.identityType && !!errors.identityType}
            onBlur={handleBlur}
            error={touched.identityType && errors.identityType ? errors.identityType : null}
            isDisabled={!isEditable}
          />
        </Col>
        <br />
        <Col xs={6}>
          <Text
            type="text"
            label={t('ek_ap_personal_id-nr')}
            id="identityNumber"
            name="identityNumber"
            onChange={handleChange}
            value={identityNumber}
            placeholder={t('ek_ap_personal_id-nr_input')}
            isValid={touched.identityNumber && !errors.identityNumber}
            isInvalid={!!touched.identityNumber && !!errors.identityNumber}
            onBlur={handleBlur}
            error={touched.identityNumber && errors.identityNumber ? errors.identityNumber : null}
            isDisabled={!isEditable}
          />
        </Col>
        <br />
        <Col xs={6}>
          <Select
            label={t('ek_ap_personal_nationality')}
            id="nationality"
            name="nationality"
            defaultOption={t('ek_ap_personal_nationality_input')}
            options={countries}
            value={nationality}
            onChange={(e) =>
              handleChange({
                target: {
                  id: 'nationality',
                  name: 'nationality',
                  value: e.target.value
                }
              })
            }
            getOptionId={(option) => option.id}
            getOptionName={(option) => option.value}
            isValid={touched.nationality && !errors.nationality}
            isInvalid={!!touched.nationality && !!errors.nationality}
            onBlur={handleBlur}
            error={touched.nationality && errors.nationality ? errors.nationality : null}
            isDisabled={!isEditable}
          />
        </Col>
        <br />
        <Col xs={6}>
          <Text
            type="text"
            label={t('ek_ap_personal_authority')}
            id="placeOfIssue"
            name="placeOfIssue"
            onChange={handleChange}
            value={placeOfIssue}
            placeholder={t('ek_ap_personal_authority_input')}
            isValid={touched.placeOfIssue && !errors.placeOfIssue}
            isInvalid={!!touched.placeOfIssue && !!errors.placeOfIssue}
            onBlur={handleBlur}
            error={touched.placeOfIssue && errors.placeOfIssue ? errors.placeOfIssue : null}
            isDisabled={!isEditable}
          />
        </Col>
        <br />
        <Col xs={6}>
          <Text
            type="date"
            label={t('ek_ap_personal_validation-date')}
            id="validUntil"
            name="validUntil"
            onChange={handleChange}
            value={validUntil}
            placeholder={t('ek_ap_personal_validate-date_input')}
            isValid={touched.validUntil && !errors.validUntil}
            isInvalid={!!touched.validUntil && !!errors.validUntil}
            onBlur={handleBlur}
            error={touched.validUntil && errors.validUntil ? errors.validUntil : null}
            isDisabled={!isEditable}
          />
        </Col> */}
        </div>
      </div>
    </>
  );
}

YourData.propTypes = {
  // salutation: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  birthDate: PropTypes.string,
  street: PropTypes.string,
  houseNumber: PropTypes.string,
  emailAddress: PropTypes.string,
  // identityType: PropTypes.string,
  // identityNumber: PropTypes.string,
  // nationality: PropTypes.string,
  // placeOfIssue: PropTypes.string,
  // validUntil: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({})
};
YourData.defaultProps = {
  // salutation: 0,
  firstName: '',
  lastName: '',
  birthDate: '',
  street: '',
  houseNumber: '',
  emailAddress: '',
  // identityType: '',
  // identityNumber: '',
  // nationality: '',
  // placeOfIssue: '',
  // validUntil: '',
  handleChange: () => {},
  handleBlur: () => {},
  touched: () => {},
  errors: {}
};

export default YourData;
