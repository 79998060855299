import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@core/Inputs';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appTextFieldLabelClass } from '@utils/globalConstant';

export function Contact({
  // emailAddress,
  alternateNumber,
  alternateNumberPrefix,
  handleChange,
  handleBlur,
  touched,
  errors
}) {
  const { t } = useStaticContent();
  return (
    <>
      <h4 className="mt-5 mb-3">{t('ek_ap_personal_h3')}</h4>

      <div className="row">
        {/* <Text
          type="text"
          labelCustomClass={appTextFieldLabelClass.V1}
          label={t('ek_ap_personal_email1')}
          id="emailAddress"
          name="emailAddress"
          onChange={handleChange}
          value={emailAddress}
          placeholder={t('ek_ap_personal_email1_input')}
          isValid={touched.emailAddress && !errors.emailAddress}
          isInvalid={!!touched.emailAddress && !!errors.emailAddress}
          onBlur={handleBlur}
          error={touched.emailAddress && errors.emailAddress ? errors.emailAddress : null}
          isDisabled
        /> */}

        <Text
          type="text"
          labelCustomClass={appTextFieldLabelClass.V1}
          label={t('ek_ap_personal_prefix-alternative')}
          id="alternateNumberPrefix"
          name="alternateNumberPrefix"
          onChange={handleChange}
          value={alternateNumberPrefix}
          placeholder={t('ek_ap_personal_prefix-alternative_input')}
          isValid={touched.alternateNumberPrefix && !errors.alternateNumberPrefix}
          isInvalid={!!touched.alternateNumberPrefix && !!errors.alternateNumberPrefix}
          onBlur={handleBlur}
          error={
            touched.alternateNumberPrefix && errors.alternateNumberPrefix
              ? errors.alternateNumberPrefix
              : null
          }
        />

        <Text
          type="text"
          labelCustomClass={appTextFieldLabelClass.V1}
          label={t('ek_ap_personal_nr')}
          toolTip={t('ek_ap_nr_info')}
          id="alternateNumber"
          name="alternateNumber"
          onChange={handleChange}
          value={alternateNumber}
          placeholder={t('ek_ap_personal_nr_input')}
          isValid={touched.alternateNumber && !errors.alternateNumber}
          isInvalid={!!touched.alternateNumber && !!errors.alternateNumber}
          onBlur={handleBlur}
          error={touched.alternateNumber && errors.alternateNumber ? errors.alternateNumber : null}
        />
      </div>
    </>
  );
}

Contact.propTypes = {
  // emailAddress: PropTypes.string,
  alternateNumberPrefix: PropTypes.string,
  alternateNumber: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({})
};

Contact.defaultProps = {
  // emailAddress: '',
  alternateNumberPrefix: '',
  alternateNumber: '',
  handleChange: () => {},
  handleBlur: () => {},
  touched: () => {},
  errors: {}
};

export default Contact;
