import React from 'react'
import { Icons } from '@core/Utils';
import { ButtonPrimary } from '@core/ButtonPrimary';
import { appButtonTypes } from '@utils/globalConstant';

export function AfterPaymentScreen({
  icon,
  heading,
  bodyText,
  buttonLabel,
  onButtonClick,
  buttonIcon,
}) {
  return (
    <div className="row">
      {/* <div className="col-xl-8 col-xl-8 col-lg-6 col-md-10 col-sm-8 col-xs-8 mx-auto"> */}
        <div className="text-center">
          {icon && <Icons name={icon} />}
          {heading && <h3 className="nc-doomsday-h3 py-8 m-0" dangerouslySetInnerHTML={{ __html: heading }} />}
          {bodyText && <p className="pb-8 m-0" dangerouslySetInnerHTML={{ __html: bodyText }} />}
          <div className="button-div pt-4">
            <ButtonPrimary
              icon={buttonIcon}
              type="button"
              buttonType={appButtonTypes.PRIMARY.DEFAULT}
              label={buttonLabel || 'Button'}
              onClick={onButtonClick}
            />
          </div>
        </div>
      {/* </div> */}
     </div>
  );
};

export default AfterPaymentScreen;
