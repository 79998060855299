import React, { useEffect } from 'react';
import { useActivation } from '@context/MobileOne';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { Meta } from '@core/Meta';

// Import each ActivationStep component separately
import { ActivationStep1 } from './Step1';
import { ActivationStep2 } from './Step2';
import { ActivationStep3 } from './Step3';
import { ActivationStep4 } from './Step4';
import { ActivationStep5 } from './Step5';
import { ActivationStep6 } from './Step6';

export function Activation() {
  const { t } = useStaticContent();
  const { currentStep, getLookup } = useActivation();

  const getCurrentStep = () => {
    switch (currentStep + 1) {
      case 1:
        return <ActivationStep1 />;
      case 2:
        return <ActivationStep2 />;
      case 3:
        return <ActivationStep3 />;
      case 4:
        return <ActivationStep4 />;
      case 5:
        return <ActivationStep5 />;
      case 6:
        return <ActivationStep6 />;
      default:
        return <ActivationStep1 />;
    }
  };

  useEffect(() => {
    getLookup();
  }, []);

  // return <div className="container-page3 overflow-x-hidden">{getCurrentStep()}</div>;
  return (
    <>
      <Meta title={t('nc_registration_page_title')} />
      <div className="container-page3 overflow-x-hidden">{getCurrentStep()}</div>
    </>
  );
}

export default Activation;
