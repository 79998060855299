import React from 'react';
import { Icons } from '@core/Utils';
import './DashboardActiveOption.scss';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export function DashboardActiveOption({
  type = '',
  volume = '',
  volumePercentage = 0,
  amount = '',
  remainingPeriod = '',
  stageType = '',
  noCounterAvailable = false
}) {
  const { t } = useStaticContent();

  return (
    <div className="dashboard-active-option rounded-4 bg-white p-4 shadow-xs animate__animated animate__zoomIn animate__delay-1s">
      <div className="d-flex align-items-center justify-content-start">
        <div>
          <Icons name="speedgray100" />
        </div>
        <div>
          <p className="nc-realheadpro-subhead text-gray-100 m-0 ps-2 pt-1">{type}</p>
        </div>
      </div>
      <div className="row pt-6 px-4">
        {stageType === 'SSD' || noCounterAvailable ? (
          <>
            <div className="col-12 p-0">
              <h4 className="nc-doomsday-h4 text-orange text-start m-0">
                {stageType === 'SSD'
                  ? t('nc_dboard_generic_card_stage_ssd_txt')
                  : t('nc_no_data_available')}
              </h4>
            </div>
          </>
        ) : (
          <>
            <div className="col-8 d-flex align-items-center justify-content-start p-0">
              <h3 className="nc-doomsday-h3 text-orange text-left m-0">{volume}</h3>
            </div>
            <div className="col-4 d-flex align-items-center justify-content-end p-0">
              <p className="nc-realtextpro-copy m-0">{amount}</p>
            </div>
          </>
        )}
        <div className="col-12 py-1 p-0">
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100">
            <div
              className="progress-bar bg-orange rounded-pill"
              style={{
                width: `${volumePercentage}%`
              }}
            />
          </div>
        </div>
        <div className="col-12 p-0 pt-2">
          <p className="nc-realtextpro-footnote text-gray-100 m-0 text-start">{remainingPeriod}</p>
        </div>
      </div>
    </div>
  );
}

export default DashboardActiveOption;
