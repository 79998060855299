/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { Icons } from '@core/Utils/Icons';
import './DashboardTariff.scss';
import { SemiCircleProgressBar } from '@core/SemiCircleProgressBar';
import { TarifProductNavigation } from '@part/TarifProductNavigation';
import { appTariffStatus, appRoute } from '@utils/globalConstant';

const DEFAULT_USAGE = {
  headlineText: ' ',
  headlineText2: ' ',
  subHeadLineText: ' ',
  endDate: false,
  noData: false,
  volumePercentage: 0
};

export function DashboardTariff({
  status = appTariffStatus.ACTIVE,
  number = '123456789',
  tariffInfo: { primaryColor, title } = { primaryColor: '', title: '' },
  usageInfo: { VOICE, SMS, DATA } = {
    VOICE: DEFAULT_USAGE,
    SMS: DEFAULT_USAGE,
    DATA: DEFAULT_USAGE
  },
}) {
  const [volumePercentage, setVolumePercentage] = useState(DATA.volumePercentage);
  const [headlineText, setHeadlineText] = useState(DATA.headlineText);
  const [headlineText2, setHeadlineText2] = useState(DATA.headlineText2);
  const [subHeadLineText, setSubHeadLineText] = useState(DATA.subHeadLineText);
  const [endDate, setEndDate] = useState(DATA.endDate);
  const [noData, setNoData] = useState(DATA.noData);
  const [currentView, setCurrentView] = useState('DATA');

  const navigate = useNavigate();
  const { t } = useStaticContent();

  useEffect(() => {
    if (currentView === 'VOICE') {
      setHeadlineText(VOICE.headlineText);
      setHeadlineText2('');
      setSubHeadLineText(VOICE.subHeadLineText);
      setEndDate(VOICE.endDate);
      setNoData(false);
      setVolumePercentage(VOICE.volumePercentage);
    } else if (currentView === 'DATA') {
      setHeadlineText(DATA.headlineText);
      setHeadlineText2(DATA.headlineText2);
      setSubHeadLineText(DATA.subHeadLineText);
      setEndDate(DATA.endDate);
      setNoData(DATA.noData);
      setVolumePercentage(DATA.volumePercentage);
    } else if (currentView === 'SMS') {
      setHeadlineText(SMS.headlineText);
      setHeadlineText2('');
      setSubHeadLineText(SMS.subHeadLineText);
      setEndDate(SMS.endDate);
      setNoData(false);
      setVolumePercentage(SMS.volumePercentage);
    }
  }, [DATA, SMS, VOICE, currentView]);

  const onChange = (i) => {
    if (i === 0) {
      setHeadlineText(VOICE.headlineText);
      setHeadlineText2('');
      setSubHeadLineText(VOICE.subHeadLineText);
      setEndDate(VOICE.endDate);
      setCurrentView('VOICE');
      setVolumePercentage();
    } else if (i === 1) {
      setHeadlineText(DATA.headlineText);
      setHeadlineText2(DATA.setHeadlineText2);
      setSubHeadLineText(DATA.subHeadLineText);
      setEndDate(DATA.endDate);
      setCurrentView('DATA');
    } else if (i === 2) {
      setHeadlineText(SMS.headlineText);
      setHeadlineText2('');
      setSubHeadLineText(SMS.subHeadLineText);
      setEndDate(SMS.endDate);
      setCurrentView('SMS');
    }
  };

  if (status === appTariffStatus.IN_CHANGE || status === appTariffStatus.ACTIVATION_PENDING) {
    return (
      <div className="dashboard-tariff shadow-xs p-5 animate__animated animate__flipInY">
        <div className="row pt-10">
          <div className="col-12 d-flex justify-content-center">
            <Icons name="coffeemint" />
          </div>
          <div className="col-12 py-5">
            <h3
              className="nc-nc-doomsday-h3 text-center px-md-8 px-sm-4 px-xs-0 mx-10 pb-8 m-0"
              dangerouslySetInnerHTML={{ __html: t('nc_dboard_tarif_change') }}
            />
            <p className="nc-realtextpro-copy text-gray-40 px-lg-4 text-center m-0">
              {t('nc_dboard_tarif_change_txt')}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (status === appTariffStatus.PAUSED) {
    return (
      <div className="dashboard-tariff shadow-xs p-5 animate__animated animate__flipInY h-100">
        <div className="row pt-10">
          <div className="col-12 d-flex justify-content-center">
            <Icons name="pausemint" />
          </div>
          <div className="col-12 py-5">
            <h3
              className="nc-nc-doomsday-h3 text-center px-md-8 px-sm-4 px-xs-0 mx-10 pb-8 m-0"
              dangerouslySetInnerHTML={{ __html: t('nc_dboard_tarif_pause') }}></h3>
            <p className="nc-realtextpro-copy text-gray-40 px-lg-4 text-center m-0">
              {t('nc_dboard_tarif_pause_txt')}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (status === appTariffStatus.LOCKED) {
    return (
      <div className="dashboard-tariff shadow-xs p-5 animate__animated animate__flipInY h-100">
        <div className="row my-10 py-10">
          <div className="col-12 d-flex justify-content-center">
            <Icons name="pausemint" />
          </div>
          <div className="col-12 py-10">
            <h3 className="nc-nc-doomsday-h3 text-center px-xl-16 px-lg-12 px-md-8 px-sm-4 px-xs-0 mx-10 pb-8 m-0">
              Deine SIM Karte ist <span className="text-mint-100">gesperrt.</span>
            </h3>
            <p className="nc-realtextpro-copy text-gray-40 px-lg-4 text-center m-0">
              Verlängere deinen Tarif um NORMA connect weiterhin zu nutzen.
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (status === appTariffStatus.ERROR) {
    return (
      <div className="dashboard-tariff shadow-xs p-5 animate__animated animate__flipInY h-100">
        <div className="row pt-10">
          <div className="col-12 d-flex justify-content-center">
            <Icons name="loupegreen" />
          </div>
          <div className="col-12 py-5">
            <h3
              className="nc-nc-doomsday-h3 text-center px-md-8 px-sm-4 px-xs-0 mx-10 pb-8 m-0"
              dangerouslySetInnerHTML={{ __html: t('nc_dboard_tarif_error') }}></h3>
            <p className="nc-realtextpro-copy text-gray-40 px-lg-4 text-center m-0">
              {t('nc_dboard_tarif_error_txt')}
            </p>
          </div>
        </div>
      </div>
    );
  }

  // status = appTariffStatus.ACTIVE;
  return (
    <div className="dashboard-tariff shadow-xs p-5 animate__animated animate__flipInY h-100">
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <div
            className=""
            style={{ color: primaryColor }}
            dangerouslySetInnerHTML={{ __html: title?.dashboardCardHead || '' }}
          />
          <button
            type="button"
            className="border-0 bg-transparent p-0"
            aria-label="Arrows Icon"
            onClick={() => navigate(appRoute.TARIFF_OVERVIEW)}>
            <Icons name="darkarrows" />
          </button>
        </div>
        <div className="col-12">
          <p
            className="nc-realtextpro-copy text-gray-80 m-0"
            dangerouslySetInnerHTML={{ __html: number }}
          />
        </div>
      </div>
      <div className="row py-10 px-0">
        <div className="col-12 justify-self-center px-5">
          <TarifProductNavigation onChange={onChange} bubbleColor={primaryColor} />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 text-center pt-0 mt-n4">
          <SemiCircleProgressBar
            percentage={volumePercentage}
            strokeColor={primaryColor}
            headlineText={headlineText}
            headlineText2={headlineText2}
            subHeadLineText={subHeadLineText}
            noData={noData}
          />
        </div>
        {endDate ? (
          <div className="col-12 text-center nc-realtextpro-footnote text-gray-60 pt-4">
            {endDate}
          </div>
        ) : (
          <div className="col-12 text-center nc-realtextpro-footnote text-gray-60 pt-4" />
        )}
      </div>
    </div>
  );
}

export default DashboardTariff;
