import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { appButtonTypes, appImages, appRoute } from '@utils/globalConstant';
import { ButtonPrimary, Meta } from '@core/index';

import './Home.scss';
import { useAlert } from '@context/Utils';

export function Home() {
  // Contexts
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const { setIsGenericError, setAlert } = useAlert();

  // Functions
  const onActivationClick = () => {
    navigate(appRoute.ACTIVATION_EMAIL_VERIFICATION);
  };

  const onLoginClick = () => {
    navigate(appRoute.LOGIN);
  };

  // Hooks
  useEffect(() => {
    setIsGenericError(false);
    setAlert({});
  }, []);

  return (
    <>
      <Meta
        title={t('nc_home_page_title')}
        // description={t('nc_home_meta_description') || t('nc_global_meta_description')}
        // keywords={t('nc_home_meta_keywords') || t('nc_global_meta_keywords')}
      />
      <div className="app-home-page container-sm">
        <div className="row container-page2">
          <div className="mx-auto col-lg-8 px-6 px-sm-3">
            <div className="position-relative z-1">
              <h1
                className="nc-doomsday-h1 pb-16 pb-sm-12 pb-md-16 text-center w-100 animation-list animate__animated animate__backInDown"
                dangerouslySetInnerHTML={{
                  __html: t('nc_home_hdl')
                }}
              />

              <div className="row gx-4 gy-4 gy-md-0 pb-8 animation-list animate__animated animate__zoomIn">
                <div className="col-sm-6 col-xs-12 text-sm-end text-center">
                  <ButtonPrimary
                    label={t('nc_home_btn_login')}
                    buttonType={appButtonTypes.PRIMARY.DEFAULT}
                    onClick={onLoginClick}
                  />
                </div>
                <div className="col-sm-6 col-xs-12 text-sm-start text-center">
                  <ButtonPrimary
                    label={t('nc_home_btn_activation')}
                    buttonType={appButtonTypes.PRIMARY.MINT}
                    onClick={onActivationClick}
                  />
                </div>
              </div>

              <div className="pb-16 text-center w-100 animation-list animate__animated animate__zoomIn">
                <div
                  className="nc-doomsday-footnote-link"
                  dangerouslySetInnerHTML={{ __html: t('nc_home_txt') }}
                />
              </div>
            </div>
          </div>

          <div className="app-home-image bottom-0 z-0 animate__animated animate__fadeIn d-flex align-items-end justify-content-center">
            <img
              className="img-fluid"
              style={{ height: '251px' }}
              src={appImages.homePage}
              alt="home page img"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
