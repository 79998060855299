import React from 'react';
import { Outlet } from 'react-router-dom';

import { NavigationHeader } from '@part/NavigationHeader';
import { NavigationFooter } from '@part/NavigationFooter';
import { Alert, LoadingScreen } from '@core/index';
import { useAlert, useLayout, useLoader } from '@context/Utils';

export function MainLayout({
  showChildren = false,
  children
}) {
  const { loader } = useLoader();
  const { isLoading, showHeader } = useLayout();
  const { isGenericError } = useAlert();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div className="bg-mint-40 min-vh-100 d-flex flex-column overflow-x-hidden">
        {showHeader && <NavigationHeader />}
        {loader && <LoadingScreen />}

        <main className="flex-grow-1">
          <Alert />
          {!isGenericError && <Outlet />}
          {showChildren && children}
        </main>

        <NavigationFooter />
      </div>
    </>
  );
}

export default MainLayout;



// import React, { useEffect, useState } from 'react';
// import { Outlet, useLocation } from 'react-router-dom';

// import { NavigationHeader } from '@part/NavigationHeader';
// import { NavigationFooter } from '@part/NavigationFooter';
// import { Alert, LoadingScreen } from '@core/index';
// import { useLayout, useLoader } from '@context/Utils';

// export function MainLayout({ showChildren = false, children }) {
//   const { loader } = useLoader();
//   const { isLoading } = useLayout();
//   const location = useLocation();

//   const [displayLocation, setDisplayLocation] = useState(location);
//   const [transitionStage, setTransistionStage] = useState('fadeIn');

//   useEffect(() => {
//     if (location !== displayLocation) setTransistionStage('fadeIn');
//   }, [location, displayLocation]);

//   if (isLoading) {
//     return <LoadingScreen />;
//   }

//   return (
//     <>
//       <div className="bg-mint-40 min-vh-100 d-flex flex-column">
//         <NavigationHeader />
//         {loader && <LoadingScreen />}

//         <main className="flex-grow-1">
//           <div
//             className={`animate__animated animate__${transitionStage}`}
//             onAnimationEnd={() => {
//               if (transitionStage === 'fadeOut') {
//                 setTransistionStage('fadeIn');
//                 setDisplayLocation(location);
//               }
//             }}>
//             <Alert />
//             <Outlet />
//             {showChildren && children}
//           </div>
//         </main>

//         <NavigationFooter />
//       </div>
//     </>
//   );
// }

// export default MainLayout;
