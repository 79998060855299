import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Form, Formik } from 'formik';
import { useActivation, useDocument } from '@context/MobileOne';

import { FormInput, ButtonPrimary, Badge, ButtonToggle, Player } from '@core/index';

import { appButtonTypes } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';

export function SIMVerification() {
  // States
  const [selectedVid, setSelectedVid] = useState({});
  const { t } = useStaticContent();
  const { isLoading, phoneNumberActivationForm, phoneNumberValidations, phoneNumberSubmit } =
    useActivation();
  const { activationVideo } = useDocument();
  const { isGenericError, setIsGenericError, setAlert } = useAlert();

  // Hooks
  useEffect(() => {
    setIsGenericError(false);
    setAlert({});
  }, []);

  return (
    <>
      {!isGenericError && (
        <div className="animate__animated animate__fadeIn">
          <div className="pb-5">
            <h3 className="nc-doomsday-h3">{t('nc_reg_step1_hdl')}</h3>
          </div>
          <Formik
            initialValues={phoneNumberActivationForm}
            validationSchema={phoneNumberValidations}
            onSubmit={phoneNumberSubmit}>
            {({
              values: { msisdn, puk, mnp },
              handleBlur,
              handleChange,
              errors,
              touched,
              handleSubmit
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="step-1-form row">
                  <div className="col-xl-6 col-xs-12 py-xl-5 py-3 pb-3">
                    <FormInput
                      id="msisdn"
                      name="msisdn"
                      type="number"
                      placeholder={t('nc_reg_step1_input_phonenumber')}
                      autoComplete="off"
                      startIcon="phonecolor"
                      value={msisdn}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            id: 'msisdn',
                            name: 'msisdn',
                            value: e.target.value
                          }
                        });
                      }}
                      valid={touched.msisdn && !errors.msisdn}
                      invalid={!!touched.msisdn && !!errors.msisdn}
                      onBlur={handleBlur}
                      invalidMessage={touched.msisdn && errors.msisdn ? errors.msisdn : null}
                    />
                  </div>
                  <div className="col-xl-6 col-xs-12 py-xl-5 py-0 pb-4">
                    <FormInput
                      id="puk"
                      name="puk"
                      type="number"
                      placeholder={t('nc_reg_step1_input_puk')}
                      autoComplete="off"
                      startIcon="keycolor"
                      value={puk}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            id: 'puk',
                            name: 'puk',
                            value: e.target.value
                          }
                        });
                      }}
                      valid={touched.puk && !errors.puk}
                      invalid={!!touched.puk && !!errors.puk}
                      onBlur={handleBlur}
                      invalidMessage={touched.puk && errors.puk ? errors.puk : null}
                      rightIcon="infomint"
                      tooltipTitle={t('nc_reg_step1_info_puk')}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-xs-12">
                    <div className="d-flex flex-column align-items-start justify-content-center">
                      <div>
                        <Badge title={t('nc_reg_step1_badge_recommendation')} />
                      </div>
                      <div className="pt-6 pb-1 pb-lg-4">
                        <ButtonToggle
                          label={t('nc_reg_step1_radiobutton_keep_phonenumber')}
                          id="mnp"
                          name="mnp"
                          value={mnp}
                          onChange={(e) => {
                            handleChange({
                              target: {
                                id: 'mnp',
                                name: 'mnp',
                                value: e
                              }
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-xs-12 pt-5 pb-0 py-lg-0 ps-lg-0 px-xl-4">
                    <Player
                      video={activationVideo}
                      // previewImage={appImages.playerBg}
                      selectedVideo={selectedVid}
                      onSelectVideo={(video) => setSelectedVid(video)}
                    />
                  </div>
                  <div className="col-12 text-center pt-8 pt-sm-12">
                    <ButtonPrimary
                      type="submit"
                      label={t('nc_reg_step1_btn')}
                      icon="arrowright"
                      isLoading={isLoading}
                      disabled={errors.msisdn || !msisdn || errors.puk || !puk || isLoading}
                      buttonType={appButtonTypes.PRIMARY.DEFAULT}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

export default SIMVerification;
