import React from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText
  // CardTitle, CardImg,
} from '@core/Layout/Card';
import PropTypes from 'prop-types';
import { Button } from '@core/Inputs';
import { appRoute } from '@utils/globalConstant';
import { useNavigate } from 'react-router-dom';

/**
 * Inidividual Option Product
 * TODO: Extend it with different type ACTIVE, INPROGRESS & USAGE etc
 * @param id
 * @param name
 * @param header
 * @param description
 * @param price
 * @param duration
 * @param durationUnit
 * @param bookable
 * @param booked
 * @param cancellable
 * @param endDate
 * @param endTime
 * @returns
 */
export function PassOfferProduct({
  passCode: resPassCode,
  name,
  tariffZonesName: description,
  price: { formattedValue: resPrice },
  duration: { valueFormatted: resDuration },

  isBookableAllowed
}) {
  // Context
  const navigate = useNavigate();

  return (
    <Card>
      {/* <CardImg variant="top" src="https://source.unsplash.com/random/1000x400?cartoon" /> */}
      <CardHeader>
        <strong>
          {name} - {resPassCode}
        </strong>
      </CardHeader>
      <CardBody>
        {/* <CardTitle>Card name</CardTitle> */}
        <CardText>{description}</CardText>
        <div className="row justify-content-md-center p-0">
          <div className="col-lg-12 m-0 p-1">
            <ul className="list-group">
              <li className="list-group-item p-2">Price - {resPrice}</li>
              <li className="list-group-item p-2">Duration - {resDuration}</li>
            </ul>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        {isBookableAllowed && (
          <Button
            variant="primary"
            type="button"
            onClick={() => navigate(appRoute.PASSOFFER_BOOK(resPassCode))}
          >
            Book Now!
          </Button>
        )}
        {!isBookableAllowed && <small className="text-muted">You are not served!</small>}
      </CardFooter>
    </Card>
  );
}

PassOfferProduct.propTypes = {
  passCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  tariffZonesName: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),

  isBookableAllowed: PropTypes.bool
};

PassOfferProduct.defaultProps = {
  passCode: 0,
  name: '',
  tariffZonesName: '',
  price: {},
  duration: {},

  isBookableAllowed: false
};

export default PassOfferProduct;
