import React from 'react';

import { Icons } from '@core/Utils';
import { ButtonPrimary } from '@core/ButtonPrimary';
import { appButtonTypes } from '@utils/globalConstant';

export function TarifFeedbackSuccess({
  icon,
  heading,
  children,
  buttonLabel,
  onButtonClick = () => {},
  buttonIcon
}) {
  return (
    <div className="row">
      <div className="text-center">
        {icon && <Icons name={icon} />}
        {heading && (
          <h3 className="nc-doomsday-h3 py-8 m-0" dangerouslySetInnerHTML={{ __html: heading }} />
        )}
        {children}
        <div className="button-div pt-4">
          <ButtonPrimary
            icon={buttonIcon}
            type="button"
            buttonType={appButtonTypes.PRIMARY.DEFAULT}
            label={buttonLabel || 'Button'}
            onClick={onButtonClick}
          />
        </div>
      </div>
    </div>
  );
}

export default TarifFeedbackSuccess;
