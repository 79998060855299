import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import './NavigationActivationBar.scss';

import { Icons, NumbersCircle, TariffBadge } from '@core/index';
import { useActivation } from '@context/MobileOne';
import { useNavigate } from 'react-router-dom';
import { appRoute, isLocalHost } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';

export function NavigationActivationBar() {
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const { setIsGenericError, setAlert } = useAlert();

  const {
    currentStep,
    setCurrentStep,
    currentSubStep1,
    currentSubStep3,
    currentSubStep6,
    selectedTariffId,
    setCurrentSubStep1,
    setCurrentSubStep3,
    setCurrentSubStep6,
    tariffActivationForm
  } = useActivation();

  // Functions

  const onBackClick = () => {
    setIsGenericError(false);
    setAlert({});
    if (currentSubStep6 > 0 && currentStep === 5) {
      setCurrentSubStep6(currentSubStep6 - 1);
    } else if (currentStep === 0 && currentSubStep1 > 0) {
      setCurrentSubStep1(0);
    } else if (currentStep === 2 && currentSubStep3 > 0) {
      setCurrentSubStep3(0);
    } else if (currentStep - 1 <= -1 && currentSubStep1 === 0) {
      navigate(appRoute.ACTIVATION_EMAIL_VERIFICATION);
    } else {
      setCurrentStep(currentStep - 1 >= 0 && currentStep < 5 ? currentStep - 1 : currentStep);
    }
    // go back to email verification page if current step is 0
  };

  // useEffect(() => {
  //   // setTariffData(
  //   //   staticContentData && staticContentData.tariff_norma ? staticContentData.tariff_norma : []
  //   // );
  //   if (
  //     staticContentData &&
  //     staticContentData.tariff_norma &&
  //     staticContentData.tariff_norma.length > 0
  //   ) {
  //     const { additionalInfo = {} } =
  //       staticContentData.tariff_norma.find((tariff) => tariff.id === selectedTariffId) || {};
  //     if (additionalInfo) {
  //       setTariffData(additionalInfo);
  //     }
  //   }
  //   return () => {
  //     setTariffData();
  //   };
  // }, [staticContentData, selectedTariffId]);

  return (
    <div className="bg-mint-80 d-block activation-navbar">
      <div className="container-sm">
        <div className="row">
          <div className="col-lg-8 mx-auto px-3 px-sm-0 px-md-3">
            <div className="main-content pt-14 border-bottom border-white">
              <div className="upper-div d-flex justify-content-between">
                {currentStep < 5 && (
                  <button
                    type="button"
                    className="border-0 bg-transparent p-0"
                    onClick={onBackClick}
                    aria-label="back-btn">
                    <Icons name="back" width={24} height={24} />
                  </button>
                )}
                <div className="d-flex flex-grow-1 title nc-doomsday-button align-items-center">
                  {currentStep + 1 === 1 && (
                    <p className="mb-0 ms-4">{t('nc_reg_step1_head_title')}</p>
                  )}
                  {currentStep + 1 === 2 && (
                    <p className="mb-0 ms-4">{t('nc_reg_step2_head_title')}</p>
                  )}
                  {currentStep + 1 === 3 && (
                    <p className="mb-0 ms-4">{t('nc_reg_step3_head_title')}</p>
                  )}
                  {currentStep + 1 === 4 && (
                    <p className="mb-0 ms-4">{t('nc_reg_step4_head_title')}</p>
                  )}
                  {currentStep + 1 === 5 && (
                    <p className="mb-0 ms-4">{t('nc_reg_step5_head_title')}</p>
                  )}
                  {currentStep + 1 === 6 && (
                    <p className="mb-0">{t('nc_reg_step6_topup_head_title')}</p>
                  )}
                </div>
                {selectedTariffId > 0 && tariffActivationForm.chosenTariffId !== 0 && (
                  <div className="ms-1">
                    <TariffBadge
                      bgColor={tariffActivationForm.tariffColor}
                      tariffName={tariffActivationForm?.chosenTariffName}
                    />
                  </div>
                )}
              </div>
              <div className="down-div position-relative">
                <div className="stepper-progress position-relative">
                  <ul className="d-flex justify-content-between m-0 p-0 list-unstyled">
                    {new Array(6).fill(0).map((_, index) => {
                      const step = index + 1;
                      const isActive = step === currentStep + 1;
                      const isCompleted = step < currentStep + 1;
                      return (
                        <div key={index}>
                          <NumbersCircle
                            step={step}
                            currentStep={currentStep}
                            onClick={() => isLocalHost() && setCurrentStep(index)}
                            isActive={isActive}
                            isCompleted={isCompleted}
                          />
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigationActivationBar;
