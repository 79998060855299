import React, { useState } from 'react';
import { DropDown, Text } from '@core/Inputs';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appTextFieldLabelClass } from '@utils/globalConstant';

export function AddressDetails({
  countries,
  houseNumber,
  street,
  zip,
  city,
  // countryCode,
  handleChange,
  handleBlur,
  touched,
  errors
}) {
  // States
  const [selectedCountry, setSelectedCountry] = useState({});

  // Contexts
  const { t } = useStaticContent();

  return (
    <>
      <h4 className="mt-5">{t('ek_ap_personal_h2')}</h4>

      <Text
        type="text"
        labelCustomClass={appTextFieldLabelClass.V1}
        label={t('ek_ap_personal_street')}
        id="street"
        name="street"
        onChange={handleChange}
        value={street}
        placeholder={t('ek_ap_personal_street_input')}
        isValid={touched.street && !errors.street}
        isInvalid={!!touched.street && !!errors.street}
        onBlur={handleBlur}
        error={touched.street && errors.street ? errors.street : null}
      />

      <Text
        type="text"
        labelCustomClass={appTextFieldLabelClass.V1}
        label={t('ek_ap_personal_house-no')}
        id="houseNumber"
        name="houseNumber"
        onChange={handleChange}
        value={houseNumber}
        placeholder={t('ek_ap_personal_house-no_input')}
        isValid={touched.houseNumber && !errors.houseNumber}
        isInvalid={!!touched.houseNumber && !!errors.houseNumber}
        onBlur={handleBlur}
        error={touched.houseNumber && errors.houseNumber ? errors.houseNumber : null}
      />

      <Text
        type="text"
        labelCustomClass={appTextFieldLabelClass.V1}
        label={t('ek_ap_personal_zip')}
        id="zip"
        name="zip"
        onChange={handleChange}
        value={zip}
        placeholder={t('ek_ap_personal_zip_input')}
        isValid={touched.zip && !errors.zip}
        isInvalid={!!touched.zip && !!errors.zip}
        onBlur={handleBlur}
        error={touched.zip && errors.zip ? errors.zip : null}
      />

      <Text
        type="text"
        labelCustomClass={appTextFieldLabelClass.V1}
        label={t('ek_ap_personal_town')}
        id="city"
        name="city"
        onChange={handleChange}
        value={city}
        placeholder={t('ek_ap_personal_town_input')}
        isValid={touched.city && !errors.city}
        isInvalid={!!touched.city && !!errors.city}
        onBlur={handleBlur}
        error={touched.city && errors.city ? errors.city : null}
      />

      <div className="d-flex justify-content-between pt-4 mt-2 custom_dropdown address-details">
        <div className="w-40">
          <label className="form-label" htmlFor="countryCode">
            {t('ek_ap_personal_country')}
          </label>
        </div>
        <div className="toggle-dropdown">
          <DropDown
            label={t('ek_ap_personal_country_placeholder')}
            id="countryCode"
            name="countryCode"
            apiData={countries}
            setSelectedItem={(e) => {
              setSelectedCountry(e);
              handleChange({
                target: {
                  id: 'countryCode',
                  name: 'countryCode',
                  value: e.id
                }
              });
            }}
            selectedItem={selectedCountry}
            // isInvalid={!!touched.countryCode && !!errors.countryCode}
            error={touched.countryCode && errors.countryCode ? errors.countryCode : null}
          />
        </div>
      </div>
    </>
  );
}

AddressDetails.propTypes = {
  houseNumber: PropTypes.string,
  street: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
  countryCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({})
};

AddressDetails.defaultProps = {
  houseNumber: '',
  street: '',
  zip: '',
  city: '',
  countryCode: '',
  handleChange: () => {},
  handleBlur: () => {},
  touched: () => {},
  errors: {}
};

export default AddressDetails;
