/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
// import background from '@theme/assets/img/customer_place.png';

export function YourTariff({ bookableTariffs, chosenTariffId, mediaImages }) {
  // Contexts

  const tariff = bookableTariffs.find(({ id }) => id === chosenTariffId);
  const tariffPrimaryColor = tariff !== null ? tariff?.additionalInfo?.primaryColor : '';
  const tariffImage =
    tariff !== null
      ? mediaImages.find((img) => img?.image_ref === tariff?.additionalInfo?.image_ref)
      : {};

  return (
    <>
      <div className="row">
        <div className="mb-0 pb-0">
          <div
            style={{
              backgroundImage: `url(${
                tariffImage?.responsive_image ? tariffImage?.responsive_image['1x'] : ''
              }),linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.2) 100%) `
            }}
            className="card tarrif-card mx-auto"
            border="primary"
            key={`tariff-product-${tariff?.id}`}
            // eslint-disable-next-line react/no-unknown-property
          >
            <div className="card-body">
              <h2 style={{ color: tariffPrimaryColor }} className="card-title text-uppercase">
                {tariff?.name}
              </h2>
              <div className="py-3">
                <ul>
                  {tariff !== null && tariff !== undefined ? (
                    tariff.additionalInfo !== null && tariff.additionalInfo.bullets.length > 0 ? (
                      tariff.additionalInfo.bullets.map(({ text }) => (
                        <li key={`bullet-item-${text}`}>
                          <i
                            style={{ color: tariff.additionalInfo.primaryColor }}
                            className="bi bi-chevron-right"
                          />
                          <div dangerouslySetInnerHTML={{ __html: text }} />
                        </li>
                      ))
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </ul>
              </div>

              <div className="py-2">
                <hr className="my-1 p-0" />
              </div>
              <div className="py-2">
                <div className="tarrif-total">
                  <p className="mb-0 tarrif-text-bottom">
                    <span>{tariff?.price}€ </span> / {tariff?.duration}&nbsp; {tariff?.durationUnit}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

YourTariff.propTypes = {
  bookableTariffs: PropTypes.arrayOf(PropTypes.shape([])),
  chosenTariffId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
YourTariff.defaultProps = {
  bookableTariffs: [],
  chosenTariffId: ''
};

export default YourTariff;
