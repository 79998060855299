// ////////////////////////////////////////////////////////////////////////////
// Before working on this file please read https://rm5.dom.de/issues/51197 ////
// ////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState } from 'react';
import { appPassCodes, appTariffBulletGroup, appTariffPriceType } from '@utils/globalConstant';

import './TariffOption.scss';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export function DayFlatOption({
  offerInfo = {
    passCode: 'GDPVDD01',
    name: 'DayFlat light',
    passType: 103,
    validityPeriod: 5,
    downloadBandwidthFormatted: '',
    uploadBandwidthFormatted: '',
    dailyCharge: null,
    smsCode: 'dayflat',
    tariffZones: [
      {
        id: 'TMDE_LG_DOMESTIC',
        domestic: true,
        roaming: false,
        name: 'Deutschland'
      }
    ],
    tariffZonesName: 'Deutschland',
    suppressZoneInfo: true,
    sortKey: 0,
    price: {
      grossAmount: 10000,
      taxValue: 1597,
      currency: '€',
      formattedValue: '1,- €'
    },
    duration: {
      milliseconds: 86400000,
      valueFormatted: '24 Stunden'
    },
    ssdDownloadBandwidthFormatted: '64 kbit/s',
    ssdUploadBandwidthFormatted: '16 kbit/s',
    requiresConfirmation: true,
    replacedPasses: [],
    suppressPrice: false,
    monetaryData: null,
    volumeData: {
      coveredByBundle: false,
      volume: {
        bytes: 26214400,
        formattedValue: '25 MB'
      },
      suppressVolume: false
    }
  },
  sizeVariant = 'small' // large, small
}) {
  const {
    passType,
    name,
    price: { formattedValue: priceFormattedValue },
    price,
    duration: { milliseconds },
    volumeData: {
      volume: { bytes, formattedValue: volumeFormattedValue }
    }
  } = offerInfo;

  const { t, staticContentData } = useStaticContent();

  const { dayFlatUnlimitedThreshold } =
    staticContentData?.nr_passCodesSettings || appPassCodes.dayFlatUnlimitedThreshold;

  // https://rm5.dom.de/issues/51197#Calculation-12-is-unlimited
  const isUnlimited = bytes >= dayFlatUnlimitedThreshold;

  const duration = `${parseInt(milliseconds / (1000 * 60 * 60), 10)}h`;

  const isCardLarge = sizeVariant === 'large';

  return (
    <div className="tariff-option d-flex h-100">
      <div className="dayflat-option">
        {/* Size variant */}
        <div
          className={`${
            isCardLarge ? 'large-card' : 'small-card'
          } flex-grow-1 bg-white border border-2 border-gray-20 rounded-4 h-100`}>
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <div className="tariff-info flex-grow-1 mx-2 w-100 text-center">
              <div className="px-4">
                <h1 className="tariff-title text-dayflat nc-doomsday-h2 m-0">
                  {/* {t(`nc_tarif_dayflat_card_passtype_${passType}_name`)} */}
                  {name}
                </h1>
              </div>
              <div className="bullet-text">
                <div className="text-gray-60">
                  <h4 className="info-top nc-doomsday-h4 pb-0 mb-0">
                    {duration}{' '}
                    {isUnlimited
                      ? t('nc_tarif_dayflat_card_head_1_unlimited')
                      : `${t(
                          'nc_tarif_dayflat_card_head_1_with_data_volume'
                        )} ${volumeFormattedValue}`}
                  </h4>
                  <h4 className="info-bottom nc-realtextpro-copy">
                    {t('nc_tarif_dayflat_card_copy_1')}
                  </h4>
                </div>
              </div>
              <div>
                <h3 className="info-top nc-doomsday-h4 text-gray-60">
                  {t('nc_tarif_dayflat_card_head_2')}
                </h3>
              </div>
            </div>
            <div
              className="tariff-price w-100 text-center"
              style={{
                borderBottomLeftRadius: '28px',
                borderBottomRightRadius: '28px',
                backgroundColor: '#f90',
                color: 'white'
              }}>
              <div className="py-1">
                <div className="text-white d-flex align-items-end justify-content-center pt-3 pb-2">
                  <h4 className="price-text-small nc-doomsday-copy">
                    {t('nc_tarif_dayflat_card_price_before')} &nbsp;
                  </h4>
                  <h3 className="price-text-large nc-doomsday-h3 pb-0 mb-1">
                    {priceFormattedValue}
                  </h3>
                  <h4 className="price-text-small nc-doomsday-copy">
                    &nbsp;{t('nc_tarif_dayflat_card_time_interval_before')}
                    {duration}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {label && <div className="radio-label d-flex align-items-center justify-content-center ps-3">{label}</div>} */}
      </div>
    </div>
  );
}

export function SpeedOnOption({
  offerInfo = {
    passCode: 'GDPAKT27',
    name: '10-faches Datenvolumen',
    passType: 102,
    validityPeriod: 5,
    downloadBandwidthFormatted: '1000B',
    uploadBandwidthFormatted: '10B',
    dailyCharge: {
      grossAmount: 1000,
      taxValue: 20,
      currency: 'EUR',
      formattedValue: '10EUR'
    },
    smsCode: 'dayfullflat',
    tariffZones: [
      {
        id: 'TMDE_LG_DOMESTIC',
        domestic: true,
        roaming: false,
        name: 'Deutschland'
      },
      {
        id: 'TMDE_RLH_DOM_LG1',
        domestic: false,
        roaming: true,
        name: 'Ländergruppe 1'
      },
      {
        id: 'TMDE_RLH_DOM_TEST',
        domestic: true,
        roaming: true,
        name: 'LäTEST 1'
      }
    ],
    tariffZonesName: 'Deutschland, Ländergruppe 1',
    suppressZoneInfo: false,
    sortKey: 1,
    price: {
      grossAmount: 999,
      taxValue: 18,
      currency: 'EUR',
      formattedValue: '9EUR'
    },
    duration: {
      milliseconds: 500000,
      valueFormatted: '24days'
    },
    ssdDownloadBandwidthFormatted: '100ttt',
    ssdUploadBandwidthFormatted: '5ttt',
    requiresConfirmation: false,
    replacedPasses: [
      'GDPAKT01',
      'GDPAKT02',
      'GDPAKT03',
      'GDPAKT04',
      'GDPAKT05',
      'GDPAKT06',
      'GDPAKT07'
    ],
    suppressPrice: true,
    monetaryData: {
      ratingUnit: {
        bytes: 10737228249,
        formattedValue: '6.240 GB'
      },
      spendingCap: {
        grossAmount: 100,
        taxValue: 18,
        currency: 'RSD',
        formattedValue: '234.78RSD'
      }
    },
    volumeData: {
      coveredByBundle: false,
      volume: {
        bytes: 10737418240,
        formattedValue: '10.240 GB'
      },
      suppressVolume: true
    }
  },
  sizeVariant = 'small' // large, small
}) {
  const {
    price: { formattedValue: priceFormattedValue },
    volumeData: {
      volume: { formattedValue: volumeFormattedValue }
    }
  } = offerInfo;

  const { t } = useStaticContent();

  const isCardLarge = sizeVariant === 'large';

  return (
    <div className="tariff-option d-flex h-100">
      <div className="speedon-option">
        {/* Size variant */}
        <div
          className={`${
            isCardLarge ? 'large-card' : 'small-card'
          } flex-grow-1 bg-white border border-2 border-gray-20 rounded-4 h-100`}>
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <div className="tariff-info flex-grow-1 mx-2 w-100 text-center">
              <div>
                <h1 className="tariff-title nc-doomsday-h1 text-dayflat m-0">
                  {volumeFormattedValue}
                </h1>
              </div>
              <div>
                <h3 className="info-text nc-realtextpro-copy mb-0 text-gray-60">
                  {t('nc_tarif_speedon_card_disc')}
                </h3>
              </div>
            </div>
            <div
              className="tariff-price w-100 text-center"
              style={{
                borderBottomLeftRadius: '28px',
                borderBottomRightRadius: '28px',
                backgroundColor: '#f90',
                color: 'white'
              }}>
              <div className="py-1">
                <div className="text-white d-flex align-items-end justify-content-center pt-3 pb-2">
                  <h3 className="price-text-large nc-doomsday-h3 pb-0 mb-1">
                    {priceFormattedValue}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {label && <div className="radio-label d-flex align-items-center justify-content-center ps-3">{label}</div>} */}
      </div>
    </div>
  );
}

export function OptionBookable({
  optionInfo = {
    name: 'Allnet 100',
    description: '100 Minuten und 100 SMS in alle dt. Netze',
    price: '1,- €',
    duration: '28 Tage',
    durationUnit: 'DAYS'
  },
  sizeVariant = 'small'
}) {
  const { name, description, price, duration, durationUnit } = optionInfo;
  const { t } = useStaticContent();

  const isCardLarge = sizeVariant === 'large';

  return (
    <div className="tariff-option d-flex h-100">
      <div className="option-base-card">
        {/* Size variant */}
        <div
          className={`${
            isCardLarge ? 'large-card' : 'small-card'
          } flex-grow-1 bg-white border border-2 border-gray-20 rounded-4 h-100`}>
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <div
              className={`tariff-info flex-grow-1 mx-2 w-100 text-center ${
                isCardLarge ? 'px-5' : 'px-2'
              }`}>
              <div className="px-5">
                <h1 className="tariff-title text-dayflat px-2 nc-doomsday-h2 m-0">
                  {name}
                </h1>
              </div>
              <div className="bullet-text">
                <div className="text-gray-60">
                  <h4 className="info-top nc-realtextpro-copy pb-0 mb-0">{description}</h4>
                </div>
              </div>
              <div className="pt-4">
                <h3 className="info-top nc-realtextpro-copy text-gray-60">
                  {t('nc_options_valid_for')}&nbsp;{duration}&nbsp;{t(`nc_durationUnit_${durationUnit}`)}
                </h3>
              </div>
            </div>
            <div
              className="tariff-price w-100 text-center"
              style={{
                borderBottomLeftRadius: '28px',
                borderBottomRightRadius: '28px',
                backgroundColor: '#f90',
                color: 'white'
              }}>
              <div className="py-1">
                <div className="text-white d-flex align-items-end justify-content-center pt-3 pb-2">
                  <h3 className="price-text-large nc-doomsday-h3 pb-0 mb-1">
                    {price}
                    {t('nc_tarif_options_comma_dash')}&nbsp;{t('nc_tarif_options_euro')}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function GenericSpeedOnOption({
  optionData,
  sizeVariant = 'small' // large, small
}) {
  const { t, staticContentData } = useStaticContent();

  const UNLIMITED_IDENTIFIER =
    staticContentData?.nr_countersTDM2Settings?.genericCardUnlimitedIdentifier;
  let isUnlimited = false;
  if (parseFloat(optionData?.initialVolume) >= parseFloat(UNLIMITED_IDENTIFIER)) {
    isUnlimited = true;
  }

  const [expiryDate, expiryTime] = (optionData?.expiryTime ?? '').split(' ');
  const [hours, minutes] = (expiryTime ?? '').split(':');
  const timeline = `${t('nc_dboard_generic_card_valid_until')} ${expiryDate}${t(
    'nc_dboard_generic_card_date_time_separator'
  )} ${hours}:${minutes} ${t('nc_dboard_generic_card_time_suffix')}`;

  const isCardLarge = sizeVariant === 'large';

  return (
    <div className="tariff-option d-flex h-100">
      <div className="speedon-option">
        {/* Size variant */}
        <div
          className={`${
            isCardLarge ? 'large-card' : 'small-card'
          } flex-grow-1 bg-white border border-2 border-gray-20 rounded-4 h-100`}>
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <div className="tariff-info flex-grow-1 mx-2 w-100 text-center">
              <div>
                <h1 className="tariff-title nc-doomsday-h1 text-dayflat m-0">
                  {`${
                    isUnlimited ? t('nc_dboard_generic_card_unlimited') : optionData?.initialVolume
                  }`}
                </h1>
              </div>
              <div>
                <h3 className="info-text nc-realtextpro-copy mb-0 text-gray-60">{timeline}</h3>
              </div>
            </div>
            <div
              className="tariff-price w-100 text-center"
              style={{
                borderBottomLeftRadius: '28px',
                borderBottomRightRadius: '28px',
                backgroundColor: '#f90',
                color: 'white'
              }}>
              <div className="py-1">
                <div className="text-white d-flex align-items-end justify-content-center pt-3 pb-13" />
              </div>
            </div>
          </div>
        </div>
        {/* {label && <div className="radio-label d-flex align-items-center justify-content-center ps-3">{label}</div>} */}
      </div>
    </div>
  );
}

export default OptionBookable;
