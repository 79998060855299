/* eslint-disable no-nested-ternary */
import React from 'react';
import { Formik, Form } from 'formik';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { ButtonPrimary, FormInput, InfoNotification } from '@core/index';
import { useActivation } from '@context/index';
import { appAlert, appButtonTypes } from '@utils/globalConstant';

export function ActivationStep2() {
  const { t } = useStaticContent();
  const {
    isLoading,
    loginPasswordForm,
    // loginPasswordValidation,
    loginPasswordFormSubmit,
    validatePasswordWithKeys,
    changePasswordErrorMsg,
    setChangePasswordErrorMsg
  } = useActivation();

  return (
    <div className="container-md animate__animated animate__slideInRight">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
          <div>
            <h3 className="nc-doomsday-h3 mb-0">{t('nc_reg_step2_hdl')}</h3>
            <p className="nc-realheadpro-copy py-8 mb-0">{t('nc_reg_step2_txt')}</p>
            <Formik
              initialValues={loginPasswordForm}
              // validationSchema={loginPasswordValidation}
              validate={(v) => validatePasswordWithKeys(v, 'cscPassword', 'confirmCscPassword')}
              onSubmit={loginPasswordFormSubmit}>
              {({
                values: { cscPassword, confirmCscPassword },
                handleBlur,
                handleChange,
                errors,
                touched,
                handleSubmit
              }) => (
                <div className="mnp-form">
                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xl-6 col-xs-12 pb-1 pb-xl-0 pb-3">
                        <FormInput
                          type="password"
                          startIcon="passwordcolor"
                          id="cscPassword"
                          name="cscPassword"
                          placeholder={t('nc_reg_step2_input_pwd')}
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange({
                              target: {
                                id: 'cscPassword',
                                name: 'cscPassword',
                                value: e.target.value
                              }
                            });
                            setChangePasswordErrorMsg('');
                          }}
                          value={cscPassword}
                          // valid={touched.cscPassword && !errors.cscPassword}
                          // invalid={!!touched.cscPassword && !!errors.cscPassword}
                          invalid={!!touched.cscPassword && !!errors?.otherErrors?.invalid}
                          onBlur={handleBlur}
                          // invalidMessage={
                          //   touched.cscPassword && errors.cscPassword ? errors.cscPassword : null
                          // }
                        />
                        {/* validation: min characters */}
                        <div className="ps-5">
                          <InfoNotification
                            type={
                              errors && errors.otherErrors && errors.otherErrors.minMax
                                ? appAlert.ERROR
                                : cscPassword
                                ? appAlert.SUCCESS
                                : false
                            }
                            message={t('nc_pwd_require1')}
                          />
                        </div>
                        {/* validation: letters */}
                        <div className="ps-5">
                          <InfoNotification
                            type={
                              (errors && errors.otherErrors && errors.otherErrors.uppercase) ||
                              (errors && errors.otherErrors && errors.otherErrors.lowecase)
                                ? appAlert.ERROR
                                : cscPassword
                                ? appAlert.SUCCESS
                                : false
                            }
                            message={t('nc_pwd_require2')}
                          />
                        </div>
                        {/* validation: number */}
                        <div className="ps-5">
                          <InfoNotification
                            type={
                              errors && errors.otherErrors && errors.otherErrors.number
                                ? appAlert.ERROR
                                : cscPassword
                                ? appAlert.SUCCESS
                                : false
                            }
                            message={t('nc_pwd_require3')}
                          />
                        </div>
                        {/* validation: special character */}
                        <div className="ps-5">
                          <InfoNotification
                            type={
                              errors && errors.otherErrors && errors.otherErrors.specific
                                ? appAlert.ERROR
                                : cscPassword
                                ? appAlert.SUCCESS
                                : false
                            }
                            message={t('nc_pwd_require4')}
                          />
                        </div>
                        {/* validation: full regex */}
                        {(changePasswordErrorMsg || errors?.otherErrors?.invalid) && (
                          <div className="ps-5 px-5">
                            <InfoNotification
                              type={appAlert.ERROR}
                              message={changePasswordErrorMsg || t('nc_password_error_invalid-char')}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-xl-6 col-xs-12">
                        <FormInput
                          type="password"
                          startIcon="passwordcolor"
                          id="confirmCscPassword"
                          name="confirmCscPassword"
                          placeholder={t('nc_reg_step2_input_pwd_repeat')}
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange({
                              target: {
                                id: 'confirmCscPassword',
                                name: 'confirmCscPassword',
                                value: e.target.value
                              }
                            });
                            setChangePasswordErrorMsg('');
                          }}
                          value={confirmCscPassword}
                          // valid={touched.confirmCscPassword && !errors.confirmCscPassword}
                          // invalid={!!touched.confirmCscPassword && !!errors.confirmCscPassword}
                          onBlur={handleBlur}
                          // invalid={!!touched.confirmCscPassword && !!errors.confirmCscPassword}
                          invalid={!!touched.confirmCscPassword && errors?.otherErrors?.match}
                          // invalidMessage={
                          //   touched.confirmCscPassword && errors.confirmCscPassword
                          //     ? errors.confirmCscPassword
                          //     : null
                          // }
                          // hint={
                          //   touched.confirmCscPassword &&
                          //   !errors.confirmCscPassword &&
                          //   cscPassword &&
                          //   confirmCscPassword
                          //     ? errors && errors.otherErrors && errors.otherErrors.match
                          //       ? false
                          //       : t('nc_reg_step2_pwd_require5')
                          //     : false
                          // }
                        />
                        <div className="px-5">
                          <InfoNotification
                            type={
                              errors?.otherErrors?.match
                                ? appAlert.ERROR
                                : touched.cscPassword && cscPassword === confirmCscPassword
                                ? appAlert.SUCCESS
                                : appAlert.DARK
                            }
                            message={t('nc_reg_step2_pwd_require5')}
                          />
                        </div>
                      </div>
                      {/* <div className="col-12">
                        
                      </div> */}
                      <div className="col-xs-12">
                        {/* validation: passwords match */}
                        {/* <div className="ps-5">
                          <InfoNotification
                            type={
                              errors && errors.otherErrors && errors.otherErrors.match
                                ? appAlert.ERROR
                                : cscPassword
                                ? appAlert.SUCCESS
                                : false
                            }
                            message={t('nc_reg_step2_pwd_require5')}
                          />
                        </div> */}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 text-center pt-8">
                        <ButtonPrimary
                          isLoading={isLoading}
                          type="submit"
                          label={t('nc_reg_step2_btn')}
                          disabled={
                            !cscPassword ||
                            !confirmCscPassword ||
                            changePasswordErrorMsg || 
                            (errors &&
                              errors.otherErrors &&
                              Object.keys(errors.otherErrors || {}).filter(
                                (v) => errors && errors.otherErrors && errors.otherErrors[v]
                              ).length > 0)
                          }
                          buttonType={appButtonTypes.PRIMARY.DEFAULT}
                          icon="arrowright"
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivationStep2;
