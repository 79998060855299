import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { AfterLegitimation } from '@part/Legitimation';
import { appRoute } from '@utils/globalConstant';
import { useActivation } from '@context/MobileOne';

export function LegitimationFailed() {
  // Contexts
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const { setCurrentStep } = useActivation();

  useEffect(() => {
    setCurrentStep(5);
  }, []);
  
  return (
    <div className="container-md container-page3">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
          <div>
            <AfterLegitimation
              icon="errorcolor"
              heading={t('nc_reg_err_hdl')}
              bodyText={t('nc_reg_err_txt')}
              buttonLabel={t('nc_reg_err_btn')}
              buttonIcon="arrowright"
              onButtonClick={() => {
                navigate(appRoute.ACTIVATION_EMAIL_VERIFICATION);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LegitimationFailed;
