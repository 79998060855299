import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { useActivation } from '@context/MobileOne';
import { appButtonTypes } from '@utils/globalConstant';
import { ButtonPrimary } from '@core/ButtonPrimary';

export function Legitimation() {
  // Contexts
  const { t } = useStaticContent();
  const { processWithPostIdent, setShowBackButton } = useActivation();

  // Hooks
  useEffect(() => {
    setShowBackButton(false);

    return () => {
      setShowBackButton(true);
    };
  }, []);
  return (
    <div className="container-md container-page3">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
          <div>
            <h3
              className="nc-doomsday-h3 pb-4"
              dangerouslySetInnerHTML={{ __html: t('nc_reg_step6_hdl') }}
            />
            <p
              className="nc-realheadpro-copy py-4"
              dangerouslySetInnerHTML={{ __html: t('nc_reg_step6_txt') }}
            />
            <div className="row">
              <div className="col-12 text-center pt-15">
                <ButtonPrimary
                  type="button"
                  label={t('nc_reg_step6_topup_btn')}
                  buttonType={appButtonTypes.PRIMARY.DEFAULT}
                  icon="arrowright"
                  onClick={processWithPostIdent}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Legitimation;
