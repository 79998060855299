/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import warning from '@theme/assets/img/warning.svg';
import { Badge, Icons, Tooltip } from '@core/Utils';

import './style.scss';
import Tabs from '@core/Layout/Tabs';
import { Radio, Switch, DropDown } from '@core/Inputs';

import {
  ButtonMisc,
  ButtonPrimary,
  ButtonRadio,
  ButtonSecondary,
  ButtonToggle,
  FormCode,
  FormDropdown,
  FormInput,
  FormVoucher,
  PopupImage,
  PopupSuccess,
  InfoBox,
  InfoBoxClose,
  InfoNotification,
  PopupPassword,
  PopupSupport,
  Logo,
  // NavigationActivationBar,
  BubbleText,
  BubbleTabs,
  BubbleSmallNumber,
  CounterButton,
  LoadingScreen,
  Counter,
  DashboradCredit,
  TarifTabsNavigation,
  Player,
  NumbersCircle,
  TopupHistory,
  SemiCircleProgressBar,
  FaqTab
} from '@core/index';
import {
  AfterPaymentScreen,
  DashboardActiveOption,
  DashboardTariff,
  DashboardTeaser,
  GenericTeaser,
  MainLayout,
  TarifProductNavigation,
  NavigationPageTitle,
  NavigationActivationBar,
  NavigationFooter,
  NavigationHeader,
  TarifFeedbackSuccess,
  // Payment, // TODO: check error message "undefined paymentMethodName" and uncomment this component
  FeedbackScreen,
  Payment,
  GenericSwip
} from '@part/index';
import { TariffProduct } from '@part/TariffProduct';
import { DayFlatOption, SpeedOnOption } from '@part/TariffOption';

import {
  appAlert,
  appBubbleTextOpenPosition,
  appBubbleTextType,
  appBubbleBackground,
  appButtonTypes,
  appCounterButtonTypes,
  appCounterTypes,
  appDashboardTeaserTypes,
  appInfoBoxType,
  appTariffBulletGroup,
  appTariffPriceType,
  appIcons,
  appTariffStatus,
  appImages,
  displayPrice,
  appGenericTeaserType,
  appGenericSwipType
} from '@utils/globalConstant';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import PaymentFields from '@core/PaymentFields/PaymentFields';
import TariffBadge from '@core/TariffBadge/TariffBadge';
import { InfoBoxToggle } from '@core/InfoBoxToggle';
// import { TariffOption } from '@part/TariffOption';
import { FormDatePicker } from '@core/FormDatePicker';
import { useActivation, useCustomer } from '@context/MobileOne';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { FormTextArea } from '@core/FormTextArea';
import Image from '@core/Utils/Image';

// import { Col, Container, Row } from '@core/Layout';
// import { Switch, Text } from '@core/Inputs';
// import { ToolTip } from '@core/Utils';

export function Test() {
  // Constants
  const testPdf = { name: 'Test PDF', url: '/assets/pdf/test.pdf' };
  const paymentProducts = {
    creditcard: {
      fraudCheckEnabled: true,
      paymentTokenEnabled: true,
      onceOffEnabled: true,
      promotions: [],
      cryptoCurrency: null
    },
    'american-express': {
      fraudCheckEnabled: true,
      paymentTokenEnabled: true,
      onceOffEnabled: true,
      promotions: [],
      cryptoCurrency: null
    },
    paydirekt: {
      fraudCheckEnabled: true,
      paymentTokenEnabled: false,
      onceOffEnabled: true,
      promotions: [],
      cryptoCurrency: null
    },
    paypal: {
      fraudCheckEnabled: true,
      paymentTokenEnabled: true,
      onceOffEnabled: true,
      promotions: [],
      cryptoCurrency: null
    },
    'sepa-direct-debit': {
      fraudCheckEnabled: true,
      paymentTokenEnabled: true,
      onceOffEnabled: false,
      promotions: [],
      cryptoCurrency: null
    },
    sofort: {
      fraudCheckEnabled: false,
      paymentTokenEnabled: false,
      onceOffEnabled: true,
      promotions: [],
      cryptoCurrency: null
    }
  };

  let payments = [];
  if (paymentProducts) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(paymentProducts)) {
      // console.log({ name: key, ...value });
      // if (value.paymentTokenEnabled) {
      payments.push({ name: key, ...value });
      // }
    }
  }

  const { staticContentData, t } = useStaticContent();

  const [openModal, setOpenModal] = useState('');
  const [test, setTest] = useState(false);
  const [testDate, setTestDate] = useState();
  const [code, setCode] = useState('');

  const [tariffData, setTariffData] = useState([]);
  const { countries, getCountries } = useActivation();
  const { getTestVideo, testVideo } = useCustomer();
  const { paymentValidations, onStepFourSubmit } = useOnlineTopUp();

  useEffect(() => {
    setTimeout(() => {
      setTest(true);
    }, 2500);
  }, []);
  useEffect(() => {
    setTariffData(
      staticContentData && staticContentData.tariff_norma ? staticContentData.tariff_norma : []
    );
    getTestVideo();
    getCountries();
    return () => {
      setTariffData([]);
    };
  }, [staticContentData]);

  return (
    <div className="bg-white pt-5">
      <section className="container mb-2">
        <div className="pb-10">
          <h1>Norma Connect</h1>
          <h4>Design system and components</h4>
        </div>
        <div>
          <h2 className="mb-5">Layout</h2>
          <ul>
            <li>Main layout: min-height 100vh</li>
            <li>Page container (default): container + pt 40px, pb 80px (.container-page1)</li>
            <li>
              Page container (page with bottom Image): container + pt 68px, pb 0 (.container-page2)
            </li>
            <li>
              Page container (full width page with slider): container fluid + pt: 40px, pb: 80px
            </li>
            <li>Page container (pages with activation bar): container + pt: 58px, pb: 80px</li>

            <li>Page content width: </li>
            <ul>
              <li>xs: 100%</li>
              <li>s: 336px</li>
              <li>md: 456px</li>
              <li>lg: 616px</li>
              <li>xl: 736px</li>
              <li>xxl: 856px</li>
            </ul>
          </ul>
        </div>
        <div className="bg-mint-40-20">
          <br />
          <h3 className="text-primary">Grids</h3>
          <div>
            <ul>
              <li>XX-Large≥1400px Container: 1320px // Content: 1296px</li>
              <li>X-Large ≥1200px Container:1140px// Content: 1116px</li>
              <li>Large ≥ 992px Container: 960px // Content: 936px</li>
              <li>Medium ≥ 768px Container: 720px // Content: 696px</li>
              <li>Small ≥ 576px Container: 540px // Content: 516px</li>
              <li>Extra small &lt; 576px Container: 100% // Content: 100%</li>
              <li>Gutter size: 24px</li>
            </ul>
          </div>
          <br />
          <h5>Desktop</h5>
          <br />
          <div className="row bg-primary">
            <div className="col-1">
              <div className="bg-secondary text-white text-center">1</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">2</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">3</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">4</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">5</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">6</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">7</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">8</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">9</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">10</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">11</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">12</div>
            </div>
          </div>
          <br />
          <h5>Tablet</h5>
          <br />
          <div className="row bg-primary-color">
            <div className="col-1">
              <div className="bg-secondary text-white text-center">1</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">2</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">3</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">4</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">5</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">6</div>
            </div>
          </div>
          <br />
          <h5>Mobile</h5>
          <br />
          <div className="row bg-primary-color">
            <div className="col-1">
              <div className="bg-secondary text-white text-center">1</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">2</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">3</div>
            </div>
            <div className="col-1">
              <div className="bg-secondary text-white text-center">4</div>
            </div>
          </div>
          <br />
        </div>
      </section>

      <section className="py-10">
        <div className="container">
          <h2 className="mb-5">Color system</h2>
          <div className="pb-5">
            <div className="nc-design-system">
              <ul>
                <li className="color-box bg-secondary">
                  <div>Color name in Figma</div>
                  <div>Color value</div>
                  <div>Variable name</div>
                </li>
              </ul>
            </div>
          </div>

          <div className="nc-design-system">
            <div className="pb-5">
              <h3 className="mb-2">Body Colors</h3>
              <ul>
                <li className="color-box" id="box-body-1">
                  <div>Body background:</div>
                  <div>nc-mint-40</div>
                  <div>#ade2de</div>
                  <div>$body-bg</div>
                </li>
                <li className="color-box" id="box-body-2">
                  <div>Body text:</div>
                  <div>nc-darkgreen</div>
                  <div>#074d52</div>
                  <div>$body-color</div>
                </li>
              </ul>
            </div>

            <div className="pb-5">
              <h3 className="mb-2">Text Colors</h3>
              <ul>
                <li className="color-box" id="box-text-1">
                  <div>nc-darkgreen</div>
                  <div>#074d52</div>
                  <div>$darkgreen</div>
                </li>
                <li className="color-box" id="box-text-2">
                  <div>nc-darkgreen-75</div>
                  <div>#467a7d</div>
                  <div>$darkgreen-75</div>
                </li>
                <li className="color-box" id="box-text-3">
                  <div>nc-darkgreen-50</div>
                  <div>#83a6a8</div>
                  <div>$darkgreen-50</div>
                </li>
              </ul>
            </div>

            <div className="pb-5">
              <h3 className="mb-2">Gray Colors</h3>
              <ul>
                <li className="color-box" id="box-gray-1">
                  <div>nc-gray-10</div>
                  <div>#eaebec</div>
                  <div>$gray-10</div>
                </li>
                <li className="color-box" id="box-gray-2">
                  <div>nc-gray-20</div>
                  <div>#d5d6d8</div>
                  <div>$gray-20</div>
                </li>
                <li className="color-box" id="box-gray-3">
                  <div>nc-gray-40</div>
                  <div>#abadb0</div>
                  <div>$gray-40</div>
                </li>
                <li className="color-box" id="box-gray-4">
                  <div>nc-gray-60</div>
                  <div>#818589</div>
                  <div>$gray-60</div>
                </li>
                <li className="color-box" id="box-gray-5">
                  <div>nc-gray-80</div>
                  <div>#575c61</div>
                  <div>$gray-80</div>
                </li>
                <li className="color-box" id="box-gray-6">
                  <div>nc-gray-100</div>
                  <div>#3c4559</div>
                  <div>$gray-100</div>
                </li>
              </ul>
            </div>

            <div className="pb-5">
              <h3 className="mb-2">Mint Colors</h3>
              <ul>
                <li className="color-box" id="box-mint-1">
                  <div>nc-mint-20</div>
                  <div>#d6f0ef</div>
                  <div>$mint-20</div>
                </li>
                <li className="color-box" id="box-mint-2">
                  <div>nc-mint-40</div>
                  <div>#ade2de</div>
                  <div>$mint-40</div>
                </li>
                <li className="color-box" id="box-mint-3">
                  <div>nc-mint-60</div>
                  <div>#84d3ce</div>
                  <div>$mint-60</div>
                </li>
                <li className="color-box" id="box-mint-4">
                  <div>nc-mint-80</div>
                  <div>#5cc4be</div>
                  <div>$mint-80</div>
                </li>
                <li className="color-box" id="box-mint-5">
                  <div>nc-mint-100</div>
                  <div>#33b6ae</div>
                  <div>$mint-100</div>
                </li>
              </ul>
            </div>

            <div className="pb-5">
              <h3 className="mb-2">Yellow Colors</h3>
              <ul>
                <li className="color-box" id="box-yellow-1">
                  <div>nc-yellow-20</div>
                  <div>#fff5d6</div>
                  <div>$yellow-20</div>
                </li>
                <li className="color-box" id="box-yellow-2">
                  <div>nc-yellow-40</div>
                  <div>#ffebad</div>
                  <div>$yellow-40</div>
                </li>
                <li className="color-box" id="box-yellow-3">
                  <div>nc-yellow-60</div>
                  <div>#ffe085</div>
                  <div>$yellow-60</div>
                </li>
                <li className="color-box" id="box-yellow-4">
                  <div>nc-yellow-80</div>
                  <div>#ffd65c</div>
                  <div>$yellow-80</div>
                </li>
                <li className="color-box" id="box-yellow-5">
                  <div>nc-yellow-100</div>
                  <div>#ffcc33</div>
                  <div>$yellow-100</div>
                </li>
              </ul>
            </div>

            <div className="pb-5">
              <h3 className="mb-2">Tariff Colors</h3>
              <ul>
                <li className="color-box" id="box-tariff-1">
                  <div>nc-tarif-smartS</div>
                  <div>#e0424e</div>
                  <div>$smartS</div>
                </li>
                <li className="color-box" id="box-tariff-3">
                  <div>nc-tarif-smartM</div>
                  <div>#f49a54</div>
                  <div>$smartM</div>
                </li>
                <li className="color-box" id="box-tariff-5">
                  <div>nc-tarif-smartL</div>
                  <div>#77255f</div>
                  <div>$smartL</div>
                </li>
                <li className="color-box" id="box-tariff-7">
                  <div>nc-tarif-smart6</div>
                  <div>#21537</div>
                  <div>$smart6</div>
                </li>
                <br />
                <li className="color-box" id="box-tariff-2">
                  <div>nc-tarif-smartS-80</div>
                  <div>#e8717a</div>
                  <div>$smartS-80</div>
                </li>
                <li className="color-box" id="box-tariff-4">
                  <div>nc-tarif-smartM-80</div>
                  <div>#f7b37f</div>
                  <div>$smartM-80</div>
                </li>
                <li className="color-box" id="box-tariff-6">
                  <div>nc-tarif-smartL-80</div>
                  <div>#995b87</div>
                  <div>$smartL-80</div>
                </li>
                <li className="color-box" id="box-tariff-8">
                  <div>nc-tarif-smart6-80</div>
                  <div>#50809a</div>
                  <div>$smart6-80</div>
                </li>
                <br />
                <li className="color-box" id="box-tariff-9">
                  <div>nc-tarif-smartS-60</div>
                  <div>#EC8E95</div>
                  <div>$smartS-60</div>
                </li>
                <li className="color-box" id="box-tariff-10">
                  <div>nc-tarif-smartM-60</div>
                  <div>#F8C298</div>
                  <div>$smartM-60</div>
                </li>
                <li className="color-box" id="box-tariff-11">
                  <div>nc-tarif-smartL-60</div>
                  <div>#AD7C9F</div>
                  <div>$smartL-60</div>
                </li>
                <li className="color-box" id="box-tariff-12">
                  <div>nc-tarif-smart6-60</div>
                  <div>#7A98AC</div>
                  <div>$smart6-60</div>
                </li>
                <br />
                <li className="color-box" id="box-tariff-13">
                  <div>NC_SPEED_ON</div>
                  <div>#FF9900</div>
                  <div>$speedon</div>
                </li>
              </ul>
            </div>

            <div className="pb-5">
              <h3 className="mb-2">State Colors</h3>
              <ul>
                <li className="color-box" id="box-state-1">
                  <div>nc-state-error</div>
                  <div>#d23030</div>
                  <div>$danger</div>
                </li>
                <li className="color-box" id="box-state-2">
                  <div>nc-state-success</div>
                  <div>#00991e</div>
                  <div>$success</div>
                </li>
                <li className="color-box" id="box-state-3">
                  <div>nc-state-info</div>
                  <div>#00a1c5</div>
                  <div>$info</div>
                </li>
                <li className="color-box" id="box-state-4">
                  <div>nc-state-warning</div>
                  <div>#ffcc33</div>
                  <div>$warning</div>
                </li>
              </ul>
            </div>

            {/* TODO: check */}
            <div className="pb-5 d-flex">
              <div>
                <h3 className="mb-2">Primary</h3>
                <ul>
                  <li className="color-box" id="box-primary">
                    <div>#074d52</div>
                    <div>
                      $primary <br /> $darkgreen
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="mb-2">Secondary</h3>
                <ul>
                  <li className="color-box" id="box-secondary">
                    <div>#33b6ae</div>
                    <div>
                      $secondary <br /> $mint-100
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="pb-5">
              <h3 className="mb-2">Colors with opacity</h3>
              <ul className="bg-black p-3 d-inline-block">
                <li className="opacity-box" id="box-opacity-1">
                  <div>nc-white-80 / 80%</div>
                  <div>rgba(255, 255, 255, 0.8)</div>
                  <div>$white-80</div>
                </li>
                <li className="opacity-box" id="box-opacity-2">
                  <div>nc-white-60 / 60%</div>
                  <div>rgba(255, 255, 255, 0.6)</div>
                  <div>$white-60</div>
                </li>
                <li className="opacity-box" id="box-opacity-3">
                  <div>nc-white-40 / 40%</div>
                  <div>rgba(255, 255, 255, 0.4)</div>
                  <div>$white-40</div>
                </li>
                <li className="opacity-box" id="box-opacity-4">
                  <div>nc-white-20 / 20%</div>
                  <div>rgba(255, 255, 255, 0.2)</div>
                  <div>$white-20</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <h2 className="mb-5">Typography</h2>
        <div className="pb-5">
          <h3 className="pb-2">Universal Doomsday Font: </h3>
          <div className="pb-5">
            <h1 className="nc-doomsday-h1">h1. Universal Doomsday heading (bold/700)</h1>
            <h2 className="nc-doomsday-h2">h2. Universal Doomsday heading (bold/700)</h2>
            <h3 className="nc-doomsday-h3">h3. Universal Doomsday heading (bold/700)</h3>
            <h4 className="nc-doomsday-h4">h4. Universal Doomsday heading (medium/500)</h4>
            {/* <h5 className="nc-doomsday-medium">h5. Universal Doomsday heading (medium/500)</h5>
              <h6 className="nc-doomsday-medium">h6. Universal Doomsday heading (medium/500)</h6> */}
          </div>
        </div>

        <div className="pb-5">
          <p className="nc-doomsday-copy">p. copy text Universal Doomsday</p>
        </div>

        <div className="pb-5">
          <p className="nc-doomsday-button">Button text Universal Doomsday </p>
        </div>

        <div className="pb-5">
          <p className="nc-doomsday-footnote">Footnote text Universal Doomsday</p>
        </div>

        <div className="pb-5">
          <p className="nc-doomsday-footnote-link">Footnote link Universal Doomsday</p>
        </div>

        <div className="pb-5">
          <h3 className="pb-2 nc-realheadpro">Real Head Font: </h3>
          <div className="pb-5">
            <h1 className="nc-realheadpro-h1">h1. Real Head heading (regular/400)</h1>
            <h2 className="nc-realheadpro-h2">h2. Real Head heading (regular/400)</h2>
            <h3 className="nc-realheadpro-h3">h3. Real Head heading (regular/400)</h3>
            <h4 className="nc-realheadpro-h4">h4. Real Head heading (regular/400)</h4>
            {/* <h5 className="nc-realheadpro">h5. Real Head heading (regular/400)</h5>
              <h6 className="nc-realheadpro">h6. Real Head heading (regular/400)</h6> */}
          </div>
          <div className="pb-5">
            <p className="nc-realheadpro-subhead">Subhead Real Head</p>
          </div>
        </div>

        <div className="pb-5">
          <h3 className="pb-2 nc-realtextpro">Real Text Font: </h3>
          <div className="pb-5">
            <p className="nc-realtextpro-copy">p. Copy text Real Text (primary)</p>
          </div>
          <div className="pb-5">
            <p className="nc-realtextpro-copy-bold">p. Copy text bold Real Text</p>
          </div>
          <div className="pb-5">
            <p className="nc-realtextpro-footnote">Footnote text Real Text</p>
          </div>
          <div className="pb-5">
            <p className="nc-realtextpro-fillin">Input text (Fill-in) Real Text</p>
          </div>
        </div>
      </section>

      <section className="pb-5 container nc-design-system">
        <h2 className="pb-5">Shadows</h2>
        <ul>
          <li className="shadow-box shadow" id="">
            <div>Neutral box shadow</div>
            <div>.shadow</div>
            <div>$box-shadow</div>
          </li>
          <li className="shadow-box shadow-sm" id="">
            <div>Small box shadow</div>
            <div>.shadow-sm</div>
            <div>$box-shadow-sm</div>
          </li>
          <li className="shadow-box shadow-modal" id="">
            <div>Modal box shadow</div>
            <div>.shadow-modal</div>
            <div>$box-shadow-modal</div>
          </li>
        </ul>
      </section>

      <section className="mb-10">
        <h1 className="mb-5">Icons</h1>
        <div className="row mx-auto bg-yellow-60">
          {Object.entries(appIcons).map(([iconName, iconPath]) => (
            <li
              key={iconName}
              className="d-flex list-unstyled col-md-4 px-6"
              style={{ borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray' }}>
              <span className="py-1 flex-grow-1">{iconName}:</span>
              <span>
                <Icons name={iconName} height={24} alt={iconName} />
              </span>
            </li>
          ))}
        </div>
      </section>

      <div className="py-10">
        <h3 className="pb-5">Navigation Header</h3>
        <NavigationHeader />
      </div>

      <div className="py-10">
        <h3 className="pb-5">Navigation Footer</h3>
        <NavigationFooter />
      </div>

      <div className="py-10">
        <h3 className="pb-5">Navigation Activation Bar</h3>
        <NavigationActivationBar />
      </div>

      <h3 className="pt-10 pb-5">Main Layout</h3>
      <MainLayout showChildren>
        <div className="container-md container-page1">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
              <h3>Page container (default): container + pt 40px, pb 80px</h3>
              <br />
              <h1 className="nc-realtextpro">
                Page content placeholder - Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Id nemo suscipit itaque eos voluptatibus, nostrum ratione atque laboriosam placeat
                porro doloremque! Laborum in, repudiandae at consequuntur consectetur libero
                voluptatem dolore, ratione porro maiores officiis, ipsa dicta vel nam ipsum saepe
                soluta quas eligendi molestiae repellat impedit itaque sequi! Tempora aliquid
                officiis harum, voluptatum, atque libero dolor sunt aperiam commodi, dicta iste
                sapiente a. Placeat, magni perferendis! Obcaecati a vero atque praesentium facere in
                voluptas velit omnis mollitia, vel expedita veniam cumque maiores hic quis quisquam
                quod quam minus magnam cupiditate sint? Molestias perspiciatis laborum deleniti
                recusandae odio provident dolorum numquam. Et necessitatibus asperiores ipsa ducimus
                nulla in ex laboriosam iste exercitationem libero. Iste, quidem adipisci quia amet
                optio quae est corrupti eveniet nulla impedit incidunt expedita sint nostrum nihil?
                Non, sed voluptatem eius harum repellendus fugit amet voluptatibus, enim reiciendis
                fugiat dignissimos facere voluptates iusto aperiam deserunt minima qui laborum,
                officiis eum possimus vel? Iste pariatur eum praesentium fugit quasi dolores, fuga
                tempora commodi eius, perferendis dolorum harum aut esse dignissimos minus quisquam
                molestias? Ullam nam consectetur eaque esse accusantium quia.
              </h1>
            </div>
          </div>
        </div>
      </MainLayout>
      <MainLayout showChildren>
        <div className="container-md container-page2">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
              <h3>Page container (with bottom image): container + pt 68px, pb 0</h3>
              <br />
              <h1 className="nc-realtextpro">
                Page content placeholder - Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Id nemo suscipit itaque eos voluptatibus, nostrum ratione atque laboriosam placeat
                porro doloremque! Laborum in, repudiandae at consequuntur consectetur libero
                voluptatem dolore, ratione porro maiores officiis, ipsa dicta vel nam ipsum saepe
                soluta quas eligendi molestiae repellat impedit itaque sequi! Tempora aliquid
                officiis harum, voluptatum, atque libero dolor sunt aperiam commodi, dicta iste
                sapiente a. Placeat, magni perferendis! Obcaecati a vero atque praesentium facere in
                voluptas velit omnis mollitia, vel expedita veniam cumque maiores hic quis quisquam
                quod quam minus magnam cupiditate sint? Molestias perspiciatis laborum deleniti
                recusandae odio provident dolorum numquam. Et necessitatibus asperiores ipsa ducimus
                nulla in ex laboriosam iste exercitationem libero. Iste, quidem adipisci quia amet
                optio quae est corrupti eveniet nulla impedit incidunt expedita sint nostrum nihil?
                Non, sed voluptatem eius harum repellendus fugit amet voluptatibus, enim reiciendis
                fugiat dignissimos facere voluptates iusto aperiam deserunt minima qui laborum,
                officiis eum possimus vel? Iste pariatur eum praesentium fugit quasi dolores, fuga
                tempora commodi eius, perferendis dolorum harum aut esse dignissimos minus quisquam
                molestias? Ullam nam consectetur eaque esse accusantium quia.
              </h1>
            </div>
          </div>
        </div>
      </MainLayout>
      <MainLayout showChildren>
        <div className="container-fluid container-page2">
          <div className="row">
            <h3>
              Page container (full width page with slider): full width/container-fluid + pt: 40px,
              pb: 80px
            </h3>
            <br />
            <h1 className="nc-realtextpro">
              Page content placeholder - Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
              nemo suscipit itaque eos voluptatibus, nostrum ratione atque laboriosam placeat porro
              doloremque! Laborum in, repudiandae at consequuntur consectetur libero voluptatem
              dolore, ratione porro maiores officiis, ipsa dicta vel nam ipsum saepe soluta quas
              eligendi molestiae repellat impedit itaque sequi! Tempora aliquid officiis harum,
              voluptatum, atque libero dolor sunt aperiam commodi, dicta iste sapiente a. Placeat,
              magni perferendis! Obcaecati a vero atque praesentium facere in voluptas velit omnis
              mollitia, vel expedita veniam cumque maiores hic quis quisquam quod quam minus magnam
              cupiditate sint? Molestias perspiciatis laborum deleniti recusandae odio provident
              dolorum numquam. Et necessitatibus asperiores ipsa ducimus nulla in ex laboriosam iste
              exercitationem libero. Iste, quidem adipisci quia amet optio quae est corrupti eveniet
              nulla impedit incidunt expedita sint nostrum nihil? Non, sed voluptatem eius harum
              repellendus fugit amet voluptatibus, enim reiciendis fugiat dignissimos facere
              voluptates iusto aperiam deserunt minima qui laborum, officiis eum possimus vel? Iste
              pariatur eum praesentium fugit quasi dolores, fuga tempora commodi eius, perferendis
              dolorum harum aut esse dignissimos minus quisquam molestias? Ullam nam consectetur
              eaque esse accusantium quia.
            </h1>
          </div>
        </div>
      </MainLayout>

      <div className="container">
        <div className="py-5">
          <h1 className="pb-5">Components</h1>
          <h3 className="pb-5">Logo</h3>

          <div className="bg-mint-20">
            <h5>Logo primary</h5>
            <div className="">
              <Logo primaryLogo={true} />
              <br />
              <br />
              <h5>Logo secondary</h5>
              <Logo primaryLogo={false} />
            </div>
          </div>
        </div>

        <div>
          <h3 className="pb-5">Loading</h3>
          <h5>Without any text</h5>
          <div className="bg-mint-40">
            <LoadingScreen />
          </div>
          <br />
          <h5>With text from CMS</h5>
          <div className="bg-mint-40">
            <LoadingScreen cmsHtml={t('nc_loading_hdl')} />
          </div>
          <br />
          <h5>With text from code</h5>
          <div className="bg-mint-40">
            <LoadingScreen
              text={
                <span>
                  Text <span className="text-secondary">coming from </span> the source code
                </span>
              }
            />
          </div>
        </div>

        <div className="py-5">
          <h3 className="pb-5">Tariff Badge</h3>
          <ul className="bg-mint-40 p-5 list-unstyled d-flex flex-column gap-3">
            <li>
              <TariffBadge bgColor={'#E0424E'} tariffName={'Smart S'} />
            </li>
            <li>
              <TariffBadge bgColor={'#F49A54'} tariffName={'Smart M'} />
            </li>
            <li>
              <TariffBadge bgColor={'#77255F'} tariffName={'Smart L'} />
            </li>
            <li>
              <TariffBadge bgColor={'#215374'} tariffName={'Smart 6'} />
            </li>
            <li>
              <TariffBadge bgColor={'#33B6AE'} tariffName={'Start'} />
            </li>
          </ul>
        </div>

        <div className="py-5">
          <h3 className="pb-5">Bubble Text</h3>
          <div className="bg-mint-40 py-10 px-5">
            <div className="row">
              <h4 className="">Bubble white </h4>
              <div className="mb-5 col-lg-3 col-md-6 col-xs-12">
                <BubbleText
                  text="Hier kann ich reintexten ohne Problemen - TOP"
                  openPosition={appBubbleTextOpenPosition.TOP}
                  bgColorClass={appBubbleBackground.WHITE}
                  textColorClass={appBubbleTextType.GRAY}
                />
              </div>

              <div className="col-lg-3 col-md-6 col-xs-12">
                <BubbleText
                  text="Hier kann ich reintexten ohne Problemen - BOTTOM"
                  openPosition={appBubbleTextOpenPosition.BOTTOM}
                  bgColorClass={appBubbleBackground.WHITE}
                  textColorClass={appBubbleTextType.GRAY}
                />
              </div>
            </div>

            <h4 className="">Bubble mint-60</h4>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-xs-12">
                <BubbleText
                  text="Hier kann ich reintexten ohne Problemen - TOP"
                  openPosition={appBubbleTextOpenPosition.TOP}
                  bgColorClass={appBubbleBackground.MINT60}
                  textColorClass={appBubbleTextType.WHITE}
                />
              </div>

              <div className="col-lg-3 col-md-6 col-xs-12">
                <BubbleText
                  text="Hier kann ich reintexten ohne Problemen - BOTTOM"
                  openPosition={appBubbleTextOpenPosition.BOTTOM}
                  bgColorClass={appBubbleBackground.MINT60}
                  textColorClass={appBubbleTextType.WHITE}
                />
              </div>
            </div>

            <div className="row">
              <h4 className="">Bubble mint-80</h4>
              <div className="col-lg-3 col-md-6 col-xs-12">
                <BubbleText
                  text="Hier kann ich reintexten ohne Problemen - TOP"
                  openPosition={appBubbleTextOpenPosition.TOP}
                  bgColorClass={appBubbleBackground.MINT80}
                  textColorClass={appBubbleTextType.WHITE}
                />
              </div>

              <div className="col-lg-3 col-md-6 col-xs-12">
                <BubbleText
                  text="Hier kann ich reintexten ohne Problemen - BOTTOM"
                  openPosition={appBubbleTextOpenPosition.BOTTOM}
                  bgColorClass={appBubbleBackground.MINT80}
                  textColorClass={appBubbleTextType.WHITE}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-10">
          <h3 className="pb-5">Bubbles Tabs</h3>
          <div className="bg-mint-40 px-5 py-10">
            <div className="row pb-5">
              <div>
                <BubbleTabs text="Data" bubbleColor={'#E0424E'} icon={'data'} tabIndex={3} />
              </div>
            </div>
            <div className="row pb-5">
              <div>
                <BubbleTabs text="Tarife" bubbleColor={'#E0424E'} icon={'data'} index={0} />
              </div>
            </div>
            <div className="row pb-5">
              <div>
                <BubbleTabs text="Tarife" bubbleColor={'#33B6AE'} index={0} />
              </div>
            </div>
            <div className="row pb-5">
              <div>
                <BubbleTabs text="Very Long Bubble Text" bubbleColor={'#33B6AE'} index={3} />
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <h3 className="pb-5">Infos/Tooltips</h3>
          <div className="bg-mint-40 py-16 px-5">
            <div className="d-flex justify-content-between">
              <span className="pe-10">Hover over the icon: </span>
              <span className="">
                <Tooltip
                  title={'Hier steht ein Hinweis Hier steht ein Hinweis Hier steht ein Hinweis'}
                  placement="top"
                  offset={'40, 10'}>
                  <Icons name={'infogray100'} />
                </Tooltip>
              </span>
              <span className="">
                <Tooltip
                  title={'Hier steht ein Hinweis Hier steht ein Hinweis Hier steht ein Hinweis'}
                  placement="top"
                  offset="0,10">
                  <Icons name={'infogray100'} />
                </Tooltip>
              </span>
              <span className="">
                <Tooltip
                  title={'Hier steht ein Hinweis Hier steht ein Hinweis Hier steht ein Hinweis'}
                  placement="top"
                  offset={'-40, 10'}>
                  <Icons name={'infogray100'} />
                </Tooltip>
              </span>
            </div>
          </div>
        </div>

        <div className="py-10">
          <h3 className="pb-5">Numbers Circle</h3>
          <div className="row mx-auto bg-mint-40 py-10">
            <div className="col-4">
              <div>
                <h5 className="pb-5">default</h5>
                <NumbersCircle step="1" />
              </div>
            </div>
            <div className="col-4">
              <div>
                <h5 className="pb-5">active</h5>
                <NumbersCircle step="2" isActive={true} />
              </div>
            </div>
            <div className="col-4">
              <div>
                <h5 className="pb-5">completed</h5>
                <NumbersCircle step="3" isCompleted={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <section className="container py-10">
          <div className="container">
            <h3 className="text-primary">Generic Swipe</h3>
          </div>
          <div className="row mx-auto py-10 bg-mint-40">
            <div className="col-lg-6 col-12 ">
              <GenericSwip label="5G Highspeed" type={appGenericSwipType.ORANGE} />
            </div>
          </div>
        </section>
        <section className="container py-10 bg-mint-40 ">
          <div className="container">
            <div className="">
              <h3 className="text-primary">Popups 5G Modal</h3>
            </div>
            <div className="d-flex flex-wrap">
              <div className="py-5 px-3">
                <ButtonPrimary
                  label="5G Modal"
                  type={appButtonTypes.PRIMARY.DEFAULT}
                  onClick={() => setOpenModal('5GModal')}
                />
              </div>
            </div>
            <PopupSupport
              isOpen={openModal === '5GModal'}
              onClose={() => setOpenModal('')}
              icon="hi5G"
              iconHeight="165"
              iconWidth="235"
              bodyText="Jetzt noch schneller unterwegs – unsere neuen 5G-Tarife sind da. Jetzt wechseln!"
              rightButtonLabel="zu den 5G-Tarifen"
              onRightClick={() => setOpenModal('')}
            />

            <h3 className="pb-5">Topup History</h3>
            <div className="row pb-5">
              <div className="col-lg-3 col-md-6 col-xs-12">
                <TopupHistory dateTime={'14.02.2024 12:12'} amount={123} />
              </div>
            </div>
            <div className="py-5" id="dashboard-tariff-example">
              <h3>Dashboard Active Option</h3>
            </div>
            <div className="py-5">
              <div className="row">
                <div className="col-lg-6 col-xs-12 py-4">
                  <DashboardActiveOption
                    type="name"
                    volume="Volumen"
                    volumePercentage={50}
                    amount="amount"
                    remainingPeriod="Remaining Period"
                  />
                </div>

                <div className="col-lg-6 col-xs-12 py-4">
                  <DashboardActiveOption
                    type="Dayflat"
                    volume="Unlimited"
                    volumePercentage={100}
                    amount="/ Unlimited"
                    remainingPeriod="24 Stunden verfügbar"
                  />
                </div>

                <div className="col-lg-6 col-xs-12 py-4">
                  <DashboardActiveOption
                    type="Speedon"
                    volume="750 MB"
                    volumePercentage={75}
                    amount="/ 1GB"
                    remainingPeriod="verfügbar bis XX.XX.2023"
                  />
                </div>
              </div>
            </div>
            <div className="py-5" id="dashboard-tariff-example">
              <h3>Tariff Tabs Navigation</h3>
            </div>
            <div className="py-5">
              <div className="row">
                <div className="col-lg-6 col-xs-12 py-4">
                  <TarifTabsNavigation onChange={console.log} />
                </div>
              </div>
            </div>
            <div className="py-5" id="dashboard-tariff-example">
              <h3>Tariff Product Navigation</h3>
            </div>
            <div className="py-5">
              <div className="row">
                <div className="col-lg-6 col-xs-12 py-4">
                  <TarifProductNavigation onChange={console.log} bubbleColor={'#E0424E'} />
                </div>
              </div>
            </div>
            <div className="py-10">
              <h3 className="pb-5">SemiCircleProgressBar</h3>
              <div>
                <SemiCircleProgressBar
                  percentage={70}
                  strokeColor={'#F49A54'}
                  headlineText={'Flat'}
                  subHeadLineText={'unlimited'}
                />
              </div>
            </div>
            <div className="py-5" id="dashboard-tariff-example">
              <h3>Dashboard Teaser</h3>
            </div>
            <div className="py-5">
              <div className="row">
                <div className="col-lg-6 col-xs-12 py-4">
                  <DashboardTeaser
                    type={appDashboardTeaserTypes.SPEED_ONS}
                    buttonText="SpeedOn"
                    text={'Du benötigst mehr Datenvolumen?'}
                  />
                </div>
                <div className="col-lg-6 col-xs-12 py-4">
                  <DashboardTeaser
                    type={appDashboardTeaserTypes.DATAPASSES}
                    buttonText="Datenpässe"
                    text={'Du benötigst mehr Datenvolumen?'}
                  />
                </div>
                <div className="col-lg-6 col-xs-12 py-4">
                  <DashboardTeaser
                    type={appDashboardTeaserTypes.OTHER}
                    buttonText="Zur Aktion"
                    text={'Du benötigst mehr Datenvolumen?'}
                  />
                </div>
              </div>
            </div>
            <div className="py-5" id="dashboard-tariff-example">
              <h3>Generic Teaser</h3>
            </div>
            <div className="py-5">
              <div className="row">
                <div className="col-lg-6 col-12 py-4">
                  <GenericTeaser
                    type={appGenericTeaserType.GREEN}
                    buttonText={t('Zu 5G wechseln')}
                    heading={t('NEU: Noch schneller unterwegs!')}
                    cornerImage={appImages.teaser5gImage}
                  />
                </div>
                <div className="col-lg-6 col-12 py-4">
                  <GenericTeaser
                    type={appGenericTeaserType.ORANGE}
                    buttonText={t('Zu 5G wechseln')}
                    heading={t('NEU: Noch schneller unterwegs!')}
                    cornerImage={appImages.teaser5gImage}
                  />
                </div>
                <div className="col-lg-6 col-12 py-4">
                  <GenericTeaser
                    type={appGenericTeaserType.RED}
                    buttonText={t('Zu 5G wechseln')}
                    heading={t('NEU: Noch schneller unterwegs!')}
                    cornerImage={appImages.teaser5gImage}
                  />
                </div>
                <div className="col-lg-6 col-12 py-4">
                  <GenericTeaser
                    type={appGenericTeaserType.BLUE}
                    buttonText={t('Zu 5G wechseln')}
                    heading={t('NEU: Noch schneller unterwegs!')}
                    cornerImage={appImages.teaser5gImage}
                  />
                </div>
              </div>
            </div>
            <div className="py-5" id="dashboard-tariff-example">
              <h3>Dashboard Tariff</h3>
            </div>
            <div className="py-5">
              <div className="row">
                {tariffData.map((tariff, index) => (
                  <div className="col-xl-6 py-4" key={`tariff-card-with-details-${index}`}>
                    <DashboardTariff
                      tariffInfo={tariff.additionalInfo}
                      usageInfo={{
                        VOICE: {
                          headlineText: 'Flat',
                          subHeadLineText: 'unlimited',
                          endDate: 'Verfügbar bis 31.12.2023'
                        },
                        DATA: {
                          headlineText: '3,3 GB',
                          subHeadLineText: 'von 5 GB',
                          endDate: 'Verfügbar bis 31.12.2023'
                        },
                        SMS: {
                          headlineText: 'Flat',
                          subHeadLineText: 'unlimited',
                          endDate: 'Verfügbar bis 31.12.2023'
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col-xl-6 py-4">
                  <DashboardTariff status={appTariffStatus.IN_CHANGE} />
                </div>
                <div className="col-xl-6 py-4">
                  <DashboardTariff status={appTariffStatus.PAUSED} />
                </div>
                <div className="col-xl-6 py-4">
                  <DashboardTariff status={appTariffStatus.LOCKED} />
                </div>
              </div>
            </div>
            {/* <h4 className="">Bubbles Text</h4>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-xs-12">
                <BubbleText
                  text="Hier kann ich reintexten ohne Problemen"
                  // cmsHtml={t('')}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-xs-12">
                <BubbleText
                  text="Hier kann ich reintexten ohne Problemen"
                  type={appBubbleTextType.WHITE}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-xs-12">
                <BubbleText
                  text="Hier kann ich reintexten ohne Problemen"
                  openPosition={appBubbleTextOpenPosition.TOP}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-xs-12">
                <BubbleText
                  text="Hier kann ich reintexten ohne Problemen"
                  openPosition={appBubbleTextOpenPosition.TOP}
                  type={appBubbleTextType.WHITE}
                />
              </div>
            </div> */}
          </div>
          {/* <div className="container  py-5">
            <h4 className="">Bubbles Tabs</h4>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-xs-12">
                <BubbleTabs text="Daten" index={3} strokeColor={'#d23030'} icon={appIcons.data} />
              </div>
            </div>
          </div> */}
          <div className="container  py-5">
            <h4 className="">Bubbles Small Number</h4>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-xs-12">
                <BubbleSmallNumber text="1" />
              </div>
            </div>
          </div>
        </section>
        <section className="container py-10 bg-mint-40">
          <div className="container">
            <h3 className="">Tariffs cards</h3>

            <div className="py-10">
              <div className="pb-5">
                <h5>Dayflat small option card (default)</h5>
                <DayFlatOption
                  sizeVariant="small"
                  // offerInfo={offer}
                  priceType={appTariffPriceType.PRICE}
                  showBulletGroup={[
                    appTariffBulletGroup.DATA,
                    appTariffBulletGroup.DESCRIPTION_ONE
                  ]}
                />
              </div>
              <div>
                <h5>Dayflat large option card </h5>
                <DayFlatOption
                  sizeVariant="large"
                  // offerInfo={offer}
                  priceType={appTariffPriceType.PRICE}
                  showBulletGroup={[
                    appTariffBulletGroup.DATA,
                    appTariffBulletGroup.DESCRIPTION_ONE
                  ]}
                />
              </div>
            </div>
            <div className="py-10">
              <div className="pb-5">
                <h5>Speedon small option card (default)</h5>
                <SpeedOnOption
                  sizeVariant="small"
                  // offerInfo={offer}
                  priceType={appTariffPriceType.PRICE}
                  showBulletGroup={[
                    appTariffBulletGroup.DATA,
                    appTariffBulletGroup.DESCRIPTION_ONE
                  ]}
                />
              </div>
              <div>
                <h5>Speedon large option card </h5>
                <SpeedOnOption
                  sizeVariant="large"
                  // offerInfo={offer}
                  priceType={appTariffPriceType.PRICE}
                  showBulletGroup={[
                    appTariffBulletGroup.DATA,
                    appTariffBulletGroup.DESCRIPTION_ONE
                  ]}
                />
              </div>
            </div>

            <h5 className="pt-10">Small option cards (default)</h5>
            <div className="row">
              {tariffData.map((tariff, index) => (
                <div
                  className="col-lg-3 col-md-6 col-xs-12 py-4"
                  key={`tariff-product-type-1-${index}`}>
                  {/* <TariffOption
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showBulletGroup={[
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE
                    ]}
                  /> */}
                </div>
              ))}
            </div>

            <h5 className="pt-10">Large option cards</h5>
            <div className="row">
              {tariffData.map((tariff, index) => (
                <div
                  className="col-lg-3 col-md-6 col-xs-12 py-4"
                  key={`tariff-product-type-1-${index}`}>
                  {/* <TariffOption
                    sizeVariant="large"
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showBulletGroup={[
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE
                    ]}
                  /> */}
                </div>
              ))}
            </div>

            <h5 className="pt-10">Small tariff cards (default)</h5>
            <div className="row">
              {tariffData.map((tariff, index) => (
                <div
                  className="col-lg-3 col-md-6 col-xs-12 py-4"
                  key={`tariff-product-type-1-${index}`}>
                  <TariffProduct
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showBulletGroup={[
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE
                    ]}
                  />
                </div>
              ))}
            </div>
            <div className="row">
              {tariffData.map((tariff, index) => (
                <div
                  className="col-lg-3 col-md-6 col-xs-12 py-4"
                  key={`tariff-product-type-2-${index}`}>
                  <TariffProduct
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showBulletGroup={[
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE,
                      appTariffBulletGroup.DESCRIPTION_TWO
                    ]}
                  />
                </div>
              ))}
            </div>
            <div className="row">
              {tariffData.map((tariff, index) => (
                <div
                  className="col-lg-3 col-md-6 col-xs-12 py-4"
                  key={`tariff-product-type-3-${index}`}>
                  <TariffProduct
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showRibbonText
                    showBulletGroup={[
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE
                    ]}
                  />
                </div>
              ))}
            </div>
            <div className="row">
              {tariffData.map((tariff, index) => (
                <div
                  className="col-lg-3 col-md-6 col-xs-12 py-4"
                  key={`tariff-product-type-4-${index}`}>
                  <TariffProduct
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showRibbonText
                    showBulletGroup={[
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE,
                      appTariffBulletGroup.DESCRIPTION_TWO
                    ]}
                  />
                </div>
              ))}
            </div>
            <div className="row">
              {tariffData.map((tariff, index) => (
                <div
                  className="col-lg-3 col-md-6 col-xs-12 py-4"
                  key={`tariff-product-type-5-${index}`}>
                  <TariffProduct
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showRibbonText
                    showBulletGroup={[
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE,
                      appTariffBulletGroup.DESCRIPTION_TWO
                    ]}
                  />
                </div>
              ))}
            </div>
            <div className="row">
              {tariffData.map((tariff, index) => (
                <div
                  className="col-lg-3 col-md-6 col-xs-12 py-4"
                  key={`tariff-product-type-6-${index}`}>
                  <TariffProduct
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showRibbonText
                    showBulletGroup={[
                      appTariffBulletGroup.PROMOTIONAL_DATA,
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE,
                      appTariffBulletGroup.DESCRIPTION_TWO
                    ]}
                  />
                </div>
              ))}
            </div>
            <h5 className="pt-10">Large tariff cards</h5>
            <div className="row">
              {tariffData.map((tariff) => (
                <div className="col-lg-3 col-md-6 col-xs-12 py-4">
                  <TariffProduct
                    sizeVariant="large"
                    showRibbonText
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showBulletGroup={[
                      appTariffBulletGroup.PROMOTIONAL_DATA,
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE,
                      appTariffBulletGroup.DESCRIPTION_TWO
                    ]}
                  />
                </div>
              ))}
            </div>
            <h5 className="pt-10">Active tariff cards</h5>
            <div className="row">
              {tariffData.map((tariff) => (
                <div className="col-lg-3 col-md-6 col-xs-12 py-4">
                  <TariffProduct
                    isActive
                    showRibbonText
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showBulletGroup={[
                      appTariffBulletGroup.PROMOTIONAL_DATA,
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE,
                      appTariffBulletGroup.DESCRIPTION_TWO
                    ]}
                  />
                </div>
              ))}
            </div>
            <h5 className="pt-10">Active tariff large cards</h5>
            <div className="row">
              {tariffData.map((tariff) => (
                <div className="col-lg-3 col-md-6 col-xs-12 py-4">
                  <TariffProduct
                    sizeVariant="large"
                    isActive
                    showRibbonText
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showBulletGroup={[
                      appTariffBulletGroup.PROMOTIONAL_DATA,
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE,
                      appTariffBulletGroup.DESCRIPTION_TWO
                    ]}
                  />
                </div>
              ))}
            </div>

            <h5 className="pt-10">Active tariff small cards</h5>
            <div className="row">
              {tariffData.map((tariff) => (
                <div className="col-lg-3 col-md-6 col-xs-12 py-4">
                  <TariffProduct
                    sizeVariant="large"
                    isActive
                    showRibbonText
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showBulletGroup={[
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE
                    ]}
                  />
                </div>
              ))}
            </div>

            <h5 className="pt-10">Paused tariff cards</h5>
            <div className="row">
              {tariffData.map((tariff) => (
                <div className="col-lg-3 col-md-6 col-xs-12 py-4">
                  <TariffProduct
                    isPaused
                    showRibbonText
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showBulletGroup={[
                      appTariffBulletGroup.PROMOTIONAL_DATA,
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE,
                      appTariffBulletGroup.DESCRIPTION_TWO
                    ]}
                  />
                </div>
              ))}
            </div>
            <h5 className="pt-10">Paused tariff large cards</h5>
            <div className="row">
              {tariffData.map((tariff) => (
                <div className="col-lg-3 col-md-6 col-xs-12 py-4">
                  <TariffProduct
                    sizeVariant="large"
                    isPaused
                    showRibbonText
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showBulletGroup={[
                      appTariffBulletGroup.PROMOTIONAL_DATA,
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE,
                      appTariffBulletGroup.DESCRIPTION_TWO
                    ]}
                  />
                </div>
              ))}
            </div>

            <h5 className="pt-10">Paused tariff small cards</h5>
            <div className="row">
              {tariffData.map((tariff) => (
                <div className="col-lg-3 col-md-6 col-xs-12 py-4">
                  <TariffProduct
                    isPaused
                    showRibbonText
                    tariffInfo={tariff.additionalInfo}
                    priceType={appTariffPriceType.PRICE}
                    showBulletGroup={[
                      appTariffBulletGroup.DATA,
                      appTariffBulletGroup.DESCRIPTION_ONE
                    ]}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="container py-10 bg-mint-40">
          <div className="py-5">
            <h3 className="text-primary">Form Dropdown</h3>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormDropdown
                id="countryCode"
                name="countryCode"
                label="TITLE"
                value={1}
                placeholder="Placeholder"
                autoComplete="off"
                onChange={console.log}
                onBlur={console.log}
                options={(countries || []).map((country) => ({
                  id: country.id,
                  label: country.value
                }))}
                invalid
                invalidMessage="Test"
              />
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormDropdown
                label="TITLE"
                startIcon="maildarkgreen"
                value={1}
                placeholder="Placeholder"
                autoComplete="off"
                onChange={console.log}
                onBlur={console.log}
                options={(countries || []).map((country) => ({
                  id: country.id,
                  label: country.value
                }))}
                validMessage="Test"
                invalidMessage="Test"
              />
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormDropdown
                label="TITLE"
                startIcon="maildarkgreen"
                value={1}
                placeholder="Placeholder"
                autoComplete="off"
                onChange={console.log}
                onBlur={console.log}
                options={(countries || []).map((country) => ({
                  id: country.id,
                  label: country.value
                }))}
                valid={test}
                validMessage="Test"
                invalidMessage="Test"
              />
            </div>
          </div>
        </section>
        <section className="container py-10 bg-mint-40">
          <div className="py-5">
            <h3 className="text-primary">Form Date picker</h3>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormDatePicker
                // date={testDate}
                // onDateChange={setTestDate}
                // label="TITLE"
                startIcon="dateGreen"
                placeholder="Test Placeholder 1"
                value={testDate}
                onChange={(date) => {
                  console.log('date', date);
                  setTestDate(date);
                }}
                // autoComplete="off"
                // invalid={test}
                // validMessage="Test"
                // invalidMessage="Test"
              />
            </div>
            {/* <div className="col-lg-4 col-md-6 col-xs-12">
              <FormDatePicker
                // label="TITLE"
                // startIcon="maildarkgreen"
                // placeholder="TestPlaceholder 2"
                // value={testDate}
                // onChange={(date) => {
                //   console.log('date', date);
                //   setTestDate(date);
                // }}
              // autoComplete="off"
              // validMessage="Test"
              // invalidMessage="Test"
              />
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormDatePicker
                // label="TITLE"
                // startIcon="maildarkgreen"
                // placeholder="Test Placeholder 3"
                // value={testDate}
                // onChange={(date) => {
                //   console.log('date', date);
                //   setTestDate(date);
                // }}
              // autoComplete="off"
              // valid={test}
              // validMessage="Test"
              // invalidMessage="Test"
              />
            </div> */}
          </div>
        </section>
        <section className="container py-10 bg-mint-40 ">
          <div className="py-5">
            <h3 className="text-primary">Form Code</h3>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormCode
                label="CODE"
                // value="1234"
                // placeholder="Placeholder"
                autoComplete="off"
                invalid={test}
                validMessage="Test"
                invalidMessage="Test"
              />
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormCode
                label="CODE"
                // placeholder="Password"
                type="password"
                autoComplete="off"
                // valid
                validMessage="Test"
                invalidMessage="Test"
              />
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormCode
                label="CODE"
                // placeholder="Password"
                type="password"
                autoComplete="off"
                valid
                validMessage="Test"
                invalidMessage="Test"
              />
            </div>
          </div>
        </section>
        <section className="container py-10 bg-mint-40 ">
          <div className="py-5">
            <h3 className="text-primary">Form Voucher</h3>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormVoucher
                label="CODE"
                type="code"
                value="123456"
                // placeholder="Placeholder"
                autoComplete="off"
                invalid={test}
                validMessage="Test"
                invalidMessage="Test"
              />
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormVoucher
                label="CODE"
                // placeholder="Password"
                type="code"
                autoComplete="off"
                // valid
                validMessage="Test"
                invalidMessage="Test"
              />
            </div>
          </div>
        </section>
        <section className="container py-10 bg-mint-40 ">
          <div className="py-5">
            <h3 className="text-primary">Form Input</h3>
          </div>

          <div className="row py-5">
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormInput
                label="Label"
                placeholder="Placeholder"
                autoComplete="off"
                startIcon="maildarkgreen"
                validMessage="Test"
                invalidMessage="Test"
              />
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormInput
                type="text"
                label="Label"
                placeholder="Placeholder 123"
                autoComplete="off"
                startIcon="maildarkgreen"
                invalid={test}
                validMessage="Test"
                invalidMessage="Validation message"
              />
            </div>

            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormInput
                label="Label"
                placeholder="Password"
                type="password"
                autoComplete="off"
                // valid
                validMessage="Test"
                invalidMessage="Test"
              />
            </div>
          </div>
          <div className="row py-5">
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormInput
                placeholder="Without label"
                type="text"
                autoComplete="off"
                validMessage="Test"
                invalidMessage="Test"
                // hint="Hint text"
              />
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <FormInput
                placeholder="Without label"
                type="text"
                autoComplete="off"
                validMessage="Test"
                invalidMessage="Validation message"
                invalid={test}
                // hint="Hint text"
              />
            </div>
          </div>
          <div className="row py-5">
            <div className="pt-5 col-lg-4 col-md-6 col-xs-12">
              <FormInput
                placeholder="With icon on the right, without tooltip"
                type="text"
                autoComplete="off"
                validMessage="Test"
                invalidMessage="Test"
                rightIcon="infomint"
                showTooltip={false}
              />
            </div>
            <div className="pt-5 col-lg-4 col-md-6 col-xs-12">
              <FormInput
                placeholder="With icon on the right, with tooltip"
                type="text"
                autoComplete="off"
                validMessage="Test"
                invalidMessage="Test"
                rightIcon="infomint"
                showTooltip={true}
                tooltipTitle={
                  'Tooltip text! Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, a. Labore est distinctio et aliquam. Soluta dolorum sequi ullam obcaecati?'
                }
              />
            </div>
          </div>
        </section>
        <section className="container py-10 bg-mint-40">
          <div className="py-5">
            <h3 className="text-primary">Buttons Primary</h3>
          </div>
          <div className="d-flex flex-wrap">
            <div className="py-5 px-3">
              <ButtonPrimary
                label="BUTTON"
                icon="arrowright"
                buttonType={appButtonTypes.PRIMARY.DEFAULT}
              />
            </div>
            <div className="py-5 px-3">
              <ButtonPrimary label="BUTTON" buttonType={appButtonTypes.PRIMARY.DEFAULT} />
            </div>
            <div className="py-5 px-3">
              <ButtonPrimary
                label="BUTTON"
                icon="arrowrightcolor"
                iconHover="arrowright"
                buttonType={appButtonTypes.PRIMARY.LIGHT}
              />
            </div>
            <div className="py-5 px-3">
              <ButtonPrimary label="BUTTON" buttonType={appButtonTypes.PRIMARY.LIGHT} />
            </div>
            <div className="py-5 px-3">
              <ButtonPrimary
                label="BUTTON"
                icon="arrowright"
                buttonType={appButtonTypes.PRIMARY.MINT}
              />
            </div>
            <div className="py-5 px-3">
              <ButtonPrimary label="BUTTON" buttonType={appButtonTypes.PRIMARY.MINT} />
            </div>
            <div className="py-5 px-3">
              <ButtonPrimary
                label="BUTTON"
                icon="arrowright"
                buttonType={appButtonTypes.PRIMARY.DEFAULT}
                disabled
              />
            </div>
            <div className="py-5 px-3">
              <ButtonPrimary label="BUTTON" buttonType={appButtonTypes.PRIMARY.DEFAULT} disabled />
            </div>
          </div>
          <div className="py-5">
            <h3 className="text-primary">Buttons Secondary</h3>
          </div>
          <div className="d-flex flex-wrap">
            <br />
            <hr />
            <div className="py-5 px-3 bg-black d-flex align-item-center justify-content-center">
              <ButtonSecondary
                label="Button secondary"
                type={appButtonTypes.SECONDARY.LIGHT}
                icon="forward"
              />
            </div>
            <div className="py-5 px-3 bg-black d-flex align-item-center justify-content-center">
              <ButtonSecondary
                label="Button secondary"
                type={appButtonTypes.SECONDARY.MINT}
                icon="forwardmint"
              />
            </div>
            <div className="py-5 px-3 bg-black d-flex align-item-center justify-content-center">
              <ButtonSecondary
                label="Button secondary"
                type={appButtonTypes.SECONDARY.DEFAULT}
                icon="forwardprimary"
              />
            </div>
            <div className="py-5 px-3 bg-black d-flex align-item-center justify-content-center">
              <ButtonSecondary
                label="Button secondary"
                type={appButtonTypes.SECONDARY.DARK}
                icon="forwarddark"
              />
            </div>
          </div>

          <div className="py-5">
            <h3 className="text-primary">Buttons Misc</h3>
          </div>
          <div className="d-flex flex-wrap">
            <br />
            <hr />
            <div className="py-5 px-3 bg-black d-flex align-item-center justify-content-center">
              <ButtonMisc
                label="Button secondary"
                type={appButtonTypes.SECONDARY.LIGHT}
                icon="forward"
              />
            </div>
            <div className="py-5 px-3 bg-black d-flex align-item-center justify-content-center">
              <ButtonMisc
                label="Button secondary"
                type={appButtonTypes.SECONDARY.MINT}
                icon="forwardmint"
              />
            </div>
            <div className="py-5 px-3 bg-black d-flex align-item-center justify-content-center">
              <ButtonMisc
                label="Button secondary"
                type={appButtonTypes.SECONDARY.DEFAULT}
                icon="forwardprimary"
              />
            </div>
            <div className="py-5 px-3 bg-black d-flex align-item-center justify-content-center">
              <ButtonMisc
                label="Button secondary"
                type={appButtonTypes.SECONDARY.DARK}
                icon="forwarddark"
              />
            </div>
          </div>

          <div className="py-5">
            <h3 className="text-primary">Buttons Toggles</h3>
          </div>
          <div className="d-flex flex-wrap">
            <br />
            <hr />
            <div className="py-5 px-3 bg-black d-flex align-item-center justify-content-center">
              <ButtonToggle />
            </div>
            <div className="py-5 px-3 bg-black d-flex align-item-center justify-content-center">
              <ButtonToggle value={true} />
            </div>
          </div>

          <div className="py-5">
            <h3 className="text-primary">Buttons Radio</h3>
          </div>
          <div className="d-flex flex-wrap">
            <br />
            <hr />
            <div className="py-5 px-3 bg-black d-flex align-item-center justify-content-center">
              <ButtonRadio />
            </div>
            <div className="py-5 px-3 bg-black d-flex align-item-center justify-content-center">
              <ButtonRadio value={'true'} />
            </div>
          </div>
        </section>

        <section className="container pt-5 pb-10 bg-body">
          <div className="py-5">
            <h3 className="text-primary">InfoNotification</h3>
          </div>
          <div className="pb-5">
            <div className="py-1">
              <InfoNotification type={appAlert.ERROR} message="Code ungültig (ERROR)" />
            </div>
            <div className="py-1">
              <InfoNotification type={appAlert.SUCCESS} message="Code ungültig (SUCCESS)" />
            </div>
            <div className="py-1">
              <InfoNotification type={appAlert.WARNING} message="Code ungültig (WARNING)" />
            </div>
            <div className="py-1">
              <InfoNotification type={appAlert.INFO} message="Code ungültig (INFO)" />
            </div>
            <div className="py-1">
              <InfoNotification type={appAlert.DISABLED} message="Code ungültig (DISABLED)" />
            </div>
          </div>

          <div className="pt-5">
            <h5 className="text-primary">InfoNotification Password Error</h5>
          </div>
          <div className="pb-5">
            <div>
              <InfoNotification type={appAlert.ERROR} message="mind. 8 Zeichen" />
            </div>
            <div>
              <InfoNotification type={appAlert.ERROR} message="1 Groß + 1 Kleinebuchstabe" />
            </div>
            <div>
              <InfoNotification type={appAlert.ERROR} message="mind. 1 Ziffer" />
            </div>
            <div>
              <InfoNotification type={appAlert.ERROR} message="mind. 1 Sonderzeichen" />
            </div>
            <div>
              <InfoNotification type={appAlert.ERROR} message="Passwort stimmt überein" />
            </div>
          </div>

          <div className="pt-5">
            <h5 className="text-primary">InfoNotification Password Success</h5>
          </div>
          <div className="pb-5">
            <div>
              <InfoNotification type={appAlert.SUCCESS} message="mind. 8 Zeichen" />
            </div>
            <div>
              <InfoNotification type={appAlert.SUCCESS} message="1 Groß + 1 Kleinebuchstabe" />
            </div>
            <div>
              <InfoNotification type={appAlert.SUCCESS} message="mind. 1 Ziffer" />
            </div>
            <div>
              <InfoNotification type={appAlert.SUCCESS} message="mind. 1 Sonderzeichen" />
            </div>
            <div>
              <InfoNotification type={appAlert.SUCCESS} message="Passwort stimmt überein" />
            </div>
          </div>

          <div className="pt-5">
            <h5 className="text-primary">InfoNotification Password Default</h5>
          </div>
          <div>
            <div className="">
              <InfoNotification message="mind. 8 Zeichen" />
            </div>
            <div className="">
              <InfoNotification message="1 Groß + 1 Kleinebuchstabe" />
            </div>
            <div className="">
              <InfoNotification message="mind. 1 Ziffer" />
            </div>
            <div className="">
              <InfoNotification message="mind. 1 Sonderzeichen" />
            </div>
            <div className="">
              <InfoNotification message="Passwort stimmt überein" />
            </div>
          </div>
        </section>

        {/* <section className="container py-20 bg-mint-40 ">
          <div className="py-5">
            <h5 className="text-primary">InfoNotification Tooltip</h5>
          </div>
          <div>
            <div className="pt-16 bg-accent">
              <div
                className="tooltip bs-tooltip-auto fade show"
                role="tooltip"
                id="tooltip738221"
                data-popper-placement="top"
                style={{
                  position: 'absolute',
                  inset: 'auto auto 0px 0px',
                  margin: 0,
                  transform: 'translate3d(34.6667px, 13901.7px, 0px)'
                }}>
                <div
                  className="tooltip-arrow"
                  style={{
                    position: 'absolute',
                    left: 0,
                    transform: 'translate3d(78px, 0px, 0px)'
                  }}
                />
                <div className="tooltip-inner">
                  Hier steht ein Hinweis
                  <br />
                  Hier steht ein Hinweis
                  <br />
                  Hier steht ein Hinweis
                </div>
              </div>
            </div>
            <Tooltip title="top" toggle="tooltip">
              <div className="">HOVER</div>
            </Tooltip>
            <div className="">
              <InfoNotification message="1 Groß + 1 Kleinebuchstabe" />
            </div>
            <div className="">
              <InfoNotification message="mind. 1 Ziffer" />
            </div>
            <div className="">
              <InfoNotification message="mind. 1 Sonderzeichen" />
            </div>
          </div>
        </section> */}
      </div>
      <div className="bg-body py-5">
        <section className="container-md">
          <div className="py-5">
            <h3 className="text-primary">Form Text Area</h3>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="cancel-contract-area">
                <FormTextArea
                  placeholder="Hiermit möchte ich …"
                  rows="3"
                  id="terminationReason"
                  name="terminationReason"
                  value="terminationReason"
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  // valid={touched.terminationReason && !errors.terminationReason}
                  invalid={true}
                  invalidMessage="Test"
                />
              </div>
            </div>
          </div>
          <div className="pb-5 pt-10">
            <h3 className="text-primary">FAQ Tab</h3>
          </div>
          <div className="d-flex">
            <div className="px-3">
              <FaqTab title="Aktivierung & Legitimation" isActive />
            </div>
            <div className="px-3">
              <FaqTab title="Aktivierung & Legitimation" />
            </div>
          </div>
          <div className="pb-5 pt-10">
            <h3 className="text-primary">Video Player</h3>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-xs-12 pt-5 pb-0 py-lg-0 ps-lg-0 px-xl-4">
              <Player video={testVideo} previewImage={appImages.playerBg} />
            </div>
          </div>
          <div className="pb-5 pt-10">
            <h3 className="text-primary">After Payment Screen Success and Error</h3>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-10 col-xs-12 mx-auto">
              <div className="pt-10 pb-10">
                <AfterPaymentScreen
                  icon="layerdarkgreen"
                  heading="Deine Auto-Aufladung wurde erfolgreich eingerichtet!"
                  buttonLabel="Weiter"
                  buttonIcon="arrowright"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-10 col-xs-12 mx-auto">
              <div className="pt-10 pb-10">
                <AfterPaymentScreen
                  icon="errorcolor"
                  heading="Hoppala,<br /> leider ist etwas schief gelaufen!"
                  bodyText="Das tut uns sehr leid – versuche es bitte später erneut."
                  buttonLabel="zurück"
                  // buttonIcon="arrowright"
                />
              </div>
            </div>
          </div>
          <div className="py-5">
            <h3 className="text-primary">Payment Screen after login</h3>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-10 col-xs-12 mx-auto">
              <div className="py-10">
                <Payment
                  formInitValues={{ paymentMethodName: '' }}
                  formValidations={paymentValidations}
                  formSubmit={onStepFourSubmit}
                  paymentMethods={payments}
                />
              </div>
            </div>
          </div>
          <div className="py-5">
            <h3 className="text-primary">Tarif Feedback Success and Tarif Feedback Error</h3>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12 mx-auto">
              <div className="pt-10 pb-10 px-2 px-md-0">
                <TarifFeedbackSuccess
                  icon="confetticolor"
                  heading={t('nc_tarif_suc_hdl')}
                  // bodyText="Das tut uns sehr leid – versuche es bitte später erneut."
                  buttonLabel={t('nc_tarif_suc_btn')}
                  // buttonIcon="arrowright"
                >
                  <div>
                    <p
                      className="m-0"
                      dangerouslySetInnerHTML={{ __html: t('nc_tarif_suc_title') }}
                    />
                    <p
                      className="py-8 m-0"
                      dangerouslySetInnerHTML={{ __html: t('nc_tarif_suc_txt') }}
                    />
                  </div>
                </TarifFeedbackSuccess>
              </div>
            </div>
          </div>
          <div className="py-5">
            <h3>CounterButton</h3>
          </div>
          <div className="d-flex flex-wrap">
            <div className="px-3">
              <CounterButton label="10 €" buttonType={appCounterButtonTypes.LIGHT} />
            </div>
            <div className="px-3">
              <CounterButton label="10 €" buttonType={appCounterButtonTypes.DARK} />
            </div>
          </div>
          <div className="pt-10 pb-5">
            <h3>Counters</h3>
          </div>
          <div className="d-flex flex-wrap">
            <div className="px-3">
              <Counter
                onClickMinus={(value) => {
                  console.log('Counter---value', value);
                }}
                onClickPlus={(value) => {
                  console.log('Counter---value', value);
                }}
                label="10 €"
                value="10"
                type={appCounterTypes.LIGHT}
              />
            </div>
            <div className="px-3">
              <Counter
                onClickMinus={(value) => {
                  console.log('Counter---value', value);
                }}
                onClickPlus={(value) => {
                  console.log('Counter---value', value);
                }}
                label="15 €"
                value="15"
                type={appCounterTypes.DARK}
              />
            </div>
          </div>
          <div className="test-badge">
            <div className="pb-5 pt-10">
              <h3>Badge</h3>
            </div>
            <div>
              <Badge title="Unsere Empfehlung" />
            </div>
          </div>
          <div className="test-modal">
            <div className="pt-10 pb-5">
              <h3 className="text-primary">Popups/Modals (Button click to open)</h3>
            </div>
            <div className="d-flex flex-wrap">
              <div className="py-5 px-3">
                <ButtonPrimary
                  label="SUCCESS"
                  type={appButtonTypes.PRIMARY.DEFAULT}
                  onClick={() => setOpenModal('success')}
                />
              </div>
              <div className="py-5 px-3">
                <ButtonPrimary
                  label="IMAGE"
                  type={appButtonTypes.PRIMARY.DEFAULT}
                  onClick={() => setOpenModal('image')}
                />
              </div>
              <div className="py-5 px-3">
                <ButtonPrimary
                  label="PASSWORD"
                  type={appButtonTypes.PRIMARY.DEFAULT}
                  onClick={() => setOpenModal('password')}
                />
              </div>
              <div className="py-5 px-3">
                <ButtonPrimary
                  label="SUPPORT"
                  type={appButtonTypes.PRIMARY.DEFAULT}
                  onClick={() => setOpenModal('support')}
                />
              </div>
              <div className="py-5 px-3">
                <ButtonPrimary
                  label="PDF Modal"
                  type={appButtonTypes.PRIMARY.DEFAULT}
                  onClick={() => setOpenModal('pdf_modal')}
                />
              </div>
            </div>

            <PopupSuccess
              isOpen={openModal === 'success'}
              icon="confetticolor"
              title="Super, du erhältst nun dein <span class='mint-text'>Einmal-Passwort</span> per SMS!"
              buttonLabel="Ok, verstanden!"
              onClose={() => setOpenModal('')}>
              <div>
                Dies kann einige Minuten dauern. Bitte log’ dich mit diesem Passwort erneut ein und
                vergebe anschließend eine neues Passwort!
              </div>
            </PopupSuccess>

            <PopupSuccess
              isOpen={openModal === 'pdf_modal'}
              title="Documents Pdf"
              onClose={() => setOpenModal('')}>
              {[testPdf].map((item, index) => (
                <div className="d-flex pb-4" key={`contract-pdf-${index}`}>
                  <Icons name="pdfcolor" />
                  <a
                    className="nc-realtextpro-copy-bold ps-4"
                    href={item?.url}
                    target="_blank"
                    rel="noreferrer">
                    {item?.name}
                  </a>
                </div>
              ))}
            </PopupSuccess>

            <PopupImage
              isOpen={openModal === 'image'}
              title="Möchtest du dich <span class='mint-text'>wirklich ausloggen?</span>"
              buttonLabel="jetzt ausloggen"
              image="popupImage"
              onClose={() => setOpenModal('')}
            />

            <PopupPassword
              isOpen={openModal === 'password'}
              title="Du hast dein <span class='mint-text'>Passwort vergessen?</span>"
              buttonLabel="Passwort anfordern"
              bodyText="Kein Problem! Wir senden dir ein Einmalpasswort per SMS zu. Gib’ dafür einfach deine Rufnummer und PUK 1 ein"
              onClose={() => setOpenModal('')}
            />

            <PopupSupport
              isOpen={openModal === 'support'}
              title="Du brauchst Hilfe? <span class='mint-text'>Unser Support ist für dich da!</span>"
              leftButtonLabel="CHAT"
              rightButtonLabel="0800 33 2233"
              bodyText="Jetzt noch schneller unterwegs – unsere neuen 5G-Tarife sind da. Jetzt wechseln!"
              onClose={() => setOpenModal('')}
            />

            <div className="d-flex flex-wrap">
              <div className="py-5 px-3">
                <ButtonPrimary
                  label="Password Change Modal"
                  type={appButtonTypes.PRIMARY.DEFAULT}
                  onClick={() => setOpenModal('forcePasswordChange')}
                />
              </div>
            </div>
            <PopupSupport
              isOpen={openModal === 'forcePasswordChange'}
              onClose={() => setOpenModal('')}
              icon="passwordchangegreen"
              title={t('nc_login_otp_modal_hdl')}
              rightButtonLabel={t('nc_login_otp_modal_btn')}
              onRightClick={() => setOpenModal('')}
              bodyText={t('nc_login_otp_modal_txt')}
            />
          </div>
          <div className="py-5">
            <h3>Payment Field</h3>
          </div>
          <div className="py-5">
            <div className="row">
              <div className="col-4">
                <PaymentFields icon="paypal" label="Paypal" />
              </div>
            </div>
          </div>
          <div className="py-5">
            <h3 className="text-primary">Navigation Title</h3>
          </div>
          <div className="py-5">
            <NavigationPageTitle title="Navigate Title lg" variant="lg" />
          </div>
          <div className="py-5">
            <NavigationPageTitle
              title="Navigate with back Title lg"
              variant="lg"
              isBackButton
              onBackClick={() => console.log('lg back clicked')}
            />
          </div>
          <div className="py-5">
            <NavigationPageTitle title="Navigate Title sm" variant="sm" />
          </div>
          <div className="py-5">
            <NavigationPageTitle
              title="Navigate with back Title sm"
              variant="sm"
              isBackButton
              onBackClick={() => console.log('sm back clicked')}
            />
          </div>
          <div className="py-5">
            <NavigationPageTitle title="Navigate Title xs" variant="xs" />
          </div>
          <div className="py-5">
            <NavigationPageTitle
              title="Navigate with back Title xs"
              variant="xs"
              isBackButton
              onBackClick={() => console.log('xs back clicked')}
            />
          </div>
          <div className="py-5">
            <h3 className="text-primary">Info Boxes Arrow</h3>
          </div>
          <div className="py-5 px-3">
            <div className="row">
              <div className="col-4">
                <InfoBox
                  label="Code nicht erhalten? Melde dich bei unserem Support."
                  rightIcon="arrowrightcolor"
                  leftIcon="supportcolor"
                  type={appInfoBoxType.DARK}
                  onInfoClick={() => console.log('info clicked', appInfoBoxType.DARK)}
                />
              </div>
            </div>
          </div>
          <div className="py-5 px-3">
            <div className="row">
              <div className="col-4">
                <InfoBox
                  label="Code nicht erhalten? Melde dich bei unserem Support."
                  rightIcon="arrowright"
                  leftIcon="support"
                  type={appInfoBoxType.SUCCESS}
                  onInfoClick={() => console.log('info clicked', appInfoBoxType.SUCCESS)}
                />
              </div>
            </div>
          </div>
          <div className="py-5 px-3">
            <div className="row">
              <div className="col-4">
                <InfoBox
                  label="Code nicht erhalten? Melde dich bei unserem Support."
                  rightIcon="arrowrightcolor"
                  leftIcon="supportcolor"
                  type={appInfoBoxType.WARNING}
                  onInfoClick={() => console.log('info clicked', appInfoBoxType.WARNING)}
                />
              </div>
            </div>
          </div>
          <div className="py-5 px-3">
            <div className="row">
              <div className="col-4">
                <InfoBox
                  label="Code nicht erhalten? Melde dich bei unserem Support."
                  rightIcon="arrowrightcolor"
                  leftIcon="supportcolor"
                  type={appInfoBoxType.LIGHT}
                  onInfoClick={() => console.log('info clicked', appInfoBoxType.LIGHT)}
                />
              </div>
            </div>
          </div>
          <div className="py-5">
            <h3 className="text-primary">Info Boxes Close</h3>
          </div>
          <div className="py-5 px-3">
            <div className="row">
              <div className="col-4">
                <InfoBoxClose
                  label="Code nicht erhalten? Melde dich bei unserem Support."
                  rightIcon="closecolor"
                  leftIcon="supportcolor"
                  type={appInfoBoxType.DARK}
                  onInfoClick={() => console.log('info clicked', appInfoBoxType.DARK)}
                />
              </div>
            </div>
          </div>
          <div className="py-5 px-3">
            <div className="row">
              <div className="col-4">
                <InfoBoxClose
                  label="Code nicht erhalten? Melde dich bei unserem Support."
                  rightIcon="close"
                  leftIcon="support"
                  type={appInfoBoxType.SUCCESS}
                  onInfoClick={() => console.log('info clicked', appInfoBoxType.SUCCESS)}
                />
              </div>
            </div>
          </div>
          <div className="py-5 px-3">
            <div className="row">
              <div className="col-4">
                <InfoBoxClose
                  label="Code nicht erhalten? Melde dich bei unserem Support."
                  rightIcon="closecolor"
                  leftIcon="supportcolor"
                  type={appInfoBoxType.WARNING}
                  onInfoClick={() => console.log('info clicked', appInfoBoxType.WARNING)}
                />
              </div>
            </div>
          </div>
          <div className="py-5 px-3">
            <div className="row">
              <div className="col-4">
                <InfoBoxClose
                  label="Code nicht erhalten? Melde dich bei unserem Support."
                  rightIcon="closecolor"
                  leftIcon="supportcolor"
                  type={appInfoBoxType.LIGHT}
                  onInfoClick={() => console.log('info clicked', appInfoBoxType.LIGHT)}
                />
              </div>
            </div>
          </div>
          <div className="py-5">
            <h3 className="text-primary">InfoBox Toggle</h3>
          </div>
          <div className="py-5 px-3">
            <div className="row">
              <div className="col-4">
                <InfoBoxToggle
                  labelTitle="Smart Login"
                  label="Melde dich bei unserem Support."
                  leftIcon="faceIdDark"
                  onInfoClick={() => console.log('info clicked', appInfoBoxType.LIGHT)}
                />
              </div>
            </div>
          </div>
          <div className="py-5" id="dashboard-credit">
            <h3>Dashboard credit</h3>
            <div className="row">
              <div className="col-xl-6 col-xs-12">
                <DashboradCredit credit="30.02" text="Dein Guthaben:" showPlusIcon />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-xl-6 col-xs-12">
                <DashboradCredit credit="5.43" text="Dein Guthaben:" isCreditLow showPlusIcon />
              </div>
            </div>
          </div>
          {/* <div className="my-5">
            <>
              <h1 className="mt-5 mb-1">Alerts</h1>
              <svg xmlns="http://www.w3.org/2000/svg" className="d-none">
                <symbol id="check-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </symbol>
                <symbol id="info-fill" viewBox="0 0 16 16">
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                </symbol>
                <symbol id="exclamation-triangle-fill" viewBox="0 0 16 16">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </symbol>
              </svg>
              <div className="alert alert-primary d-flex align-items-center" role="alert">
                <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Info:">
                  <use xlinkHref="#info-fill" />
                </svg>
                <h3 className="nc-realheadpro-h3 ps-2">An example alert with an icon</h3>
              </div>
              <div className="alert alert-success d-flex align-items-center" role="alert">
                <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Success:">
                  <use xlinkHref="#check-circle-fill" />
                </svg>
                <h3 className="nc-realheadpro-h3 ps-2">An example alert with an icon</h3>
              </div>
              <div className="alert alert-warning d-flex align-items-center" role="alert">
                <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Warning:">
                  <use xlinkHref="#exclamation-triangle-fill" />
                </svg>
                <h3 className="nc-realheadpro-h3 ps-2">An example alert with an icon</h3>
              </div>
              <div className="alert alert-danger d-flex align-items-center" role="alert">
                <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Danger:">
                  <use xlinkHref="#exclamation-triangle-fill" />
                </svg>
                <h3 className="nc-realheadpro-h3 ps-2">An example alert with an icon</h3>
              </div>
            </>
          </div> */}
          <div className="py-5">
            <h3 className="text-primary">Image Integration</h3>
          </div>
          <Image
            className="logo mx-100"
            refs="nc_explain-video_topup_preview"
            resRefs="nc_explain-video_topup_preview"
            alt="sim card activation "
          />
        </section>
      </div>
      <h3 className="pt-10">Voucher Topup</h3>
      <MainLayout showChildren>
        <div className="container-md container-page1">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
              <NavigationPageTitle isBackButton title={t('nc_topup_voucher_hdl')} variant="lg" />
              <div
                className="py-8"
                dangerouslySetInnerHTML={{ __html: t('nc_topup_voucher_txt1') }}
              />
              <div className="col-12">
                <FormVoucher
                  label={t('nc_topup_voucher_label_code')}
                  type="code"
                  value={code}
                  placeholder={t('nc_topup_voucher_input_code')}
                  autoComplete="off"
                  invalid={!code}
                  valid={code}
                  validMessage={code ? t('nc_topup_voucher_input_code_acc') : null}
                  invalidMessage={!code ? t('nc_topup_voucher_input_code_err') : null}
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 text-center pt-12">
                <ButtonPrimary
                  // type="submit"
                  type="button"
                  label={t('nc_topup_voucher_btn1')}
                  // disabled={

                  // }
                  buttonType={appButtonTypes.PRIMARY.DEFAULT}
                />
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
      <h3 className="pt-10">FeedbackScreen success</h3>
      <MainLayout showChildren>
        <div className="container-md container-page1">
          <div className="row pt-1">
            <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
              <FeedbackScreen
                type="success"
                heading={t('nc_topup_voucher_suc_hdl')}
                buttonLabel={t('nc_topup_voucher_suc_btn')}
                icon="layerdarkgreen"
              />
            </div>
          </div>
        </div>
      </MainLayout>
      <h3 className="pt-10">FeedbackScreen error</h3>
      <MainLayout showChildren>
        <div className="container-md container-page1">
          <div className="row pt-1">
            <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
              <FeedbackScreen
                type="error"
                heading={t('nc_topup_voucher_err_hdl')}
                buttonLabel={t('nc_topup_voucher_err_btn')}
                icon="errorcolor">
                <p
                  className="pb-8"
                  dangerouslySetInnerHTML={{ __html: t('nc_topup_voucher_err_title') }}
                />
              </FeedbackScreen>
            </div>
          </div>
        </div>
      </MainLayout>
      <h3 className="pt-10">FeedbackScreen success</h3>
      <MainLayout showChildren>
        <div className="container-md container-page1">
          <div className="row pt-1">
            <div className="col-xxl-4 col-xl-5 col-lg-4 col-md-8 col-sm-6 col-12 mx-auto px-6 px-sm-0 px-md-3">
              <FeedbackScreen
                type="success"
                heading={t(
                  '<span style="color:#074D52">Deine Auto-Aufladung wurde </span><span style="color:#33B6AE">erfolgreich eingerichtet!</span>'
                )}
                buttonLabel={t('nc_topup_suc_btn')}
                icon="layerdarkgreen"
              />
            </div>
          </div>
        </div>
      </MainLayout>
      <h3 className="pt-10">FeedbackScreen success</h3>
      <MainLayout showChildren>
        <div className="container-md container-page1">
          <div className="row pt-1">
            <div className="col-lg-8 col-12 mx-auto px-6 px-sm-0 px-md-3">
              <FeedbackScreen
                type="success"
                heading={t('nc_topup_suc_immi_hdl')}
                buttonLabel={t('nc_topup_suc_btn')}
                icon="layerdarkgreen">
                <p
                  className="pb-4"
                  dangerouslySetInnerHTML={{
                    __html: t('nc_topup_suc_immi_title')
                      .replace('{{amount}}', displayPrice(5))
                      .replace('.', ',')
                  }}
                />
              </FeedbackScreen>
            </div>
          </div>
        </div>
      </MainLayout>
      <h3 className="pt-10">FeedbackScreen error</h3>
      <MainLayout showChildren>
        <div className="container-md container-page1">
          <div className="row pt-1">
            <div className="col-lg-4 col-md-8 col-sm-7 col-12 mx-auto px-6 px-sm-0 px-md-3">
              <FeedbackScreen
                type="error"
                heading={t('nc_topup_err_hdl')}
                buttonLabel={t('nc_topup_err_btn')}
                icon="errorcolor">
                <p className="pb-4" dangerouslySetInnerHTML={{ __html: t('nc_topup_err_txt') }} />
              </FeedbackScreen>
            </div>
          </div>
        </div>
      </MainLayout>
    </div>
  );
}

export default Test;
