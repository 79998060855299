/* eslint-disable prefer-const */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import {
  appTariffStatus,
  appTariffs,
  appUsageType,
  counterTDMArray,
  getTariffStatusKey
} from '@utils/globalConstant';
import { useCustomer } from '../Customer';
import { useOption, useSpeedOn, useTariff } from '../TariffOption';

export const DashBoardContext = createContext({});

export function DashBoardContextProvider({ children }) {
  const WELCOME_SCREEN_TIMEOUT = 5000;

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [tariffDetails, setTariffDetails] = useState({});
  const [activeProduct, setActiveProduct] = useState({
    tariff: {},
    status: { id: false },
    endDate: false
  });
  const [tariffStatus, setTariffStatus] = useState(false); // False | ACTIVE - Display Nothing | PAUSED | Change In progress
  const [isMultiData, setIsMultiData] = useState(false);
  const [dataUsage, setDataUsage] = useState([]);
  const [smsUsage, setSmsUsage] = useState([]);
  const [voiceUsage, setVoiceUsage] = useState([]);
  const [activeOptions, setActiveOptions] = useState([]);
  const [requestedOptions, setResponseData] = useState([]);
  const [isFlatTariff, setIsFlatTariff] = useState(false);
  const [counterTDMList, setCounterTDMList] = useState(counterTDMArray);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [open5GModal, setOpen5GModal] = useState(false);
  const [genericOptionCounterData, setGenericOptionCounterData] = useState([]);
  const [genericTariffCounterData, setGenericTariffCounterData] = useState([]);

  const [notShowTariffOption, setNotShowTariffOption] = useState(false);

  // Context
  const { t, staticContentData } = useStaticContent();
  const {
    isLoading: isCustomerLoading,
    customerProducts,
    customerData,
    customerBalance,
    customerUsage,
    personalData: { firstName = false, lastName = false },
    tariffChangeActive
  } = useCustomer();
  const { isLoading: isTariffLoading, activeTariff } = useTariff();
  const { isLoading: isOptionLoading, bookableOptions, bookedOptions } = useOption();
  const { isLoading: isSpeedOnLoading, passOffers } = useSpeedOn();

  // Validations

  // Functions
  // handle counter TDM Data
  const handleCounterTDMView = () => {
    let voiceView = [];
    let smsView = [];
    let dataView = [];
    let voiceBooked = [];
    let smsBooked = [];
    let dataBooked = [];
    // eslint-disable-next-line no-unused-vars
    let localCounterTDM = [...counterTDMArray];
    let finalLocalCounterTDM = [...counterTDMArray];
    let bookedCounterTDM = [];
    // console.log('localCounterTDM===========', localCounterTDM);
    if (bookedOptions.length > 0) {
      if (voiceUsage.length > 1) {
        voiceView = voiceUsage.filter((voiceUsageItem) =>
          bookedOptions.some(
            (bookedOptionsItem) => voiceUsageItem.optionId !== bookedOptionsItem.id
          )
        );
        voiceBooked = voiceUsage.filter((voiceUsageItem) =>
          bookedOptions.some(
            (bookedOptionsItem) => voiceUsageItem.optionId === bookedOptionsItem.id
          )
        );
      } else {
        voiceView = voiceUsage;
        voiceBooked = [];
      }

      if (smsUsage.length > 1) {
        smsView = smsUsage.filter((smsUsageItem) =>
          bookedOptions.some((bookedOptionsItem) => smsUsageItem.optionId !== bookedOptionsItem.id)
        );
        smsBooked = smsUsage.filter((smsUsageItem) =>
          bookedOptions.some((bookedOptionsItem) => smsUsageItem.optionId === bookedOptionsItem.id)
        );
      } else {
        smsView = smsUsage;
        smsBooked = [];
      }

      dataView = dataUsage.length > 0 ? [dataUsage[dataUsage.length - 1]] : [];
      // eslint-disable-next-line no-unused-expressions
      dataUsage.length > 0 ? dataUsage.pop() : [];
      dataBooked = dataUsage.length > 0 ? dataUsage : [];
    } else {
      voiceView = voiceUsage;
      smsView = smsUsage;
      // dataView = dataUsage;
      dataView = dataUsage.length > 0 ? [dataUsage[dataUsage.length - 1]] : [];
      // eslint-disable-next-line no-unused-expressions
      dataUsage.length > 0 ? dataUsage.pop() : [];
      dataBooked = dataUsage.length > 0 ? dataUsage : [];
    }

    // eslint-disable-next-line consistent-return, array-callback-return
    finalLocalCounterTDM = localCounterTDM.map((item) => {
      if (appUsageType.VOICE === item?.counterType) {
        return Object.assign(item, voiceView[0]);
        // eslint-disable-next-line no-else-return
      } else if (appUsageType.SMS === item?.counterType) {
        return Object.assign(item, smsView[0]);
      } else if (appUsageType.DATA === item?.counterType) {
        return Object.assign(item, dataView[0]);
      }
    });

    // removing the first object for simple view  voice and SMS
    if (voiceView.length > 0) {
      voiceView.shift();
    }
    if (smsView.length > 0) {
      smsView.shift();
    }

    // console.log('handleCounterTDMView----voiceView', voiceView)
    // console.log('handleCounterTDMView----smsView', smsView)
    // console.log('handleCounterTDMView----dataView', dataView)
    // console.log('handleCounterTDMView----voiceBooked', voiceBooked)
    // console.log('handleCounterTDMView----smsBooked', smsBooked)
    // console.log('handleCounterTDMView----dataBooked', dataBooked)
    bookedCounterTDM = [...voiceBooked, ...voiceView, ...smsBooked, ...smsView, ...dataBooked];
    // console.log('finalLocalCounterTDM================', finalLocalCounterTDM);
    // console.log('handleCounterTDMView----finalLocalCounterTDM', finalLocalCounterTDM)
    // console.log('handleCounterTDMView----bookedCounterTDM', bookedCounterTDM)
    // console.log('handleCounterTDMView----setCounterTDMList', [...bookedCounterTDM, ...finalLocalCounterTDM])
    setCounterTDMList([...bookedCounterTDM, ...finalLocalCounterTDM]);
  };

  // Hooks

  // Loading Hook
  useEffect(() => {
    setIsLoading(isCustomerLoading || isTariffLoading || isOptionLoading || isSpeedOnLoading);

    // Clean up
    return () => {
      setIsLoading(false);
    };
  }, [isCustomerLoading, isTariffLoading, isOptionLoading, isSpeedOnLoading]);

  // Set tariff details & status for dashboard
  useEffect(() => {
    // Find active tariff to view it on dashboard
    if (
      customerProducts &&
      customerProducts.length > 0 &&
      activeTariff &&
      activeTariff.length > 0
    ) {
      let findTariff = customerProducts.find(
        ({ status }) => status.id === appTariffStatus.ACTIVATION_PENDING
      );

      if (!findTariff) {
        findTariff = customerProducts.find(({ status }) => status.id === appTariffStatus.PAUSED);
      }

      if (!findTariff) {
        findTariff = customerProducts.find(({ status }) => status.id === appTariffStatus.ACTIVE);
      }

      if (!findTariff) {
        findTariff = customerProducts.find(({ status }) => status.id > -1);
      }

      if (findTariff?.tariff?.id && findTariff?.status) {
        const findCurrentTariff = activeTariff.find(
          ({ tariff }) => tariff.id === findTariff?.tariff?.id
        );
        // Find & Set Status Id
        const statusKey = getTariffStatusKey(findTariff?.status.id);

        setTariffStatus(statusKey);

        // Set Tariff for details
        setTariffDetails(findCurrentTariff?.tariff);
        setActiveProduct(findCurrentTariff);
      }
    }

    // Clean up
    return () => {
      setTariffStatus(false);
      setTariffDetails({});
    };
  }, [customerProducts, activeTariff]);

  // Set customer usage details
  useEffect(() => {
    if (customerUsage) {
      const {
        usage: { counters } = { usage: { counters: [] } },
        counters: { DATA, SMS, VOICE, isMultiData: multiData } = {}
      } = customerUsage;
      if (multiData) {
        // console.log('Multi Data is detected');
        setIsMultiData(true);
      }

      if (DATA) setDataUsage(DATA);
      if (SMS) setSmsUsage(SMS);
      if (VOICE) setVoiceUsage(VOICE);

      if (counters && counters.length > 0) {
        const counterData = [];
        const tariffData = [];
        counters.map((item) => {
          if (item.name === t('nc_dboard_tab_date_volume')) {
            tariffData.push(item);
          } else {
            const findDataCounter = counters.find(
              ({ passCode }) => passCode && passCode === item.passCode
            );
            if (findDataCounter) {
              counterData.push({ ...item, ...findDataCounter });
            } else {
              counterData.push(item);
            }
          }
          return item;
        });
        const sortedCounters = counterData.sort((a, b) => b.priority - a.priority);
        setGenericOptionCounterData(sortedCounters);
        setGenericTariffCounterData(tariffData);
      }
    }

    // Clean up
    return () => {
      setDataUsage({});
      setSmsUsage({});
      setVoiceUsage({});
      setGenericOptionCounterData([]);
      setGenericTariffCounterData([]);
    };
  }, [customerUsage]);

  useEffect(() => {
    const { msisdn = false, ...restCustomerData } = customerData;
    if (restCustomerData && msisdn && activeTariff) {
      const findTariff = activeTariff.find(({ status }) => status.id);
      if (findTariff) {
        // console.log('findTariff============', findTariff);
        const {
          tariff: { id } = { tariff: { id: false } } // , price, duration
        } = findTariff;

        if (
          // eslint-disable-next-line eqeqeq
          !(id == appTariffs.TALK || id == appTariffs.KOMBI_S || id == appTariffs.JAHRESTARIF_START)
        ) {
          // (price === 0 && duration === 0)
          setIsFlatTariff(true);
        } else {
          setIsFlatTariff(false);
        }
      }
    }
  }, [customerData, activeTariff]);

  useEffect(() => {
    if (firstName && lastName && !showWelcomeScreen) {
      setShowWelcomeScreen(true);
      setTimeout(() => {
        setShowWelcomeScreen(false);
      }, WELCOME_SCREEN_TIMEOUT);
    }
    return () => {
      setShowWelcomeScreen(false);
    };
  }, [firstName, lastName]);

  useEffect(() => {
    if (
      activeProduct?.tariff &&
      activeProduct?.tariff.id &&
      staticContentData &&
      staticContentData?.nr_5GTariffsIds
    ) {
      setOpen5GModal(!staticContentData?.nr_5GTariffsIds.includes(activeProduct?.tariff?.id));
    }

    if (
      activeProduct?.tariff &&
      activeProduct?.tariff.id &&
      staticContentData &&
      staticContentData?.nr_noDisplayInTariffCard
    ) {
      setNotShowTariffOption((staticContentData.nr_noDisplayInTariffCard || []).includes(activeProduct?.tariff?.id));
    }
  }, [staticContentData, activeProduct]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      tariffDetails,
      setTariffDetails,
      activeProduct,
      setActiveProduct,
      tariffStatus,
      setTariffStatus,
      counterTDMList,
      customerUsage,
      open5GModal,
      setOpen5GModal,
      genericOptionCounterData,
      setGenericOptionCounterData,
      genericTariffCounterData,
      setGenericTariffCounterData,
      tariffChangeActive,
      notShowTariffOption, 
      setNotShowTariffOption,

      customerData,
      customerBalance,
      showWelcomeScreen,
      setShowWelcomeScreen,

      isFlatTariff,

      isMultiData,
      dataUsage,
      setDataUsage,
      voiceUsage,
      setVoiceUsage,
      smsUsage,
      setSmsUsage,
      activeOptions,
      setActiveOptions,
      bookableOptions,
      bookedOptions,
      passOffers,
      handleCounterTDMView
    }),
    [
      // States
      isLoading,
      setIsLoading,
      tariffDetails,
      setTariffDetails,
      activeProduct,
      setActiveProduct,
      tariffStatus,
      setTariffStatus,
      customerUsage,
      open5GModal,
      setOpen5GModal,
      genericOptionCounterData,
      setGenericOptionCounterData,
      genericTariffCounterData,
      setGenericTariffCounterData,
      tariffChangeActive,
      notShowTariffOption, 
      setNotShowTariffOption,

      customerData,
      customerBalance,
      showWelcomeScreen,
      setShowWelcomeScreen,
      counterTDMList,
      isFlatTariff,

      isMultiData,
      dataUsage,
      setDataUsage,
      voiceUsage,
      setVoiceUsage,
      smsUsage,
      setSmsUsage,
      requestedOptions,
      setResponseData,
      activeOptions,
      setActiveOptions,
      bookableOptions,
      bookedOptions,
      passOffers,
      handleCounterTDMView
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <DashBoardContext.Provider value={contextPayload}>{children}</DashBoardContext.Provider>;
}
DashBoardContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useDashBoard = () => useContext(DashBoardContext);

export default DashBoardContext;
