import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation } from '@context/MobileOne';
import { PopupSupport } from '@core/PopupSupport';
import { appRoute } from '@utils/globalConstant';
import { NumberPortability } from './NumberPortability';
import { SIMVerification } from './SIMVerification';

export function ActivationStep1() {
  // States
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [portabilityPdf, setPortabilityPdf] = useState([]);

  // Contexts
  const { currentSubStep1, phoneNumberActivationForm } = useActivation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, staticContentData } = useStaticContent();

  // const { mediaPdfs } = useDocument();
  // const portabilityPdf = staticContentData?.media_pdf ? staticContentData?.media_pdf?.filter((element) => element.id === '338') : [];

  // Functions
  const onDownloadPdf = (downloadURL) => {
    window.open(downloadURL);
  };

  // Hooks
  useEffect(() => {
    if (
      !phoneNumberActivationForm.email &&
      (pathname !== appRoute.ACTIVATION_EMAIL_VERIFICATION ||
        pathname !== appRoute.ACTIVATION_LEGITIMATION_SUCCESS ||
        pathname !== appRoute.ACTIVATION_LEGITIMATION_FAILED)
    ) {
      navigate(appRoute.ACTIVATION_EMAIL_VERIFICATION);
    }
  }, []);

  useEffect(() => {
    if (staticContentData) {
      setPortabilityPdf(staticContentData?.media_pdf?.filter((element) => element.id === '338'));
    }
  }, [staticContentData])

  // if (isMNP) {
  //   return <NumberPortability />;
  // } else if (!phoneNumberActivationForm.msisdn) {
  //   return <SIMVerification />;
  // } else {
  //   return <SIMVerification />;
  // }

  return (
    <div className="container-md">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
          {currentSubStep1 === 1 ? (
            <NumberPortability setIsPopupOpen={setIsPopupOpen} />
          ) : (
            <SIMVerification />
          )}
        </div>
      </div>
      <PopupSupport
        isOpen={isPopupOpen}
        title={t('nc_reg_mnr_modal_hdl')}
        rightButtonLabel={t('nc_reg_mnr_modal_btn')}
        onClose={() => setIsPopupOpen(false)}
        onRightClick={() => {
          setIsPopupOpen(false);
          // onDownloadPdf(testPdf.url);
          onDownloadPdf(portabilityPdf[0].media_url);
        }}
        bodyText={t('nc_reg_mnr_modal_txt')}
      />
    </div>
  );
}

export default ActivationStep1;
