import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { useAlert } from '@context/Utils/Alert';
import { ButtonPrimary, FormDropdown, FormInput } from '@core/index';
import { useActivation } from '@context/index';
import { appButtonTypes } from '@utils/globalConstant';
import { FormDatePicker } from '@core/FormDatePicker';

export function ActivationStep4() {
  // Contexts
  const { t } = useStaticContent();
  const { setIsGenericError, setAlert } = useAlert();
  const {
    personalDataForm,
    // personalDataInitialValue,
    personalDataValidations,
    countries,
    isLoading,
    // areaCode,
    personalDataFormSubmit
  } = useActivation();

  useEffect(() => {
    setIsGenericError(false);
    setAlert({});
  }, []);

  return (
    <div className="container-md animate__animated animate__slideInRight">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
          <div>
            <h3 className="nc-doomsday-h3 mb-0" dangerouslySetInnerHTML={{ __html: t('nc_reg_step4_hdl') }} />
            <p
              className="nc-realheadpro-subhead pt-4 mt-4 mb-0"
              dangerouslySetInnerHTML={{ __html: t('nc_reg_step4_hdl2') }}
            />
            <div className="personal-data-form">
              <Formik
                initialValues={personalDataForm}
                enableReinitialize
                validationSchema={personalDataValidations}
                onSubmit={personalDataFormSubmit}>
                {({
                  values: {
                    firstName,
                    lastName,
                    birthDate,
                    street,
                    houseNumber,
                    zip,
                    city,
                    // alternateNumberPrefix,
                    // alternateNumber,
                    countryCode,
                    hotlinePassword
                  },
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  touched,
                  errors
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xl-6 col-xs-12 pt-4 pb-1">
                        <FormInput
                          type="text"
                          id="firstName"
                          name="firstName"
                          value={firstName}
                          placeholder={t('nc_reg_step4_input_firstname')}
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          valid={touched.firstName && !errors.firstName}
                          invalid={!!touched.firstName && !!errors.firstName}
                          invalidMessage={
                            touched.firstName && errors.firstName ? errors.firstName : null
                          }
                        />
                      </div>
                      <div className="col-xl-6 col-xs-12 pt-xl-4 pt-0 pb-1">
                        <FormInput
                          type="text"
                          id="lastName"
                          name="lastName"
                          value={lastName}
                          placeholder={t('nc_reg_step4_input_name')}
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          valid={touched.lastName && !errors.lastName}
                          invalid={!!touched.lastName && !!touched.lastName && !!errors.lastName}
                          invalidMessage={
                            touched.lastName && errors.lastName ? errors.lastName : null
                          }
                        />
                      </div>
                      <div className="col-xl-6 col-xs-12">
                        <FormDatePicker
                          // date={testDate}
                          // onDateChange={setTestDate}
                          // label="TITLE"
                          id="birthDate"
                          // startIcon="dateGreen"
                          placeholder={t('nc_reg_step4_input_birthdate')}
                          value={birthDate}
                          onChange={(date) => {
                            handleChange({
                              target: {
                                name: 'birthDate',
                                value: date
                              }
                            });
                            // setTestDate(date);
                          }}
                          onBlur={handleBlur}
                          // autoComplete="off"
                          valid={touched.birthDate && !errors.birthDate}
                          invalid={!!touched.birthDate && !!errors.birthDate}
                          invalidMessage={
                            touched.birthDate && errors.birthDate ? errors.birthDate : null
                          }
                        />
                        {/* <FormInput
                          type="date"
                          id="birthDate"
                          name="birthDate"
                          value={birthDate}
                          placeholder={t('nc_reg_step4_input_birthdate')}
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          valid={touched.birthDate && !errors.birthDate}
                          invalid={!!touched.birthDate && !!touched.birthDate && !!errors.birthDate}
                          invalidMessage={
                            touched.birthDate && errors.birthDate ? errors.birthDate : null
                          }
                          onFocus={"this.type='date'"}
                        /> */}
                      </div>
                    </div>
                    <div className="row">
                      <p
                        className="nc-realheadpro-subhead pt-8 mt-4 mb-0"
                        dangerouslySetInnerHTML={{ __html: t('nc_reg_step4_hdl3') }}
                      />
                      <div className="col-xl-6 col-xs-12 pt-4 pb-1">
                        <FormInput
                          type="text"
                          id="street"
                          name="street"
                          value={street}
                          placeholder={t('nc_reg_step4_input_street')}
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          valid={touched.street && !errors.street}
                          invalid={!!touched.street && !!errors.street}
                          invalidMessage={touched.street && errors.street ? errors.street : null}
                        />
                      </div>
                      <div className="col-xl-6 col-xs-12 pt-xl-4 pt-0 pb-1">
                        <FormInput
                          type="text"
                          id="houseNumber"
                          name="houseNumber"
                          value={houseNumber}
                          placeholder={t('nc_reg_step4_input_streetnr')}
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          valid={touched.houseNumber && !errors.houseNumber}
                          invalid={!!touched.houseNumber && !!errors.houseNumber}
                          invalidMessage={
                            touched.houseNumber && errors.houseNumber ? errors.houseNumber : null
                          }
                        />
                      </div>
                      <div className="col-xl-6 col-xs-12 pb-1">
                        <FormInput
                          type="text"
                          id="zip"
                          name="zip"
                          placeholder={t('nc_reg_step4_input_plz')}
                          autoComplete="off"
                          value={zip}
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          valid={touched.zip && !errors.zip}
                          invalid={!!touched.zip && !!errors.zip}
                          invalidMessage={touched.zip && errors.zip ? errors.zip : null}
                        />
                      </div>
                      <div className="col-xl-6 col-xs-12 pb-1">
                        <FormInput
                          type="text"
                          id="city"
                          name="city"
                          value={city}
                          placeholder={t('nc_reg_step4_input_city')}
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          valid={touched.city && !errors.city}
                          invalid={!!touched.city && !!errors.city}
                          invalidMessage={touched.city && errors.city ? errors.city : null}
                        />
                      </div>
                      <div className="col-xl-6 col-xs-12 pb-1">
                        <FormDropdown
                          id="countryCode"
                          name="countryCode"
                          value={countryCode}
                          placeholder={t('nc_reg_step4_dropdown_land')}
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={(countries || []).map((country) => ({
                            id: country.id,
                            label: country.value
                          }))}
                          valid={touched.countryCode && !errors.countryCode}
                          invalid={!!touched.countryCode && !!errors.countryCode}
                          invalidMessage={
                            touched.countryCode && errors.countryCode ? errors.countryCode : null
                          }
                        />
                      </div>
                    </div>

                    <div className="row">
                      <p
                        className="nc-realheadpro-subhead pt-8 mt-4 mb-0"
                        dangerouslySetInnerHTML={{ __html: t('nc_reg_step4_hdl4') }}
                      />
                      {/* <div className="col-xl-6 col-xs-12 pt-4 pb-1">
                        <FormDropdown
                          id="alternateNumberPrefix"
                          name="alternateNumberPrefix"
                          value={alternateNumberPrefix}
                          placeholder={`${t('nc_reg_step4_input_alt_number')} Prefix`}
                          autoComplete="off"
                          onChange={handleChange}
                          options={(areaCode || []).map((area) => ({
                            id: area.value,
                            label: area.value
                          }))}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          valid={touched.alternateNumberPrefix && !errors.alternateNumberPrefix}
                          invalid={
                            !!touched.alternateNumberPrefix && !!errors.alternateNumberPrefix
                          }
                          invalidMessage={
                            touched.alternateNumberPrefix && errors.alternateNumberPrefix
                              ? errors.alternateNumberPrefix
                              : null
                          }
                        />
                      </div>
                      <div className="col-xl-6 col-xs-12 pt-xl-4 pt-0 pb-1">
                        <FormInput
                          type="text"
                          id="alternateNumber"
                          name="alternateNumber"
                          value={alternateNumber}
                          placeholder={t('nc_reg_step4_input_alt_number')}
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          valid={touched.alternateNumber && !errors.alternateNumber}
                          invalid={!!touched.alternateNumber && !!errors.alternateNumber}
                          invalidMessage={
                            touched.alternateNumber && errors.alternateNumber
                              ? errors.alternateNumber
                              : null
                          }
                        />
                      </div> */}
                      <div className="col-xl-6 col-xs-12 pt-4 pb-1">
                        <FormInput
                          type="password"
                          id="hotlinePassword"
                          name="hotlinePassword"
                          value={hotlinePassword}
                          placeholder={t('nc_reg_step4_input_hotline')}
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          valid={touched.hotlinePassword && !errors.hotlinePassword}
                          invalid={!!touched.hotlinePassword && !!errors.hotlinePassword}
                          invalidMessage={
                            touched.hotlinePassword && errors.hotlinePassword
                              ? errors.hotlinePassword
                              : null
                          }
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 text-center pt-11">
                        <ButtonPrimary
                          isLoading={isLoading}
                          type="submit"
                          label={t('nc_reg_step2_btn')}
                          disabled={
                            !firstName ||
                            !lastName ||
                            (errors &&
                              Object.keys(errors || {}).filter((v) => errors && errors && errors[v])
                                .length > 0)
                          }
                          buttonType={appButtonTypes.PRIMARY.DEFAULT}
                          icon="arrowright"
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivationStep4;
