import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { useAlert } from '@context/Utils/Alert';
import { useActivation, useDocument } from '@context/MobileOne';
import { InfoBox } from '@core/InfoBox';
import { ButtonRadio } from '@core/ButtonRadio';
import { ButtonPrimary } from '@core/ButtonPrimary';
import { Badge, Icons } from '@core/Utils';
import { PopupSupport } from '@core/PopupSupport';
import { PdfScreen } from '@part/PdfScreen';
import { appButtonTypes, appInfoBoxType, getAppPdfIds } from '@utils/globalConstant';

export function ActivationStep5() {
  // States
  const [openModal, setOpenModal] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const [showPdf, setShowPdf] = useState(false);

  // Contexts
  const { t, staticContentData } = useStaticContent();
  const appPdfIds = getAppPdfIds();
  const { isGenericError, setIsGenericError, setAlert } = useAlert();
  const {
    overviewForm,
    loginPasswordForm,
    personalDataForm,
    tariffActivationForm,
    phoneNumberActivationForm,
    isLoading,
    overviewValidation,
    overviewFormSubmit,
    getVVIDocuments,
    vviDocuments,
    // bookableTariffs,
    selectedTariffId
    // setSelectedTariffId,
    // setVviDocuments
  } = useActivation();
  const { termsInfoPdfs, privacyInfoPdfs, agbPdfs } = useDocument();
  const filteredData = termsInfoPdfs
    ? termsInfoPdfs.filter((item) => item.id !== appPdfIds.AGB)
    : [];

  // Functions
  const onAgbClick = (event) => {
    // setShowPdf(true);
    if (event.target.parentElement.id === 'agb-link' || event.target.id === 'agb-link') {
      setShowPdf(true);
    }
  };

  const onInfoClick = () => {
    setOpenModal(true);
    setModalValue('info_modal');
  };

  const onModalClose = () => {
    setOpenModal(false);
    setModalValue('');
  };

  const onVVIClick = () => {
    setOpenModal(true);
    setModalValue('vvi_modal');
  };

  const getModalTitle = () => {
    switch (modalValue) {
      case 'info_modal':
        return '';
      case 'vvi_modal':
        return t('nc_modal_vvi_hdl');
      case 'contract_modal':
        // return t('Dokumente');
        return t('nc_reg_step5_modal_doc_hdl');
      case 'data_protection':
        // return t('Datenschutz');
        return t('nc_reg_step5_modal_data_hdl');
      default:
        return 'Title';
    }
  };

  // Hooks
  useEffect(() => {
    if (staticContentData && !showPdf) {
      const terminalDataModal = document.querySelector('#data-protection');
      if (terminalDataModal) {
        terminalDataModal.setAttribute('role', 'button');
        terminalDataModal.addEventListener('click', () => {
          setOpenModal(true);
          setModalValue('data_protection');
        });
      }
      const withdrawalModal = document.querySelector('#withdrawal');
      if (withdrawalModal) {
        withdrawalModal.setAttribute('role', 'button');
        withdrawalModal.addEventListener('click', () => {
          setOpenModal(true);
          setModalValue('contract_modal');
        });
      }
    }
  }, [staticContentData, showPdf]);

  // VVI documents
  useEffect(() => {
    if (selectedTariffId) {
      getVVIDocuments(selectedTariffId);
    }
  }, [selectedTariffId]);

  useEffect(() => {
    if (vviDocuments) {
      if (openModal && modalValue === 'vvi_modal') {
        document.querySelector('#docA').addEventListener('click', () => {
          const linkSource = `data:application/pdf;base64,${vviDocuments.vzf}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = 'vzf.pdf';
          downloadLink.click();
          downloadLink.remove();
        });
        document.querySelector('#docB').addEventListener('click', () => {
          const linkSource = `data:application/pdf;base64,${vviDocuments.agb}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = 'agb.pdf';
          downloadLink.click();
          downloadLink.remove();
        });
        document.querySelector('#docC').addEventListener('click', () => {
          const linkSource = `data:application/pdf;base64,${vviDocuments.preisliste}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = 'preisliste.pdf';
          downloadLink.click();
          downloadLink.remove();
        });
        document.querySelector('#docD').addEventListener('click', () => {
          const linkSource = `data:application/pdf;base64,${vviDocuments.leistungsbeschreibung}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = 'leistungsbeschreibung.pdf';
          downloadLink.click();
          downloadLink.remove();
        });
      }
      // eslint-disable-next-line no-unused-expressions
      () => {
        document.querySelector('#docA').removeEventListener('click');
        document.querySelector('#docB').removeEventListener('click');
        document.querySelector('#docC').removeEventListener('click');
        document.querySelector('#docD').removeEventListener('click');
      };
    }
  }, [openModal, vviDocuments]);

  useEffect(() => {
    setIsGenericError(false);
    setAlert({});
  }, []);

  return (
    <>
      {!isGenericError && (
        <div className="container-md">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
              <div>
                {showPdf ? (
                  <PdfScreen pdf={agbPdfs} onBackClick={() => setShowPdf(false)} />
                ) : (
                  <div className="animate__animated animate__slideInRight">
                    <h3
                      className="nc-doomsday-h3 pb-8 mb-0"
                      dangerouslySetInnerHTML={{ __html: t('nc_reg_step5_hdl') }}
                    />
                    <div className="row">
                      <div className="col-lg-6 col-md-8 px-3">
                        <InfoBox
                          type={appInfoBoxType.DARK}
                          leftIcon="infogray100"
                          label={t('nc_reg_step5_infobox')}
                          rightIcon="arrowrightcolor"
                          onInfoClick={onVVIClick}
                        />
                      </div>
                    </div>
                    <div className="terms-form">
                      <Formik
                        initialValues={{
                          ...overviewForm,
                          ...loginPasswordForm,
                          ...personalDataForm,
                          ...tariffActivationForm,
                          ...phoneNumberActivationForm
                        }}
                        validationSchema={overviewValidation}
                        onSubmit={overviewFormSubmit}>
                        {({
                          values: { termsAndConditions, thirdParty },
                          handleChange,
                          handleSubmit
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex pt-12 pb-9">
                                  <ButtonRadio
                                    value={termsAndConditions}
                                    isDefaultChecked={termsAndConditions}
                                    onChange={(e) => {
                                      handleChange({
                                        target: {
                                          id: 'termsAndConditions',
                                          name: 'termsAndConditions',
                                          value: e
                                        }
                                      });
                                    }}
                                  />
                                  <div
                                    // role="button"
                                    // aria-hidden
                                    onClick={onAgbClick}
                                    className="ps-3"
                                    dangerouslySetInnerHTML={{ __html: t('nc_reg_step5_agree1') }}
                                  />
                                </div>
                                <div className="pb-4">
                                  <Badge title={t('nc_reg_step5_badge_recommendation')} />
                                </div>
                                <div className="d-flex">
                                  <ButtonRadio
                                    value={thirdParty}
                                    isDefaultChecked={thirdParty}
                                    onChange={(e) => {
                                      handleChange({
                                        target: {
                                          id: 'thirdParty',
                                          name: 'thirdParty',
                                          value: e
                                        }
                                      });
                                    }}
                                  />
                                  <div className="ps-3">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: t('nc_reg_step5_agree2_text1')
                                      }}
                                    />
                                    <div
                                      role="button"
                                      aria-hidden
                                      onClick={onInfoClick}
                                      dangerouslySetInnerHTML={{
                                        __html: t('nc_reg_step5_agree2_text2')
                                      }}
                                    />
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: t('nc_reg_step5_agree2_text3')
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 text-center pt-8">
                                <ButtonPrimary
                                  isLoading={isLoading}
                                  type="submit"
                                  label={t('nc_reg_step5_btn')}
                                  disabled={!termsAndConditions || isLoading}
                                  buttonType={appButtonTypes.PRIMARY.DEFAULT}
                                  icon="arrowright"
                                />
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <PopupSupport isOpen={openModal} title={getModalTitle(modalValue)} onClose={onModalClose}>
        {modalValue === 'vvi_modal' && (
          <div>
            <ul className="list-unstyled mb-0">
              <li id="docA" className="pb-4" type="button">
                <div className="d-flex">
                  <Icons name="pdfcolor" />
                  <span className="nc-realtextpro-copy-bold-link ps-4">
                    {t('nc_tarif_modal_doc_txt1')}
                  </span>
                </div>
              </li>
              <li id="docB" className="pb-4" type="button">
                <div className="d-flex">
                  <Icons name="pdfcolor" />
                  <span className="nc-realtextpro-copy-bold-link ps-4">
                    {t('nc_tarif_modal_doc_txt2')}
                  </span>
                </div>
              </li>
              <li id="docC" className="pb-4" type="button">
                <div className="d-flex">
                  <Icons name="pdfcolor" />
                  <span className="nc-realtextpro-copy-bold-link ps-4">
                    {t('nc_tarif_modal_doc_txt3')}
                  </span>
                </div>
              </li>
              <li id="docD" className="" type="button">
                <div className="d-flex">
                  <Icons name="pdfcolor" />
                  <span className="nc-realtextpro-copy-bold-link nav-link ps-4">
                    {t('nc_tarif_modal_doc_txt4')}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        )}

        {modalValue && modalValue === 'info_modal' && (
          <div>
            <h3
              className="m-0 pb-4"
              dangerouslySetInnerHTML={{ __html: t('nc_reg_step5_modal_cotract_detal_hdl1') }}
            />
            <div
              dangerouslySetInnerHTML={{ __html: t('nc_reg_step5_modal_contract_detail_txt1') }}
            />
            <h3
              className="m-0 pt-8 pb-4"
              dangerouslySetInnerHTML={{ __html: t('nc_reg_step5_modal_cotract_detal_hdl2') }}
            />
            <div
              dangerouslySetInnerHTML={{ __html: t('nc_reg_step5_modal_contract_detail_txt2') }}
            />
            <h3
              className="m-0 pt-8 pb-4"
              dangerouslySetInnerHTML={{ __html: t('nc_reg_step5_modal_cotract_detal_hdl3') }}
            />
            <div
              className="nc-realtextpro-copy"
              dangerouslySetInnerHTML={{ __html: t('nc_reg_step5_modal_contract_detail_txt3') }}
            />
          </div>
        )}
        {modalValue &&
          modalValue === 'contract_modal' &&
          filteredData.map((item, index) => (
            <div className="d-flex pb-4" key={`contract-pdf-${index}`}>
              <Icons name="pdfcolor" />
              <a
                className="nc-realtextpro-copy-bold ps-4"
                href={item?.redirectionURL}
                target="_blank"
                rel="noreferrer">
                {item?.name}
              </a>
            </div>
          ))}
        {modalValue &&
          modalValue === 'data_protection' &&
          privacyInfoPdfs.map((item, index) => (
            <div className="d-flex pb-4" key={`contract-pdf-${index}`}>
              <Icons name="pdfcolor" />
              <a
                className="nc-realtextpro-copy-bold ps-4"
                href={item?.redirectionURL}
                target="_blank"
                rel="noreferrer">
                {item?.name}
              </a>
            </div>
          ))}
      </PopupSupport>
    </>
  );
}

export default ActivationStep5;
