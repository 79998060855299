import React from 'react';
import { Form, Formik } from 'formik';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { PaymentFields } from '@core/PaymentFields';
import { ButtonPrimary } from '@core/ButtonPrimary';
import { appButtonTypes, appPaymentMethods, getPlanIconName } from '@utils/globalConstant';
import { LoadingScreen } from '@core/LoadingScreen';

export function Payment({
  formInitValues,
  formValidations,
  formSubmit,
  paymentMethods,
  isLoading,
  isActivation
}) {
  // Contexts
  const { t } = useStaticContent();

  // Functions
  const getLabel = (name) => {
    switch (name) {
      case appPaymentMethods.PAYPAL:
        return t('nc_reg_step6_topuppay_txt1');
      case appPaymentMethods.SOFORT:
        return t('nc_reg_step6_topuppay_txt2');
      case appPaymentMethods.CREDIT_CARD:
        return t('nc_reg_step6_topuppay_txt3');
      case appPaymentMethods.PAY_DIRECT:
        return t('nc_topup_pay_txt6');
      case appPaymentMethods.SEPA_DIRECT_DEBIT:
        return t('nc_reg_step6_topuppay_txt6');
      case appPaymentMethods.AMERICAN_EXPRESS:
        return t('nc_reg_step6_topuppay_txt4');
      default:
        return name;
    }
  };
  
  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="row">
      {isActivation ?
        <h3
          className="nc-doomsday-h3 pb-8 mb-0"
          dangerouslySetInnerHTML={{ __html: t('nc_reg_step6_topuppay_hdl') }}
        />
        :
        <h4
          className="nc-doomsday-h4 pb-8 mb-0"
          dangerouslySetInnerHTML={{ __html: t('nc_reg_step6_topuppay_hdl') }}
        />
      }
      <Formik
        initialValues={formInitValues}
        validationSchema={formValidations}
        onSubmit={formSubmit}>
        {({ values: { paymentMethodName }, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row">
              {paymentMethods.map((item, index) => (
                <div className="col-xl-6 col-lg-12 pb-3" key={`payment-methods-${index}`}>
                  <PaymentFields
                    icon={getPlanIconName(item.name)}
                    value={paymentMethodName === item.name}
                    label={getLabel(item.name)}
                    isDefaultChecked={paymentMethodName === item.name}
                    onChange={(isTrue) => {
                      if (isTrue) {
                        handleChange({
                          target: {
                            name: 'paymentMethodName',
                            value: item.name
                          }
                        });
                      }
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-12 text-center pt-9">
                <ButtonPrimary
                  isLoading={isLoading}
                  type="submit"
                  label={t('nc_reg_step6_topuppay_btn')}
                  disabled={!paymentMethodName || isLoading}
                  buttonType={appButtonTypes.PRIMARY.DEFAULT}
                  icon="arrowright"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Payment;
