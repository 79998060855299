import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { appPaymentProductType } from '@utils/globalConstant';

export function ActiveAutoTopUpList({ item, currentTopupType }) {
  // Constants
  const momentObj = moment(item?.created).format('DD.MM.YYYY');

  // States
  const [recurringDay, setRecurringDay] = useState('');
  const [recurringLimit, setRecurringLimit] = useState('');
  const [recurringAmount, setRecurringAmount] = useState('');

  // Contexts
  const { t } = useStaticContent();

  // Functions
  const currentType = () => {
    switch (currentTopupType) {
      case appPaymentProductType.LOW_BALANCE:
        return t('nc_tariff-details_text1').replace('{{amount}}', `${recurringLimit}`);
      case appPaymentProductType.RECURRING:
        return t('nc_tariff-details_text2')
          .replace('{{day}}', `${recurringDay}`)
          .replace('{{amount}}', `${recurringAmount}`);
      case null:
        return t('nc_tarif-details_no-topup');
      default:
        return t('nc_tariff-details_text3');
    }
  };

  //  Hooks
  useEffect(() => {
    if (Object.keys(item).length > 0) {
      setRecurringDay(item?.recurringData?.day);
      setRecurringLimit(item?.lowBalanceLimit);
      setRecurringAmount(item?.amount);
    }
  }, [item]);

  return (
    <>
      <div className="d-flex flex-column pt-4">
        <h4
          className="nc-doomsday-h4 pt-0"
          dangerouslySetInnerHTML={{ __html: t('nc_acc_topup_qur_hdl1') }}
        />
        <ul className="mb-n1">
          <li
            className="nc-realtextpro-copy"
            dangerouslySetInnerHTML={{
              __html: `${item?.msisdn ? item?.msisdn : t('nc_acc_no_data')}`.replace('+49', '0')
            }}
          />
          <li
            className="nc-realtextpro-copy"
            dangerouslySetInnerHTML={{
              __html: momentObj
            }}
          />
          {
            Object.keys(item).length > 0 && (
              <li
                className="nc-realtextpro-copy"
                dangerouslySetInnerHTML={{
                  __html: currentType()
                }}
              />
            )
          }
          {item?.tariffEnabled && (
            <li
              className="nc-realtextpro-copy"
              dangerouslySetInnerHTML={{
                __html: t('nc_topup_display_tariff')
              }}
            />
          )
          }
          {/* {item?.autoTopupType ===
                            appAutoTopUpPeriodType.ONCE_PER_MONTH && (
                            <li
                              className="nc-realtextpro-copy"
                              dangerouslySetInnerHTML={{
                                __html: `${t('nc-acc-topup-once-per-month')}`.replace(
                                  '{{day}}',
                                  item?.recurringData?.day
                                )
                              }}
                            />
                          )} */}
        </ul>
        {/* {item?.paymentMethod && (
                        <div className="d-flex flex-column pt-4">
                          <h4
                            className="nc-doomsday-h4 pt-0"
                            dangerouslySetInnerHTML={{ __html: t('nc_acc_topup_qur_hdl2') }}
                          />
                          <ul className="mb-n1 pb-1">
                            <li
                              className="nc-realtextpro-copy"
                              dangerouslySetInnerHTML={{
                                __html: `${t(`nc_acc_pay_${item?.paymentMethod}_mth`)}`.replace('+49', '0')
                              }}
                            />
                          </ul>
                        </div>
                      )} */}
      </div>
    </>
  );
}

export default ActiveAutoTopUpList;
