import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Form, Formik } from 'formik';
import { useActivation } from '@context/MobileOne';

import { FormDropdown, FormInput, ButtonPrimary, InfoBox } from '@core/index';

import { appButtonTypes, appInfoBoxType } from '@utils/globalConstant';

export function NumberPortability({ setIsPopupOpen }) {
  const { t } = useStaticContent();
  const {
    phoneNumberActivationForm,
    phoneNumberValidationsWithMNP,
    phoneNumberActivationFormSubmit,

    areaCode,
    simProvider
  } = useActivation();

  return (
    <>
      <div className="animate__animated animate__slideInRight">
        <p className="nc-doomsday-h3 mb-11">{t('nc_reg_step1_mnr_hdl')}</p>
        <Formik
          initialValues={phoneNumberActivationForm}
          validationSchema={phoneNumberValidationsWithMNP}
          onSubmit={(data) => {
            phoneNumberActivationFormSubmit({ ...data, mnp: true });
          }}>
          {({
            values: { oldNumber, oldNumberPrefix, currentProvider },
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit
          }) => (
            <div className="mnp-form">
              <Form onSubmit={handleSubmit}>
                <div className="row gx-6 gy-3">
                  <div className="col-xl-6">
                    <FormDropdown
                      placeholder={t('nc_reg_step1_mnr_dropdown_prefix')}
                      id="oldNumberPrefix"
                      name="oldNumberPrefix"
                      value={oldNumberPrefix}
                      options={(areaCode || []).map((area) => ({
                        id: area.value,
                        label: area.value
                      }))}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      valid={touched.oldNumberPrefix && !errors.oldNumberPrefix}
                      invalid={!!touched.oldNumberPrefix && !!errors.oldNumberPrefix}
                      invalidMessage={
                        touched.oldNumberPrefix && errors.oldNumberPrefix
                          ? errors.oldNumberPrefix
                          : null
                      }
                    />
                  </div>
                  <div className="col-xl-6">
                    <FormInput
                      type="text"
                      startIcon="phonecolor"
                      id="oldNumber"
                      name="oldNumber"
                      autoComplete="off"
                      onChange={(e) => {
                        handleChange({
                          target: {
                            id: 'oldNumber',
                            name: 'oldNumber',
                            value: e.target.value
                          }
                        });
                      }}
                      value={oldNumber}
                      placeholder={t('nc_reg_step1_mnr_input_phonenumber')}
                      valid={touched.oldNumber && !errors.oldNumber}
                      invalid={!!touched.oldNumber && !!errors.oldNumber}
                      onBlur={(e) => {
                        // console.log('blur', e);
                        handleBlur(e);
                      }}
                      invalidMessage={
                        touched.oldNumber && errors.oldNumber ? errors.oldNumber : null
                      }
                    />
                  </div>
                  <div className="col-xl-6">
                    <FormDropdown
                      id="currentProvider"
                      name="currentProvider"
                      value={currentProvider}
                      placeholder={t('nc_reg_step1_mnr_dropdown_provider')}
                      options={(simProvider || []).map((provider) => ({
                        id: provider.id,
                        label: provider.value
                      }))}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      valid={touched.currentProvider && !errors.currentProvider}
                      invalid={!!touched.currentProvider && !!errors.currentProvider}
                      invalidMessage={
                        touched.currentProvider && errors.currentProvider
                          ? errors.currentProvider
                          : null
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 py-12">
                    <InfoBox
                      label={t('nc_reg_step1_mnr_infobox')}
                      rightIcon="arrowrightcolor"
                      leftIcon="infogray100"
                      type={appInfoBoxType.DARK}
                      onInfoClick={() => setIsPopupOpen(true)}
                    />
                  </div>
                  <div className="col-12 text-center">
                    <ButtonPrimary
                      type="submit"
                      label={t('nc_reg_step1_mnr_btn')}
                      disabled={
                        errors.oldNumber || !oldNumber || !oldNumberPrefix || !currentProvider
                      }
                      buttonType={appButtonTypes.PRIMARY.DEFAULT}
                      icon="arrowright"
                    />
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
}
export default NumberPortability;
