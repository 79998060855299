import React, { useEffect, useState } from 'react';
import { ButtonSecondary } from '@core/ButtonSecondary';
import { appButtonTypes, appGenericTeaserType, appGrenericTeaserBg } from '@utils/globalConstant';

import './GenericTeaser.scss';
// import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import Image from '@core/Utils/Image';

export function GenericTeaser({ heading, subHeading, buttonText, cornerImage, type, onClick, color1, color2, imgRef, resImgRefs, imgAlt }) {
  // Context
  // const { mediaImage } = useStaticContent();

  // States
  const [bgColor, setBgColor] = useState('');
  // const [imgSrc, setImgSrc] = useState(null);
  // const [imgWidth, setImgWidth] = useState('');
  // const [imgHeight, setImgHeight] = useState('');

  // Hooks
  useEffect(() => {
    if (type === appGenericTeaserType.ORANGE) {
      setBgColor(appGrenericTeaserBg.ORANGE);
    } else if (type === appGenericTeaserType.RED) {
      setBgColor(appGrenericTeaserBg.RED);
    } else if (type === appGenericTeaserType.BLUE) {
      setBgColor(appGrenericTeaserBg.BLUE);
    } else if (type === appGenericTeaserType.GREEN) {
      setBgColor(appGrenericTeaserBg.GREEN);
    }
  }, [type]);

  useEffect(() => {
    if (color1 && color2) setBgColor(`linear-gradient(90deg, ${color1} -0.15%, ${color2} 100.91%)`);
  }, [color1, color2]);

  // useEffect(() => {
  //   if (imageRef && mediaImage) {
  //     const getImage = mediaImage.find((item) => item.image_ref === imageRef);
  //     setImgSrc(getImage.media_url_display);
  //     setImgWidth(`${getImage.responsive_image.basic_width}px`);
  //     setImgHeight(`${getImage.responsive_image.basic_height}px`);
  //   }

  //   return () => {
  //     setImgSrc('');
  //     setImgWidth('');
  //     setImgHeight('');
  //   };
  // }, [mediaImage, imageRef]);

  return (
    <div className="generic-teaser d-flex rounded-4 animate__animated animate__fadeInUp" style={{ background: bgColor }}>
      <div className="position-relative w-100">
        <div className="generic-teaser-content h-100 d-flex flex-column justify-content-between align-items-start p-5">
          <div className="w-100 z-1">
            {heading && <h3 className="nc-doomsday-h3 ms-0 ps-0 m-0" dangerouslySetInnerHTML={{__html: heading}}/>}
            {subHeading && <h4 className="nc-doomsday-h4 w-80 text-white ms-0 ps-0 m-0" dangerouslySetInnerHTML={{__html: subHeading}}/>}
          </div>
          <div className="mb-n2 z-1">
            <ButtonSecondary
              label={buttonText}
              type={appButtonTypes.SECONDARY.DEFAULT}
              icon="forwardprimary"
              onClick={onClick}
            />
          </div>
        </div>
        {/* <img
          className="generic-teaser-img rounded-4 position-absolute bottom-0 end-0"
          src={imgSrc || cornerImage}
          alt=""
          style={{ height: imgHeight, width: imgWidth }}
        /> */}
        <Image
          className="generic-teaser-img rounded-4 position-absolute bottom-0 end-0"
          refs={imgRef}
          resRefs={resImgRefs}
          alt={imgAlt}
        />
      </div>
    </div>
  );
};

export default GenericTeaser;
