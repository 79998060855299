/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Text } from '@core/Inputs/Text';
import { Radio } from '@core/Inputs';
import './style.scss';
import { appTextFieldLabelClass } from '@utils/globalConstant';

export function DirectPaymentProduct({
  id,
  // productType,
  // minAmount,
  // maxAmount,

  // currency,
  // priceRange,

  // channel,
  // optionType,
  error,
  isSelected,
  onSelect,
  otherAmount,
  onAmountChange,
  onBlur
}) {
  const { t } = useStaticContent();
  return (
    <div className="row auto-top-amount" onClick={() => onSelect(id)}>
      <div className="col-1 mt-3 pt-3">
        <Radio
          // label={t('ek_autotop-up_setup_amount4')}
          type="radio"
          id={`payment-other-product-${id}`}
          isDefaultChecked={isSelected}
          name="productId"
          // onBlur={onBlur}
          onChange={() => onSelect(id)}
        />
      </div>
      <div className="col-11 mt-1">
        <Text
          label={t('ek_autotop-up_setup_amount4')}
          labelCustomClass={appTextFieldLabelClass.V1}
          type="text"
          id="otherAmount"
          inputCustomClass="direct-input"
          placeholder={t('ek_web_common_amount')}
          name="otherAmount"
          value={otherAmount}
          onBlur={onBlur}
          onChange={(e) => {
            onAmountChange(e.target.value);
            // onSelect(id);
          }}
          isInvalid={!!error}
          error={error}
          isEuroIcon
        />
      </div>
    </div>
  );
}

DirectPaymentProduct.propTypes = {
  id: PropTypes.number,
  // productType: PropTypes.string,
  // minAmount: PropTypes.number,
  // maxAmount: PropTypes.number,
  // currency: PropTypes.string,
  // priceRange: PropTypes.string,
  // channel: PropTypes.string,
  error: PropTypes.string,
  // optionType: PropTypes.string,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,

  otherAmount: PropTypes.string,
  onAmountChange: PropTypes.func,
  onBlur: PropTypes.func
};

DirectPaymentProduct.defaultProps = {
  id: 105,
  // productType: '',
  // minAmount: 5,
  // maxAmount: 50,
  // currency: '',
  // priceRange: '',
  // channel: '',
  error: '',
  // optionType: '',
  isSelected: false,
  onSelect: () => {},
  otherAmount: '',
  onAmountChange: () => {},
  onBlur: () => {}
};

export default DirectPaymentProduct;
