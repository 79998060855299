import React, { useEffect, useState } from 'react';
import { ButtonSecondary } from '@core/ButtonSecondary';
import { appButtonTypes, appDashboardTeaserTypes, appImages } from '@utils/globalConstant';

import "./DashboardTeaser.scss";

export function DashboardTeaser({ type = appDashboardTeaserTypes.SPEED_ONS, buttonText, text, onClick }) {
  const [bgColor, setBgColor] = useState('');
  const [cornerImage, setCornerImage] = useState('');

  useEffect(() => {
    if (type === appDashboardTeaserTypes.SPEED_ONS) {
      setBgColor('bg-orange');
      setCornerImage(appImages.dataPasses);
    } else if (type === appDashboardTeaserTypes.DATAPASSES) {
      setBgColor('bg-mint-100');
      setCornerImage(appImages.dataPasses);
    } else if (type === appDashboardTeaserTypes.OTHER) {
      setBgColor('bg-yellow-100');
      setCornerImage(appImages.dataPasses2);
    }
  }, [type]);

  return (
    <div className={`dashboard-teaser d-flex rounded-4 shadow-xs animate__animated animate__fadeInUp ${bgColor}`}>
      <div className="position-relative w-100">
        <div className="dashboard-teaser-content h-100 d-flex flex-column justify-content-between align-items-start p-5">
          <div className="w-80">
            <h3 className="nc-doomsday-h3 text-white ms-0 ps-0">{text}</h3>
          </div>
          <div className="mb-n2">
            <ButtonSecondary
              label={buttonText}
              type={appButtonTypes.SECONDARY.DEFAULT}
              icon="forwardprimary"
              onClick={onClick}
            />
          </div>
        </div>
        <img
          className="dashboard-teaser-img rounded-4 position-absolute bottom-0 end-0"
          src={cornerImage}
          alt="dashboard teaser image"
        />
      </div>
    </div>
  );
}

export default DashboardTeaser;
