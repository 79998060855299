import React from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText
  // CardTitle, CardImg,
} from '@core/Layout/Card';
import PropTypes from 'prop-types';
import { appRoute, appUsageMeasurementsUnits } from '@utils/globalConstant';
import { useNavigate } from 'react-router-dom';
import { Button } from '@core/Inputs';
import { useCustomer, useOption } from '@context/MobileOne';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

/**
 * Inidividual Option Product
 * TODO: Extend it with different type ACTIVE, INPROGRESS & USAGE etc
 * @param id
 * @param name
 * @param header
 * @param description
 * @param price
 * @param duration
 * @param durationUnit
 * @param bookable
 * @param booked
 * @param cancellable
 * @param endDate
 * @param endTime
 * @returns
 */
export function OptionProduct({
  id,
  name,
  description,
  price,

  duration,
  durationUnit,

  bookable,
  booked,
  cancellable,

  // endDate,
  // endTime,

  isBookableAllowed
}) {
  // Context
  const { bookableOptions } = useOption();
  const {
    customerBalance: { totalBalance = 0 }
  } = useCustomer();
  const navigate = useNavigate();

  const isBookingAllowed =
    totalBalance >= price &&
    bookableOptions.findIndex(({ id: optionId }) => parseInt(id, 10) === parseInt(optionId, 10)) >
      -1;
  const { t } = useStaticContent();
  return (
    <Card>
      {/* <CardImg variant="top" src="https://source.unsplash.com/random/1000x400?cartoon" /> */}
      <CardHeader>
        <strong>
          {name} - {id}
        </strong>
      </CardHeader>
      <CardBody>
        {/* <CardTitle>Card name</CardTitle> */}
        <CardText>{description}</CardText>
        <div className="row justify-content-md-center p-0">
          <div className="col-md-6">
            <ul className="list-group">
              <li className="list-group-item p-2">
                {t('activation-price')} - {price}
              </li>
              <li className="list-group-item p-2">
                {t('activation-duration')} - {duration}&nbsp;{durationUnit}
              </li>
            </ul>
          </div>

          <div className="col-md-6">
            <ul className="list-group">
              <li className="list-group-item p-2">
                {t('dashboard-bookable')} - {JSON.stringify(bookable)}
              </li>
              <li className="list-group-item p-2">
                {t('dashboard-booked')} - {JSON.stringify(booked)}
              </li>
              <li className="list-group-item p-2">
                {t('dashboard-cancellable')} - {JSON.stringify(cancellable)}
              </li>
              {/* <li className="list-group-item p-2">
                EndDate     - {JSON.stringify(endDate)}
              </li>
              <li className="list-group-item p-2">
                EndTime     - {JSON.stringify(endTime)}
              </li> */}
            </ul>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        {isBookableAllowed && (
          <Button
            variant="primary"
            type="button"
            disabled={!isBookingAllowed}
            onClick={() => navigate(appRoute.OPTION_BOOK(id))}
          >
            {t('dashboard-book-now')}
          </Button>
        )}
        {!isBookableAllowed && <small className="text-muted">You are not served!</small>}
      </CardFooter>
    </Card>
  );
}

OptionProduct.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  durationUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bookable: PropTypes.bool,
  booked: PropTypes.bool,
  cancellable: PropTypes.bool,
  // endDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // endTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  isBookableAllowed: PropTypes.bool
};

OptionProduct.defaultProps = {
  id: 0,
  name: '',
  description: '',
  price: 0,
  duration: 0,
  durationUnit: appUsageMeasurementsUnits.GB,
  bookable: false,
  booked: false,
  cancellable: false,
  // endDate: '',
  // endTime: '',

  isBookableAllowed: false
};

export default OptionProduct;
