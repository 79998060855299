import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation } from '@context/MobileOne';
import { Button, DropDown, Radio } from '@core/Inputs';

import EmpfehLung from '@theme/assets/img/empfehlung.svg';
import {
  appAutoTopUpPeriodType,
  appButtonType,
  appPaymentCertainDay,
  appPaymentProductType
} from '@utils/globalConstant';
import { DirectPaymentProduct, PaymentProduct } from '@part/Payment';
import errorIcon from '@theme/assets/img/error.svg';
import { PaymentMethods } from '../PaymentMethods';
import './style.scss';
// import { AutoTopUpOptions } from '../AutoTopUpOptions';

export function OptionsAndProducts({
  isLoading,
  processToPaymentForm,
  // processToPaymentValidations,
  processToPayment,
  paymentProducts,
  allPaymentProducts,
  setPaymentProducts,
  // otherAmount
  setOtherAmount,
  paymentMethod,
  chargingForm,
  chargingValidations,
  chargingFormSubmit
}) {
  // States
  const [selectPeriodDay, setSelectPeriodDay] = useState({});

  // Contexts
  const { t } = useStaticContent();
  const {
    processToProductValidations,
    setProcessToPaymentForm,
    productValidation,
    setIsAutoLowEnabled,
    isAutoLowEnabled
  } = useActivation();

  // Functions

  const onChangeProductType = (value) => {
    if (
      allPaymentProducts.length > 0 &&
      (value === appAutoTopUpPeriodType.LOW_BALANCE || value === appAutoTopUpPeriodType.RATE)
    ) {
      let lowBalProducts = [];
      const fixedProducts = allPaymentProducts.filter(
        ({ productType, maxAmount, minAmount }) =>
          productType === appPaymentProductType.LOW_BALANCE && maxAmount === minAmount
      );

      const directProduct = allPaymentProducts.filter(
        ({ productType, maxAmount, minAmount }) =>
          productType === appPaymentProductType.LOW_BALANCE && maxAmount !== minAmount
      );

      lowBalProducts = fixedProducts;
      lowBalProducts.sort((x, y) => x.amount - y.amount);
      lowBalProducts.push(directProduct[0]);

      setPaymentProducts(lowBalProducts);
    }

    if (allPaymentProducts.length > 0 && value === appAutoTopUpPeriodType.ONCE_PER_MONTH) {
      let reccuringProducts = [];
      const fixedProducts = allPaymentProducts.filter(
        ({ productType, maxAmount, minAmount }) =>
          productType === appPaymentProductType.RECURRING && maxAmount === minAmount
      );

      const directProduct = allPaymentProducts.filter(
        ({ productType, maxAmount, minAmount }) =>
          productType === appPaymentProductType.RECURRING && maxAmount !== minAmount
      );

      reccuringProducts = fixedProducts;
      reccuringProducts.sort((x, y) => x.amount - y.amount);
      reccuringProducts.push(directProduct[0]);

      setPaymentProducts(reccuringProducts);
    }
  };

  // Hooks

  return (
    <>
      {processToPaymentForm.periodType === '' && (
        <div className="card mx-auto">
          <div className="card-body">
            <Formik
              initialValues={processToPaymentForm}
              validationSchema={processToProductValidations}
              onSubmit={(data) => {
                // setIsPaymentClicked(!isPaymentClicked);
                // processToPayment(data);
                setProcessToPaymentForm(data);
              }}
            >
              {({
                values: { periodDay, periodType },
                handleSubmit,
                handleChange,
                touched,
                errors
              }) => (
                <Form className="auto-top-radio-option" onSubmit={handleSubmit}>
                  <h3
                    className="mb-4 pb-3"
                    dangerouslySetInnerHTML={{ __html: t('ek_ap_topup_h4') }}
                  />

                  <>
                    <div
                      aria-hidden
                      role="button"
                      onClick={() => {
                        handleChange('periodType')({
                          target: { name: 'periodType', value: appAutoTopUpPeriodType.LOW_BALANCE }
                        });
                        onChangeProductType(appAutoTopUpPeriodType.LOW_BALANCE);
                        setIsAutoLowEnabled(false);
                      }}
                    >
                      <div className="col-lg-12">
                        <div className="d-flex mb-4 mt-4">
                          <span className="copytext-semibold me-3 pt-2">
                            {t('ek_autotop-up_setup_option1')}
                          </span>

                          <div className="welcome-badge listing-badge d-flex">
                            <img src={EmpfehLung} alt="Icon" />{' '}
                            <h6 className="mb-0 px-2">{t('ek_autotop-up_setup_recommendation')}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-4 mt-4">
                        <div className="col-lg-6 ps-1">
                          <span className="copytext">{t('ek_autotop-up_setup_text4')}</span>
                        </div>
                        <div className="col-lg-1">
                          <Radio
                            type="radio"
                            name="periodType"
                            id={appAutoTopUpPeriodType.LOW_BALANCE}
                            value={appAutoTopUpPeriodType.LOW_BALANCE}
                            isDefaultChecked={periodType === appAutoTopUpPeriodType.LOW_BALANCE}
                            onChange={(e) => {
                              onChangeProductType(e);
                              handleChange('periodType')(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <p
                        className="copytext-semibold ms-3 mt-4"
                        dangerouslySetInnerHTML={{ __html: t('ek_autotop-up_text6') }}
                      />
                    </div>
                    <div
                      aria-hidden
                      className="mb-2"
                      onClick={() => {
                        handleChange('periodType')({
                          target: {
                            name: 'periodType',
                            value: appAutoTopUpPeriodType.LOW_BALANCE
                          }
                        });
                        onChangeProductType(appAutoTopUpPeriodType.LOW_BALANCE);
                        setIsAutoLowEnabled(true);
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="col-lg-10 ps-1">
                          <span className="copytext">{t('ek_autotop-up_setup_text6')}</span>
                        </div>
                        <div className="col-lg-1">
                          <Radio
                            reverse
                            type="radio"
                            // name="periodType"
                            id={appAutoTopUpPeriodType.LOW_BALANCE}
                            value={isAutoLowEnabled}
                            isDefaultChecked={
                              periodType === appAutoTopUpPeriodType.LOW_BALANCE && isAutoLowEnabled
                            }
                            onChange={(e) => {
                              onChangeProductType(e);
                              handleChange('periodType')(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      aria-hidden
                      role="button"
                      onClick={() => {
                        handleChange('periodType')({
                          target: {
                            name: 'periodType',
                            value: appAutoTopUpPeriodType.ONCE_PER_MONTH
                          }
                        });
                        onChangeProductType(appAutoTopUpPeriodType.ONCE_PER_MONTH);
                      }}
                    >
                      <div className="col-lg-12">
                        <div>
                          <span className="copytext-semibold me-3 pt-2">
                            {t('ek_autotop-up_setup_option2')}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <div className="col-lg-3 ps-1">
                          <span className="copytext">{t('ek_autotop-up_setup_text7')}</span>
                        </div>
                        <div className="col-lg-3">
                          <div className="options-size">
                            <DropDown
                              id="periodDay"
                              name="periodDay"
                              label={t('ek_autotop-up_setup_days')}
                              apiData={appPaymentCertainDay.map((value) => ({
                                id: `${value}`,
                                value: `${value}`
                              }))}
                              value={periodDay}
                              setSelectedItem={(e) => {
                                setSelectPeriodDay(e);
                                handleChange({
                                  target: {
                                    id: 'periodDay',
                                    name: 'periodDay',
                                    value: e.id
                                  }
                                });
                              }}
                              selectedItem={selectPeriodDay}
                              error={
                                touched.periodDay && errors.periodDay ? errors.periodDay : null
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <span className="copytext">{t('ek_autotop-up_setup_text8')}</span>
                        </div>
                        <div className="col-lg-1">
                          <Radio
                            type="radio"
                            isDefaultChecked={periodType === appAutoTopUpPeriodType.ONCE_PER_MONTH}
                            name="periodType"
                            id={appAutoTopUpPeriodType.ONCE_PER_MONTH}
                            value={appAutoTopUpPeriodType.ONCE_PER_MONTH}
                            onChange={(e) => {
                              onChangeProductType(e);
                              handleChange('periodType')(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      aria-hidden
                      className="col-lg-12"
                      onClick={() => {
                        handleChange('periodType')({
                          target: { name: 'periodType', value: appAutoTopUpPeriodType.RATE }
                        });
                        onChangeProductType(appAutoTopUpPeriodType.RATE);
                        setIsAutoLowEnabled(true);
                      }}
                    >
                      <div className="col-lg-12 pt-3 pb-3">
                        <div>
                          <span className="copytext-semibold me-3 pt-2">
                            {t('ek_autotop-up_setup_option3')}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="col-lg-10 ps-1">
                          <span className="copytext">{t('ek_autotop-up_setup_text6')}</span>
                        </div>
                        <div className="col-lg-1">
                          <Radio
                            type="radio"
                            name="periodType"
                            id="selfNumber"
                            isDefaultChecked={periodType === appAutoTopUpPeriodType.RATE}
                            value={appAutoTopUpPeriodType.RATE}
                            onChange={(e) => {
                              onChangeProductType(e);
                              handleChange('periodType')(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <Col xs={12} lg={12}>
                  <AutoTopUpOptions
                    processToPaymentForm={processToPaymentForm}
                    allPaymentProducts={allPaymentProducts}
                    setPaymentProducts={setPaymentProducts}
                  />
                </Col> */}

                    <div className="d-block invalid-feedback">
                      {!!errors.periodType && (
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                      )}
                      {errors.periodType}
                    </div>
                    <div className="mt-5 d-flex justify-content-end">
                      <Button
                        buttonClass={appButtonType.PRIMARY}
                        disabled={!periodType}
                        isIcon
                        icon={<i className="bi bi-arrow-right" />}
                        type="sumbit"
                        isLoading={isLoading}
                      >
                        {t('ek_ap_topup_next_button')}
                      </Button>
                    </div>
                  </>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {processToPaymentForm.periodType !== '' && processToPaymentForm.productId === '' && (
        <div className="card mx-auto">
          <div className="card-body">
            <Formik
              initialValues={processToPaymentForm}
              validationSchema={productValidation}
              validateOnMount
              onSubmit={processToPayment}
            >
              {({ values, handleChange, handleBlur, handleSubmit, setValues, errors, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <h3 className="mb-5 pb-4">{t('ek_autotop-up_setup_h4')}</h3>
                  <div className="price-radio">
                    <div className="row">
                      {paymentProducts.map((item) =>
                        item.amount > 0 || Number(item.minAmount) === Number(item.maxAmount) ? (
                          <div className="col-lg-2 me-4" key={`select-payment-product-${item.id}`}>
                            <PaymentProduct
                              id={item.id}
                              amount={item.amount}
                              isSelected={values.productId === item.id}
                              onBlur={handleBlur}
                              onSelect={(id) => {
                                handleChange('productId')({
                                  target: { name: 'productId', value: id }
                                });
                                handleChange('productType')({
                                  target: { name: 'productType', value: item.productType }
                                });
                                handleChange('isDirectProduct')({
                                  target: { name: 'isDirectProduct', value: 'NO' }
                                });
                                handleChange('otherAmount')({
                                  target: { name: 'otherAmount', value: item.amount }
                                });
                                setOtherAmount(item.amount);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="py-2" key={`select-direct-payment-product-${item.id}`}>
                            <DirectPaymentProduct
                              id={item.id}
                              isSelected={values.productId === item.id}
                              otherAmount={values.otherAmount}
                              onBlur={handleBlur}
                              error={
                                touched.otherAmount && errors.otherAmount
                                  ? errors.otherAmount
                                  : null
                              }
                              onSelect={(id) => {
                                setValues({
                                  ...values,
                                  productId: id,
                                  productType: item.productType,
                                  isDirectProduct: 'YES'
                                });
                              }}
                              onAmountChange={(amount) => {
                                setValues({
                                  ...values,
                                  productId: item.id,
                                  productType: item.productType,
                                  isDirectProduct: 'YES',
                                  otherAmount: amount
                                });
                                setOtherAmount(amount);
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  {touched.productId && errors.productId && (
                    <div className="d-block invalid-feedback">
                      {!!errors.productId && (
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                      )}
                      {errors.productId}
                    </div>
                  )}
                  <div className="mt-5 pt-5" />
                  <div className="mt-5 pt-5 d-flex justify-content-end">
                    <Button
                      buttonClass={appButtonType.PRIMARY}
                      disabled={!values.productId}
                      isIcon
                      icon={<i className="bi bi-arrow-right" />}
                      type="submit"
                    >
                      {t('ek_autotop-up_setup_next')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {processToPaymentForm.periodType !== '' && processToPaymentForm.productId !== '' && (
        <PaymentMethods
          isLoading={isLoading}
          paymentMethod={paymentMethod}
          chargingForm={chargingForm}
          chargingValidations={chargingValidations}
          chargingFormSubmit={chargingFormSubmit}
        />
      )}
    </>
  );
}

OptionsAndProducts.propTypes = {
  paymentProducts: PropTypes.arrayOf(PropTypes.shape({})),
  allPaymentProducts: PropTypes.arrayOf(PropTypes.shape({}))
  // paymentProductId: PropTypes.number,
  // otherAmount: PropTypes.string,
  // setOtherAmount: PropTypes.func,
  // isPaymentClicked: PropTypes.bool.isRequired,
  // setIsPaymentClicked: PropTypes.func.isRequired
  // handleChange: PropTypes.func.isRequired
};
OptionsAndProducts.defaultProps = {
  paymentProducts: [],
  allPaymentProducts: []
  // paymentProductId: 0,
  // otherAmount: '',
  // setOtherAmount: () => {}
};

export default OptionsAndProducts;
