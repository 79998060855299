import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { Badge } from '@core/Utils';

import { useAlert } from '@context/Utils/Alert';
import { useActivation } from '@context/MobileOne';
import {
  appButtonTypes,
  appCounterButtonTypes,
  appCounterTypes,
  appImages,
  appPaymentMethods,
  appPaymentProductType,
  appRoute,
  appTopUpTo,
  displayPrice,
  getPlanIconName
} from '@utils/globalConstant';
import { LoadingScreen } from '@core/LoadingScreen';
import { PopupAutotopUpMoreInfo } from '@core/PopupAutotopUpMoreInfo';

import { AutoTopUpWelcome } from './AutoTopUpWelcome';
import { ButtonRadio } from '@core/ButtonRadio';
import { PaymentFields } from '@core/PaymentFields';
import { Counter } from '@core/Counter';
import { CounterButton } from '@core/CounterButton';
import { Form, Formik } from 'formik';
import { ButtonPrimary } from '@core/ButtonPrimary';

import './Step6.scss';
import { ButtonSecondary } from '@core/ButtonSecondary';

export function ActivationStep6() {
  const [openModal, setOpenModal] = useState(false);

  // Contexts
  const { t, staticContentData } = useStaticContent();
  const navigate = useNavigate();
  const { isGenericError, setIsGenericError, setAlert } = useAlert();
  const {
    isLoading,
    isPaymentProductLoading,
    isPaymentMethodLoading,

    // currentSubStep6,
    // setCurrentSubStep6,
    ssoPaymentToken,
    paymentProducts,
    paymentMethod,
    autoTopUpForm,
    autoTopUpValidations,
    processToPayment
  } = useActivation();

  // Functions
  const startTopUpProcess = async () => {
    // Disable welcome screen
    // await ssoPaymentToken({
    //   clientId: '',
    //   alphacommHash:
    //     '',
    //   msisdn: ''
    // });
    // setCurrentSubStep6(currentSubStep6 + 1);
  };

  const onClickPosident = () => {
    navigate(appRoute.ACTIVATION_LEGITIMATION);
  };

  const filterProduct = () =>
    (paymentProducts || [])
      .filter(
        (product) => product.productType === appPaymentProductType.LOW_BALANCE // ||
      )
      .filter((product) => Number(product.id) !== 887189 && Number(product.id) !== 887525)
      .sort((a, b) => a.amount - b.amount);

  const filterPaymentMethod = () =>
    (paymentMethod || [])
      .filter(({ paymentTokenEnabled }) => paymentTokenEnabled)
      .sort((a, b) => {
        const filterList = staticContentData?.nr_paymentMethodSortOrder?.activationTopUp || [];
        return filterList.indexOf(a.name) - filterList.indexOf(b.name);
      });

  const getProductLabel = (product) => {
    if (product.amount > 0 || Number(product.minAmount) === Number(product.maxAmount)) {
      return `${product.amount} €`;
    }
    return '';
  };

  const getDirectProductLabel = (amount) => `${displayPrice(amount)} €`.replace('.', ',');

  const getPaymentMethodLabel = (name) => {
    switch (name) {
      case appPaymentMethods.PAYPAL:
        return t('nc_topup_pay_txt2');
      case appPaymentMethods.SOFORT:
        return t('nc_topup_pay_txt3');
      case appPaymentMethods.CREDIT_CARD:
        return t('nc_topup_pay_txt4');
      case appPaymentMethods.PAY_DIRECT:
        return t('nc_topup_pay_txt6');
      case appPaymentMethods.SEPA_DIRECT_DEBIT:
        return t('nc_topup_pay_txt7');
      case appPaymentMethods.AMERICAN_EXPRESS:
        return t('nc_topup_pay_txt5');
      default:
        return name;
    }
  };

  // Hooks
  useEffect(() => {
    setIsGenericError(false);
    setAlert({});
    // startTopUpProcess();
    const infoModal = document.getElementById('auto-top-up-more-info');
    if (infoModal !== null) {
      infoModal.addEventListener('click', () => {
        setOpenModal(true);
      });
    }

    return () => {
      if (infoModal !== null) {
        infoModal.removeEventListener('click', () => {
          setOpenModal(true);
        });
      }
    };
  }, []);

  return (
    <>
      {!isGenericError && (
        <div className="container-md animate__animated animate__slideInRight">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-3 px-sm-0 px-md-3">
              <div>
                <div className="pb-4">
                  <Badge title={t('nc_reg_step6_topup_badge_tipp')} />
                </div>
                <h3
                  className="nc-doomsday-h3 pb-4 mb-0"
                  dangerouslySetInnerHTML={{ __html: t('nc_reg_step6_topup_hdl1') }}
                />
                {/* <AutoTopUpWelcome
                  startTopUpProcess={startTopUpProcess}
                  onClickPosident={onClickPosident}
                /> */}
                {/* {currentSubStep6 > 0 ? (
                  <SetAutoTopup />
                ) : (
                  <AutoTopUpWelcome
                    startTopUpProcess={startTopUpProcess}
                    onClickPosident={onClickPosident}
                  />
                )} */}
              </div>
              <div className="row">
                <div className="col-12">
                  <p
                    className=""
                    dangerouslySetInnerHTML={{ __html: t('nc_reg_step6_topup_txt') }}
                  />
                </div>
              </div>
              <Formik
                initialValues={autoTopUpForm}
                enableReinitialize
                validationSchema={autoTopUpValidations}
                onSubmit={processToPayment}>
                {({ values, errors, handleSubmit, handleBlur, handleChange, setValues }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12 py-4">
                        <h4
                          className="nc-realheadpro-h4"
                          dangerouslySetInnerHTML={{ __html: t('nc_reg_step6_topupprice_hdl') }}
                        />
                      </div>
                      <div className="payment-products pb-4">
                        {isPaymentProductLoading && <LoadingScreen />}
                        {!isPaymentProductLoading &&
                          filterProduct().map((item, index) =>
                            item.amount > 0 || Number(item.minAmount) === Number(item.maxAmount) ? (
                              <div
                                key={`product-${index}`}
                                data-p-id={item.id}
                                className="items animate__animated animate__backInRight">
                                <CounterButton
                                  id={item.id}
                                  customClass="w-100"
                                  buttonType={
                                    values.productId === item.id
                                      ? appCounterButtonTypes.LIGHT
                                      : appCounterButtonTypes.DARK
                                  }
                                  label={getProductLabel(item)}
                                  amount={item.id}
                                  onSelect={(id) => {
                                    setValues({
                                      ...values,
                                      productId: id,
                                      productType: item.productType,
                                      isDirectProduct: 'NO',
                                      productAmount: item.amount
                                    });
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                key={`select-direct-payment-product-${item.id}`}
                                className="counter-item mx-auto animate__animated animate__zoomIn">
                                <div className="w-100">
                                  <Counter
                                    containerLabel={t('nc_topupprice_label')}
                                    id={item.id}
                                    customConitainerClass="text-center"
                                    type={
                                      values.productId === item.id
                                        ? appCounterTypes.LIGHT
                                        : appCounterTypes.DARK
                                    }
                                    label={
                                      !values.productAmount
                                        ? t('nc_reg_step6_topupprice_own_price')
                                        : getDirectProductLabel(values.productAmount)
                                    } // getDirectProductLabel(values.productAmount)
                                    value={values.productAmount}
                                    onSelect={(id) => {
                                      if (values.productId !== id) {
                                        setValues({
                                          ...values,
                                          productId: id,
                                          productType: item.productType,
                                          isDirectProduct: 'YES',
                                          productAmount: item.minAmount
                                        });
                                      }
                                    }}
                                    onClickMinus={(amount) => {
                                      if (amount >= Number(item.minAmount)) {
                                        setValues({
                                          ...values,
                                          productId: item.id,
                                          productType: item.productType,
                                          isDirectProduct: 'YES',
                                          productAmount: amount
                                        });
                                      }
                                    }}
                                    onClickPlus={(amount) => {
                                      if (amount <= Number(item.maxAmount)) {
                                        setValues({
                                          ...values,
                                          productId: item.id,
                                          productType: item.productType,
                                          isDirectProduct: 'YES',
                                          productAmount: amount
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 py-8">
                        <h4
                          className="nc-realheadpro-h4"
                          dangerouslySetInnerHTML={{ __html: t('nc_topup_pay_txt1') }}
                        />
                      </div>
                      {isPaymentMethodLoading && <LoadingScreen />}
                      {!isPaymentMethodLoading &&
                        filterPaymentMethod().map((item, index) => (
                          <div
                            className="col-xl-6 col-lg-12 pb-3 animate__animated animate__flipInX"
                            key={`payment-methods-${index}`}
                            role="button"
                            aria-hidden
                            onClick={() => {
                              handleChange({
                                target: {
                                  name: 'paymentMethodName',
                                  value: item.name
                                }
                              });
                            }}>
                            <PaymentFields
                              icon={getPlanIconName(item.name)}
                              value={values.paymentMethodName === item.name}
                              label={getPaymentMethodLabel(item.name)}
                              isDefaultChecked={values.paymentMethodName === item.name}
                              onChange={(isTrue) => {
                                if (isTrue) {
                                  handleChange({
                                    target: {
                                      name: 'paymentMethodName',
                                      value: item.name
                                    }
                                  });
                                }
                              }}
                            />
                            {values.paymentMethodName === item.name &&
                              item.name === appPaymentMethods.SEPA_DIRECT_DEBIT && (
                                <p
                                  className="py-3 mb-n2 animate__animated animate__zoomIn"
                                  dangerouslySetInnerHTML={{
                                    __html: t('nc_topup_pay_txt7_active')
                                  }}
                                />
                              )}
                          </div>
                        ))}
                      {/* <div className="pt-8">
                <Payment
                  formInitValues={{ paymentMethodName: '' }}
                  formValidations={paymentValidations}
                  formSubmit={onStepFiveSubmit}
                  paymentMethods={paymentMethod}
                  isLoading={isLoading}
                />
              </div> */}
                    </div>
                    <div className="row pt-4">
                      <div className="col-12 py-4">
                        <div className="d-flex flex-rows pb-2">
                          <div className="pe-3" style={{ paddingTop: '2px' }}>
                            <ButtonRadio
                              isDefaultChecked={values.terms}
                              value={values.terms}
                              id="terms"
                              onBlur={handleBlur}
                              onChange={() => {
                                handleChange({
                                  target: {
                                    value: !values.terms,
                                    name: 'terms',
                                    id: 'terms'
                                  }
                                });
                              }}
                            />
                          </div>

                          <p
                            className="pt-1"
                            dangerouslySetInnerHTML={{ __html: t('nc_reg_step6_alphacom_txt') }}
                          />
                        </div>
                        <div className="py-4 text-center">
                          <img src={appImages.alphacommLogo} alt="alphacomm-logo" />
                        </div>
                      </div>
                      <div className="col-12 py-8 text-center">
                        <ButtonPrimary
                          isLoading={isLoading}
                          icon="arrowright"
                          label={t('nc_reg_step6_topup_btn')}
                          disabled={
                            isLoading ||
                            !values.productId ||
                            !values.paymentMethodName ||
                            !values.terms ||
                            (errors &&
                              Object.keys(errors || {}).filter((v) => errors && errors && errors[v])
                                .length > 0)
                          }
                          type="submit"
                          buttonType={appButtonTypes.PRIMARY.DEFAULT}
                        />
                      </div>
                      <div className="col-12 pt-8 text-center">
                        <ButtonSecondary
                          type={appButtonTypes.SECONDARY.DEFAULT}
                          label={t('nc_reg_step6_topup_btn2')}
                          icon="forwardprimary"
                          onClick={onClickPosident}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
      <PopupAutotopUpMoreInfo
        isOpen={openModal}
        title={t('nc_reg_autotopup_modal_hdl')}
        bodyText={t('nc_reg_autotopup_modal_txt')}
        buttonLabel={t('nc_reg_autotopup_modal_btn')}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}

export default ActivationStep6;
