/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appIcons } from '@utils/globalConstant';
import { Icons } from '@core/Utils/Icons';
import { BubbleTabs } from '@core/BubbleTabs';

function ActiveTab({ index, strokeColor, bubbleColor, bubbleIcon, text, ...props }) {
  let justifyContent;
  if (index === 0) {
    justifyContent = 'start';
  } else if (index === 2) {
    justifyContent = 'end';
  } else {
    justifyContent = 'center';
  }

  return (
    <div
      className={`d-flex align-items-center justify-content-${justifyContent} animate__animated animate__flipInY`}>
      <div className="position-absolute">
        <BubbleTabs text={text} bubbleColor={bubbleColor} index={index} bubbleIcon={bubbleIcon} />
      </div>
    </div>
  );
}

function Tab({ index, onClick, icon, text }) {
  let justifyContent;
  if (index === 0) {
    justifyContent = 'start';
  } else if (index === 2) {
    justifyContent = 'end';
  } else {
    justifyContent = 'center';
  }

  return (
    <div
      className={`d-flex align-items-center justify-content-${justifyContent} px-5 pb-1`}
      onClick={onClick}
      role="button">
      <div className="">
        <Icons name={icon} />
      </div>
      <div className="">
        <p className="p-0 m-0 ps-1 pt-1 nc-doomsday-copy text-gray-100">{text}</p>
      </div>
    </div>
  );
}

export function TarifProductNavigation({ strokeColor, bubbleColor, onChange }) {
  const [active, setActive] = useState(1);

  const { t } = useStaticContent();

  const handleClick = (i) => {
    setActive(i);
    onChange(i);
  };

  return (
    <div className="px-3 ">
      <div className="row bg-gray-10 py-1 rounded-pill">
        <div className="col-4 p-0 align-self-center justify-self-start">
          {active === 0 ? (
            <ActiveTab
              index={0}
              bubbleColor={bubbleColor}
              bubbleIcon={'minutes'}
              icon={appIcons.minutes}
              // text="Min."
              text={t('nc_dboard_tab_min')}
            />
          ) : (
            <Tab index={0} onClick={() => handleClick(0)} icon="minutesgray100" text={t('nc_dboard_tab_min')} />
          )}
        </div>
        <div className="col-4 p-0 align-self-center justify-self-center">
          {active === 1 ? (
            <ActiveTab
              index={1}
              bubbleColor={bubbleColor}
              bubbleIcon={'data'}
              icon={appIcons.data}
              // text="Daten"
              text={t('nc_dboard_tab_date')}
            />
          ) : (
            <Tab
              index={1}
              onClick={() => handleClick(1)}
              icon="datagray100"
              // text="Daten"
              text={t('nc_dboard_tab_date')}
            />
          )}
        </div>
        <div className="col-4 p-0 align-self-center justify-self-center">
          {active === 2 ? (
            <ActiveTab
              index={2}
              bubbleColor={bubbleColor}
              bubbleIcon={'sms'}
              icon={appIcons.sms}
              // text="SMS"
              text={t('nc_dboard_tab_sms')}
            />
          ) : (
            <Tab
              index={2}
              onClick={() => handleClick(2)}
              icon="smsgray100"
              // text="SMS"
              text={t('nc_dboard_tab_sms')}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TarifProductNavigation;
