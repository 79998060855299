import React from 'react';
import RSlider from 'react-slick';
import PropTypes from 'prop-types';

import './index.scss';

// function NextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <Button
//       className={`p-0 ${className} d-inline-flex align-items-center justify-content-center text-primary`}
//       style={{ ...style, background: '#d4d4d4', width: '35px', height: '35px', border: 'none' }}
//       onClick={onClick}
//     />
//   );
// }

// function PrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <Button
//       className={`p-0 ${className} d-inline-flex align-items-center justify-content-center text-primary`}
//       style={{ ...style, background: '#d4d4d4', width: '35px', height: '35px', border: 'none' }}
//       onClick={onClick}
//     />
//   );
// }

export function Slider({ items, isDashboard }) {
  const settings = {
    className: isDashboard ? '' : 'center',
    dots: true,
    centerMode: !isDashboard,
    infinite: !isDashboard,
    centerPadding: isDashboard ? '20px' : '10px',
    focusOnSelect: !isDashboard,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerMode: !isDashboard,
          centerPadding: '10px',
          slidesToScroll: 1,
          infinite: !isDashboard,
          dots: true
        }
      }
    ]
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />
  };

  return (
    <>
      <div className="container p-0">
        <div className="row">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <RSlider {...settings} className={`${isDashboard ? 'h-100' : ''}`}>
            {/* eslint-disable-next-line react/no-array-index-key */}
            {items.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${item.key}_${index}`}>{item}</div>
            ))}
          </RSlider>
        </div>
      </div>
    </>
  );
}

Slider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  isDashboard: PropTypes.bool
};

Slider.defaultProps = {
  isDashboard: false
};

export default Slider;
