import React from 'react';
import { Text } from '@core/Inputs';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Tooltip } from '@core/Utils';
import tooltipIcon from '@theme/assets/img/tooltip.svg';
import { appTextFieldLabelClass } from '@utils/globalConstant';

export function HotlinePassword({
  hotlinePassword,
  confirmHotlinePassword,
  handleChange,
  handleBlur,
  touched,
  errors
}) {
  // Contexts
  const { t } = useStaticContent();

  return (
    <>
      <div className="row">
        <div className="col-xs-12 px-3 py-2">
          <h4 className="d-inline-block mt-5">{t('ek_ap_personal_h5')}</h4>
          <Tooltip title={t('ek_ap_hotline_info')}>
            <img className="tool-tip-icon" src={tooltipIcon} alt="tooltip_icon" />
          </Tooltip>
        </div>
      </div>
      <div className="row">
        <div>
          <Text
            type="password"
            labelCustomClass={appTextFieldLabelClass.V1}
            label={t('ek_ap_personal_holine-pw2')}
            id="hotlinePassword"
            name="hotlinePassword"
            onChange={handleChange}
            value={hotlinePassword}
            placeholder={t('ek_ap_personal_holine-pw_input1')}
            isValid={touched.hotlinePassword && !errors.hotlinePassword}
            isInvalid={!!touched.hotlinePassword && !!errors.hotlinePassword}
            onBlur={handleBlur}
            error={
              touched.hotlinePassword && errors.hotlinePassword ? errors.hotlinePassword : null
            }
          />
        </div>
        <div>
          <Text
            type="password"
            labelCustomClass={appTextFieldLabelClass.V1}
            label={t('ek_ap_personal_holine-pw2')}
            id="confirmHotlinePassword"
            name="confirmHotlinePassword"
            onChange={handleChange}
            value={confirmHotlinePassword}
            placeholder={t('ek_ap_personal_holine-pw_input2')}
            isValid={touched.confirmHotlinePassword && !errors.confirmHotlinePassword}
            isInvalid={!!touched.confirmHotlinePassword && !!errors.confirmHotlinePassword}
            onBlur={handleBlur}
            error={
              touched.confirmHotlinePassword && errors.confirmHotlinePassword
                ? errors.confirmHotlinePassword
                : null
            }
          />
        </div>
      </div>
    </>
  );
}

HotlinePassword.propTypes = {
  hotlinePassword: PropTypes.string,
  confirmHotlinePassword: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({})
};

HotlinePassword.defaultProps = {
  hotlinePassword: '',
  confirmHotlinePassword: '',
  handleChange: () => {},
  handleBlur: () => {},
  touched: () => {},
  errors: {}
};

export default HotlinePassword;
