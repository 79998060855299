/* eslint-disable import/no-unresolved */
import React, { lazy, useEffect } from 'react';
import {
  Routes,
  Route,
  useSearchParams,
  useLocation,
  useNavigate,
  Navigate
} from 'react-router-dom';

// import { PrivateRoute } from '@config/AppRoutes/PrivateRoute';
import { appStorage } from '@dom-digital-online-media/dom-app-config-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';

import { Test, Test2 } from '@page/index';

import { withLayout } from '@components/part/Layout';
import { appRoute, storageKeys } from '@utils/globalConstant';

import { useConfig } from '@config/ContextManager';
// import { useAlert } from '@context/Utils';

import { MainLayout } from '@part/MainLayout';
import { ActivationLayout } from '@part/ActivationLayout';
import { TariffOptionLayout } from '@part/TariffOptionLayout';

import { FallBack } from './FallBack';
import { PrivateRoute, PublicRoute, TestRoute } from './PrivateRoute';

// Lazy load all pages
// TODO: Implement data loading for each page with suspense
// https://reactrouter.com/en/main/route/lazy
const Activation = lazy(() => import('@pages/Activation'));
const ActivationPaymentVerification = lazy(() =>
  import('@pages/Activation/Step6/PaymentVerification')
);
const ActivationLegitimation = lazy(() => import('@pages/Activation/Step6/Legitimation'));
const ActivationLegitimationSuccess = lazy(() =>
  import('@pages/Activation/Step6/LegitimationSuccess')
);
const ActivationLegitimationFailed = lazy(() =>
  import('@pages/Activation/Step6/LegitimationFailed')
);

const Dashboard = lazy(() => import('@pages/Dashboard'));
const EmailVerification = lazy(() => import('@pages/EmailVerification'));
const Home = lazy(() => import('@pages/Home'));
const Login = lazy(() => import('@pages/Login'));
const NotFound = lazy(() => import('@pages/NotFound'));

const TariffOverview = lazy(() => import('@pages/TariffOption/TariffOverview/TariffOverview'));
const OptionOverview = lazy(() => import('@pages/TariffOption/OptionOverview/OptionOverview'));
const AuslandOverview = lazy(() => import('@pages/TariffOption/AuslandOverview/AuslandOverview'));

const TariffDetail = lazy(() => import('@pages/TariffOption/TariffDetail/TariffDetail'));
const OptionDetail = lazy(() => import('@pages/TariffOption/OptionDetail/OptionDetail'));
const AdditionalOptionDetail = lazy(() =>
  import('@pages/TariffOption/AdditionalOptionDetail/AdditionalOptionDetail')
);
// const BookableOptionDetail = lazy(() =>
//   import('@pages/TariffOption/BookableOptionDetail/BookableOptionDetail')
// );
const SpeedOnDetail = lazy(() => import('@pages/TariffOption/SpeedOnDetail/SpeedOnDetail'));
// const ForcePasswordChange = lazy(() => import('@pages/ForcePasswordChange/ForcePasswordChange'));

const AufladenOverview = lazy(() => import('@pages/TopUp/AufladenOverview/AufladenOverview'));
const OnlineTopUp = lazy(() => import('@pages/TopUp/OnlineTopUp/OnlineTopUp'));
const OnlineTopUpCallBack = lazy(() =>
  import('@pages/TopUp/OnlineTopUpCallBack/OnlineTopUpCallBack')
);
const VoucherTopUp = lazy(() => import('@pages/TopUp/VoucherTopUp/VoucherTopUp'));
const VoucherHistory = lazy(() => import('@pages/TopUp/VoucherHistory/VoucherHistory'));
const VoucherTopUpCallBack = lazy(() =>
  import('@pages/TopUp/VoucherTopUpCallBack/VoucherTopUpCallBack')
);

const HelpServicesOverview = lazy(() => import('@pages/HelpServices/Overview'));
const HelpServicesContact = lazy(() => import('@pages/HelpServices/Contact'));
const HelpServicesFaq = lazy(() => import('@pages/HelpServices/Faq'));
const HelpServicesPrivacyPolicy = lazy(() => import('@pages/HelpServices/PrivacyPolicy'));
const HelpServicesServiceVideo = lazy(() => import('@pages/HelpServices/ServiceVideo'));
const HelpServicesImprint = lazy(() => import('@pages/HelpServices/Imprint'));
const HelpServicesCancelContract = lazy(() => import('@pages/HelpServices/CancelContract'));

const AccountOverview = lazy(() => import('@pages/Account/Overview'));
const AccountAutoTopUp = lazy(() => import('@pages/Account/AutoTopUp'));
const AccountDocument = lazy(() => import('@pages/Account/Document'));
const AccountPrivateDataOverview = lazy(() =>
  import('@pages/Account/PrivateData/PrivateDataOverview')
);

const AccountPrivateDataChangeConsent = lazy(() =>
  import('@pages/Account/PrivateData/ChangeConsent')
);
const AccountPrivateDataChangeContactNumber = lazy(() =>
  import('@pages/Account/PrivateData/ChangeContactNumber')
);
const AccountPrivateDataChangeEmail = lazy(() => import('@pages/Account/PrivateData/ChangeEmail'));
const AccountPrivateDataChangeHotlinePin = lazy(() =>
  import('@pages/Account/PrivateData/ChangeHotlinePin')
);
const AccountPrivateDataNewPassword = lazy(() => import('@pages/Account/PrivateData/NewPassword'));

const AccountTariffOption = lazy(() => import('@pages/Account/TariffOption'));
const AccountLogout = lazy(() => import('@pages/Account/Logout'));
const AccountEVNPdfOverview = lazy(() =>
  import('@pages/Account/PrivateData/EVNPdfOverview/EVNPdfOverview')
);

export function AllRoutes() {
  // Constants

  // States
  // const [oauthVerifyLoading, setOauthVerifyLoading] = useState(true);

  // Contexts
  const location = useLocation();
  const navigate = useNavigate();

  // const { showAlert } = useAlert();
  const { verifyLogin, setUserLogin } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    config: { storage }
  } = useConfig();

  // Functions
  const getAccess = async (code) => {
    try {
      const codeVerifier = await storage.getItem(appStorage.AUTH_CODE_VERIFIER);

      if (code && codeVerifier) {
        // setOauthVerifyLoading(false);

        await storage.encryptedSetItem(appStorage.AUTH_CODE, code);
        // eslint-disable-next-line no-debugger
        const { data } = await verifyLogin(code, codeVerifier);

        // setUserLogin(true);
        await storage.encryptedSetItem(appStorage.AUTH_TOKEN, data.access_token);
        await storage.encryptedSetItem(appStorage.AUTH_REFRESH_TOKEN, data.refresh_token);
        await storage.encryptedSetItem(appStorage.USER_AUTH_DATA, JSON.stringify(data));

        // Clear up url;
        setSearchParams('');
        // Check if user is comming from previous route
        const APP_PREVIOUS_ROUTE_STORAGE = 'navigateTo';
        if (localStorage && localStorage.getItem(APP_PREVIOUS_ROUTE_STORAGE)) {
          setTimeout(() => {
            const previousRoute = localStorage.getItem(APP_PREVIOUS_ROUTE_STORAGE);
            localStorage.removeItem(APP_PREVIOUS_ROUTE_STORAGE);
            navigate(previousRoute);
          }, 150);
        }
        // showAlert({ type: appAlert.SUCCESS, message: 'Welcome' });
        return true;
      }
      setUserLogin(false);
      // setOauthVerifyLoading(false);
      setSearchParams('');
      return false;
    } catch (error) {
      console.log(error);
      // setOauthVerifyLoading(false);
      // Show alert and clear up navigation
      // showAlert({ type: appAlert.ERROR, message: 'Something went wrong!' });
      setSearchParams('');
      return false;
    }
  };

  const oauthValidate = async () => {
    const accessToken = await storage.encryptedGetItem(appStorage.AUTH_TOKEN);
    const refreshToken = await storage.encryptedGetItem(appStorage.AUTH_REFRESH_TOKEN);
    // setUserLogin(accessToken && refreshToken); // useEffect rerendring stoped by this
    if (accessToken && refreshToken) {
      setUserLogin(true);
    } else {
      setUserLogin(false);
    }
    // setTimeout(() => {
    //   setOauthVerifyLoading(false);
    // }, 150);
    return accessToken && refreshToken;
  };

  const callbackValidator = async () => {
    try {
      const sessionState = searchParams.get(storageKeys.SESSION_STATE);
      const code = searchParams.get(storageKeys.CODE);

      // eslint-disable-next-line no-debugger
      if (code && sessionState) {
        await storage.encryptedSetItem(storageKeys.CODE, code);
        await storage.encryptedSetItem(storageKeys.SESSION_STATE, sessionState);
        await getAccess(code);
      } else {
        await oauthValidate();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Hooks
  // Authentication callback
  useEffect(() => {
    callbackValidator();
  }, []);

  // useEffect(() => {
  //   console.log(oauthVerifyLoading);
  // }, [oauthVerifyLoading]);

  // useEffect(() => {
  //   oauthValidate();
  // }, [location]);

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' // Optional if you want to skip the scrolling animation
    });
  }, [location.pathname]);

  // if (oauthVerifyLoading) {
  //   return <>Loading...</>;
  // }

  return (
    <Routes>
      {/* Testing UI Page */}
      <Route
        index
        path={appRoute.TEST}
        element={
          <React.Suspense fallback={<FallBack />}>
            <TestRoute>
              <Test />
            </TestRoute>
          </React.Suspense>
        }
      />
      <Route
        index
        path={appRoute.TEST2}
        element={
          <React.Suspense fallback={<FallBack />}>
            <TestRoute>
              <Test2 />
            </TestRoute>
          </React.Suspense>
        }
      />

      {/* Main Layout Routes */}
      <Route path="" element={<MainLayout />}>
        <Route
          index
          path={appRoute.LANDING_PAGE}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PublicRoute>
                <Home />
              </PublicRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.LOGIN}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PublicRoute>
                <Login />
              </PublicRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.ACTIVATION_EMAIL_VERIFICATION}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PublicRoute>
                <EmailVerification />
              </PublicRoute>
            </React.Suspense>
          }
        />

        {/* Authenticated Routes */}
        <Route
          path={appRoute.DASHBOARD}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        {/* <Route
          path={appRoute.FORCE_PASSWORD_CHANGE}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <ForcePasswordChange />
              </PrivateRoute>
            </React.Suspense>
          }
        /> */}

        {/* Tariff Routes */}
        <Route path={appRoute.TARIFF_OPTION} element={<TariffOptionLayout />}>
          <Route
            path={appRoute.TARIFF_OVERVIEW}
            element={
              <React.Suspense fallback={<FallBack />}>
                <PrivateRoute>
                  <TariffOverview />
                </PrivateRoute>
              </React.Suspense>
            }
          />

          <Route
            path={appRoute.OPTION_OVERVIEW}
            element={
              <React.Suspense fallback={<FallBack />}>
                <PrivateRoute>
                  <OptionOverview />
                </PrivateRoute>
              </React.Suspense>
            }
          />

          <Route
            path={appRoute.AUSLAND_OVERVIEW}
            element={
              <React.Suspense fallback={<FallBack />}>
                <PrivateRoute>
                  <AuslandOverview />
                </PrivateRoute>
              </React.Suspense>
            }
          />

          <Route
            path={appRoute.TARIFF_DETAIL(false)}
            element={
              <React.Suspense fallback={<FallBack />}>
                <PrivateRoute>
                  <TariffDetail />
                </PrivateRoute>
              </React.Suspense>
            }
          />

          <Route
            path={appRoute.OPTION_DETAIL(false)}
            element={
              <React.Suspense fallback={<FallBack />}>
                <PrivateRoute>
                  <OptionDetail />
                </PrivateRoute>
              </React.Suspense>
            }
          />

          <Route
            path={appRoute.ADDITIONAL_OPTION_DETAIL(false)}
            element={
              <React.Suspense fallback={<FallBack />}>
                <PrivateRoute>
                  <AdditionalOptionDetail />
                </PrivateRoute>
              </React.Suspense>
            }
          />

          <Route
            path={appRoute.PASSOFFER_DETAIL(false)}
            element={
              <React.Suspense fallback={<FallBack />}>
                <PrivateRoute>
                  <SpeedOnDetail />
                </PrivateRoute>
              </React.Suspense>
            }
          />
        </Route>
        {/* Tariff & Options default navigation redirect */}
        <Route
          index
          path={appRoute.TARIFF_OPTION}
          element={<Navigate to={appRoute.TARIFF_OVERVIEW} replace />}
        />

        {/* Topup Routes */}
        <Route
          path={appRoute.TOPUP_AUFLADEN_OVERVIEW}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AufladenOverview />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.TOPUP_ONLINE}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <OnlineTopUp />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.TOPUP_ONLINE_CALLBACK}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <OnlineTopUpCallBack />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.TOPUP_VOUCHER}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <VoucherTopUp />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.TOPUP_VOUCHER_HISTORY}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <VoucherHistory />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.TOPUP_VOUCHER_CALLBACK}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <VoucherTopUpCallBack />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        {/* Topup default navigation redirect */}
        <Route
          index
          path={appRoute.TOPUP_AUFLADEN_OVERVIEW.replace('/overview', '')}
          element={<Navigate to={appRoute.TOPUP_AUFLADEN_OVERVIEW} replace />}
        />

        {/* Help & Services */}
        <Route
          path={appRoute.HELP_SERVICES_OVERVIEW}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <HelpServicesOverview />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.HELP_SERVICES_CONTACT}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <HelpServicesContact />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.HELP_SERVICES_FAQ}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <HelpServicesFaq />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.HELP_SERVICES_PRIVACY_POLICY}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <HelpServicesPrivacyPolicy />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.HELP_SERVICES_VIDEO}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <HelpServicesServiceVideo />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.HELP_SERVICES_IMPRINT}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <HelpServicesImprint />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.HELP_SERVICES_CANCEL_CONTRACT}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <HelpServicesCancelContract />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        {/* Help & Services default navigation redirect */}
        <Route
          index
          path={appRoute.HELP_SERVICES}
          element={<Navigate to={appRoute.HELP_SERVICES_OVERVIEW} replace />}
        />

        {/* Account */}
        <Route
          path={appRoute.ACCOUNT_OVERVIEW}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AccountOverview />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.ACCOUNT_AUTO_TOPUP}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AccountAutoTopUp />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.ACCOUNT_DOCUMENT}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AccountDocument />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.ACCOUNT_PRIVATE_DATA}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AccountPrivateDataOverview />
              </PrivateRoute>
            </React.Suspense>
          }
        />

        {/* Sub Private Data Routes */}
        <Route
          path={appRoute.ACCOUNT_PRIVATE_DATA_CHANGE_CONSENT}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AccountPrivateDataChangeConsent />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.ACCOUNT_PRIVATE_DATA_CHANGE_CONTACT_NUMBER}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AccountPrivateDataChangeContactNumber />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.ACCOUNT_PRIVATE_DATA_CHANGE_EMAIL}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AccountPrivateDataChangeEmail />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.ACCOUNT_PRIVATE_DATA_CHANGE_HOTLINE_PIN}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AccountPrivateDataChangeHotlinePin />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.ACCOUNT_PRIVATE_DATA_NEW_PASSWORD}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AccountPrivateDataNewPassword />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.ACCOUNT_TARIFF_OPTION}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AccountTariffOption />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.ACCOUNT_PRIVATE_DATA_EVN_PDF_VIEW(false)}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AccountEVNPdfOverview />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        <Route
          path={appRoute.ACCOUNT_LOGOUT}
          element={
            <React.Suspense fallback={<FallBack />}>
              <PrivateRoute>
                <AccountLogout />
              </PrivateRoute>
            </React.Suspense>
          }
        />
        {/* Account default navigation redirect */}
        <Route
          index
          path={appRoute.ACCOUNT}
          element={<Navigate to={appRoute.ACCOUNT_OVERVIEW} replace />}
        />

        {/* Public Paths */}
        <Route
          path={appRoute.NOT_FOUND}
          element={
            <React.Suspense fallback={<FallBack />}>
              <NotFound />
            </React.Suspense>
          }
        />
        {/* <Route path="*" element={<Navigate to={appRoute.TARIFF_OVERVIEW} replace />} /> */}
      </Route>

      {/* Activation Layout Routes */}
      <Route path={appRoute.ACTIVATION} element={<ActivationLayout />}>
        <Route
          path=""
          element={
            <React.Suspense fallback={<FallBack />}>
              <Activation />
            </React.Suspense>
          }
        />

        <Route
          path={appRoute.ACTIVATION_PAYMENT_CALLBACK}
          element={
            <React.Suspense fallback={<FallBack />}>
              <ActivationPaymentVerification />
            </React.Suspense>
          }
        />

        <Route
          path={appRoute.ACTIVATION_LEGITIMATION}
          element={
            <React.Suspense fallback={<FallBack />}>
              <ActivationLegitimation />
            </React.Suspense>
          }
        />

        <Route
          path={appRoute.ACTIVATION_LEGITIMATION_SUCCESS}
          element={
            <React.Suspense fallback={<FallBack />}>
              <ActivationLegitimationSuccess />
            </React.Suspense>
          }
        />

        <Route
          path={appRoute.ACTIVATION_LEGITIMATION_FAILED}
          element={
            <React.Suspense fallback={<FallBack />}>
              <ActivationLegitimationFailed />
            </React.Suspense>
          }
        />
      </Route>

      <Route path="*" element={<Navigate to={appRoute.NOT_FOUND} replace />} />
    </Routes>
  );
}

// AppRoutes.propTypes = {
//   location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired
// };

// export default withRouter(withAlert(withToast(withLayout(AppRoutes))));
export const AppRoutes = withLayout(AllRoutes);
export default AppRoutes;
