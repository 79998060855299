import React from 'react';
import { Text } from '@core/Inputs';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import PropTypes from 'prop-types';
import { appTextFieldLabelClass } from '@utils/globalConstant';

export function General({
  // salutation,
  // salutations,
  firstName,
  lastName,
  birthDate,
  handleChange,
  handleBlur,
  touched,
  errors
}) {
  const { t } = useStaticContent();
  return (
    <>
      <h4 className="mt-0 mb-2 pb-2">{t('ek_ap_personal_h1')}</h4>

      {/* <div className="row">
        {salutations.map((item) => (
          <div className="col-md-4 px-2 py-2" key={item.id}>
            <Radio
              label={item.value}
              id={`salutation-${item.id}`}
              name="salutation"
              value={item.id}
              isDefaultChecked={item.id === salutation}
              onChange={() => {
                handleChange({
                  target: {
                    id: `salutation_${item.value}`,
                    name: 'salutation',
                    value: item.id
                  }
                });
              }}
              isValid={touched.salutation && !errors.salutation}
              isInvalid={!!touched.salutation && !!errors.salutation}
              onBlur={handleBlur}
              error={touched.salutation && errors.salutation ? errors.salutation : null}
            />
          </div>
        ))}
      </div> */}
      <div className="row">
        <div className="personal-data">
          <Text
            type="text"
            labelCustomClass={appTextFieldLabelClass.V1}
            label={t('ek_ap_personal_first-name')}
            id="firstName"
            name="firstName"
            onChange={handleChange}
            value={firstName}
            placeholder={t('ek_ap_personal_first-name_input')}
            isValid={touched.firstName && !errors.firstName}
            isInvalid={!!touched.firstName && !!touched.firstName && !!errors.firstName}
            onBlur={handleBlur}
            error={touched.firstName && errors.firstName ? errors.firstName : null}
          />

          <Text
            type="text"
            labelCustomClass={appTextFieldLabelClass.V1}
            label={t('ek_ap_personal_last-name')}
            id="lastName"
            name="lastName"
            onChange={handleChange}
            value={lastName}
            placeholder={t('ek_ap_personal_last-name_input')}
            isValid={touched.lastName && !errors.lastName}
            isInvalid={!!touched.lastName && !!errors.lastName}
            onBlur={handleBlur}
            error={touched.lastName && errors.lastName ? errors.lastName : null}
          />

          <Text
            type="date"
            labelCustomClass={appTextFieldLabelClass.V1}
            label={t('ek_ap_personal_birth-date')}
            name="birthDate"
            id="birthDate"
            onChange={handleChange}
            value={birthDate}
            isValid={touched.birthDate && !errors.birthDate}
            isInvalid={!!touched.birthDate && !!touched.birthDate && !!errors.birthDate}
            onBlur={handleBlur}
            error={touched.birthDate && errors.birthDate ? errors.birthDate : null}
            onfocus={"this.type='date'"}
          />
        </div>
      </div>
    </>
  );
}

General.propTypes = {
  // salutation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // salutations: PropTypes.arrayOf(PropTypes.shape([])),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  birthDate: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({})
};

General.defaultProps = {
  // salutation: '',
  // salutations: [],
  firstName: '',
  lastName: '',
  birthDate: '',
  handleChange: () => {},
  handleBlur: () => {},
  touched: () => {},
  errors: {}
};

export default General;
