/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useSpeedOn, useTariff } from '@context/MobileOne';
import { useAlert, useLayout } from '@context/Utils';
import { LoadingScreen } from '@core/LoadingScreen';
import {
  appRoute,
  appTariffFailTypes,
  appTariffOptionSuccessType,
  displayPrice
} from '@utils/globalConstant';
import { PopupSupport, TarifTabsNavigation } from '@core/index';
import { Icons } from '@core/Utils';
import { FeedbackScreen } from '../FeedbackScreen';
import { NavigationPageTitle } from '../NavigationPageTitle';

export function TariffOptionLayout() {
  // States

  // Contexts
  const { t } = useStaticContent();
  const { isLoading } = useLayout();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    tariffOptionSuccess,
    setTariffOptionSuccess,
    tariffFailModal,
    setTariffFailModal,
    tarifPdfModal,
    setTariffPdfModal,
    vviDocuments,
    selectedTariff,
    selectedOption,
    selectedSpeedOn,
    selectedAdditionalOption,
    selectedBookableOption,
    isLoading: isTariffLoading
  } = useTariff();
  const { isGenericError, setIsGenericError, setAlert } = useAlert();
  const { onLoad, setDayFlat, setSpeedOns } = useSpeedOn();

  const [isOverview, setIsOverview] = useState(false);
  const [isTariffChange, setIsTariffChange] = useState(false);
  const [isAdditionalOptionBook, setIsAdditionalOptionBook] = useState(false);
  const [isOptionBook, setIsOptionBook] = useState(false);
  const [isSpeedOnBook, setIsSpeedOnBook] = useState(false);
  const [isBookableOptionOnBook, setIsBookableOptionOnBook] = useState(false);
  const [activeNavTab, setActiveNavTab] = useState(0);

  // Functions
  const onClick = (i) => {
    if (i === 0) {
      navigate(appRoute.TARIFF_OVERVIEW);
    } else if (i === 1) {
      navigate(appRoute.OPTION_OVERVIEW);
    } else if (i === 2) {
      navigate(appRoute.AUSLAND_OVERVIEW);
    } else {
      navigate(appRoute.TARIFF_OVERVIEW);
    }
  };

  // Function to get Tariff change and option book fail modal text.
  const getFailModalText = () => {
    switch (tariffFailModal) {
      case appTariffFailTypes.TARIFF:
        return `${t('nc_tarif_modal_err_txt')}`.replace(
          '{amount}',
          `${displayPrice(selectedTariff?.price)}`.replace('.', ',')
        );
      case appTariffFailTypes.SPEEDON:
        return `${t('nc_tarif_speedon_modal_err_txt')}`.replace(
          '{amount}',
          `${displayPrice(selectedSpeedOn.price.grossAmount / 10000)}`.replace('.', ',')
        );
      case appTariffFailTypes.DAYFLAT:
        return `${t('nc_tarif_option_modal_err_txt')}`.replace(
          '{amount}',
          `${displayPrice(selectedOption.price.grossAmount / 10000)}`.replace('.', ',')
        );
      case appTariffFailTypes.OPTION:
        return `${t('nc_tarif_extra_options_err_txt')}`
          .replace('{amount}', `${displayPrice(selectedBookableOption.price)}`)
          .replace('.', ',')
          .replace(
            '{Option Name}',
            selectedBookableOption.name || selectedBookableOption.displayName
          );
      default:
        return `${t('nc_tarif_modal_err_txt')}`;
    }
  };

  // Function to get Tariff change and option book success heading.
  const getSuccessHeading = (type) => {
    if (type === appTariffOptionSuccessType.TARIFF) {
      return `${t('nc_tarif_suc_hdl')}`.replace('{tariff_name}', selectedTariff?.name);
    }
    if (type === appTariffOptionSuccessType.OPTION) {
      return `${t('nc_tarif_options_suc_hdl')}`.replace(
        '{option_name}',
        selectedBookableOption?.name
      );
    }
    if (type === appTariffOptionSuccessType.ADDITIONAL_OPTION) {
      return `${t('nc_tarif_options_suc_hdl')}`.replace(
        '{option_name}',
        `${selectedAdditionalOption?.name}`
      );
    }
    const {
      volumeData: {
        volume: { formattedValue: volumeFormattedValue = '' }
      }
    } = selectedSpeedOn;
    return `${t('nc_tarif_options_suc_hdl')}`.replace(
      '{option_name}',
      `${selectedSpeedOn?.name} ${volumeFormattedValue}`
    );
    //  ${selectedSpeedOn?.volumeData?.volume?.formattedValue}
  };

  // Function to handle the click of fail modal button.
  const onClickFailModal = () => {
    setTariffFailModal('');
    navigate(appRoute.TOPUP_AUFLADEN_OVERVIEW);
  };

  // Function to handle the click of Tariff change and option book success screen button.
  const onSuccessClick = () => {
    setTariffOptionSuccess({ isSuccessful: false, type: '' });
    navigate(appRoute.DASHBOARD);
  };

  // Hooks
  useEffect(() => {
    if (pathname.includes('/overview')) {
      setIsOverview(true);
    } else {
      setIsOverview(false);
    }

    if (pathname.includes('/tariff/change')) {
      setIsTariffChange(true);
    } else {
      setIsTariffChange(false);
    }
    if (pathname.includes('/option/book')) {
      setIsOptionBook(true);
    } else {
      setIsOptionBook(false);
    }

    if (pathname.includes('/additional-option/book')) {
      setIsAdditionalOptionBook(true);
    } else {
      setIsAdditionalOptionBook(false);
    }

    if (pathname.includes('/passoffer/book')) {
      setIsSpeedOnBook(true);
    } else {
      setIsSpeedOnBook(false);
    }

    if (pathname.includes('/bookable-option/book')) {
      setIsBookableOptionOnBook(true);
    } else {
      setIsBookableOptionOnBook(false);
    }

    if (pathname.includes('/option/overview')) {
      setActiveNavTab(1);
    } else if (pathname.includes('/ausland/overview')) {
      setActiveNavTab(2);
    } else if (pathname.includes('/overview')) {
      setActiveNavTab(0);
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname.includes('/option/overview')) {
      setActiveNavTab(1);
    } else if (pathname.includes('/ausland/overview')) {
      setActiveNavTab(2);
    } else if (pathname.includes('/overview')) {
      setActiveNavTab(0);
    }
    setDayFlat([]);
    setSpeedOns([]);
    onLoad();
    setIsGenericError(false);
    setAlert({});
  }, []);

  // useEffect(() => {
  //   if (vviDocuments) {
  //     if (tarifPdfModal) {
  //       document.querySelector('#docA').addEventListener('click', () => {
  //         const linkSource = `data:application/pdf;base64,${vviDocuments.vzf}`;
  //         const downloadLink = document.createElement('a');
  //         downloadLink.href = linkSource;
  //         downloadLink.download = 'vzf.pdf';
  //         downloadLink.click();
  //         downloadLink.remove();
  //       });
  //       document.querySelector('#docB').addEventListener('click', () => {
  //         const linkSource = `data:application/pdf;base64,${vviDocuments.agb}`;
  //         const downloadLink = document.createElement('a');
  //         downloadLink.href = linkSource;
  //         downloadLink.download = 'agb.pdf';
  //         downloadLink.click();
  //         downloadLink.remove();
  //       });
  //       document.querySelector('#docC').addEventListener('click', () => {
  //         const linkSource = `data:application/pdf;base64,${vviDocuments.preisliste}`;
  //         const downloadLink = document.createElement('a');
  //         downloadLink.href = linkSource;
  //         downloadLink.download = 'preisliste.pdf';
  //         downloadLink.click();
  //         downloadLink.remove();
  //       });
  //       document.querySelector('#docD').addEventListener('click', () => {
  //         const linkSource = `data:application/pdf;base64,${vviDocuments.leistungsbeschreibung}`;
  //         const downloadLink = document.createElement('a');
  //         downloadLink.href = linkSource;
  //         downloadLink.download = 'leistungsbeschreibung.pdf';
  //         downloadLink.click();
  //         downloadLink.remove();
  //       });
  //     }
  //     // eslint-disable-next-line no-unused-expressions
  //     () => {
  //       document.querySelector('#docA').removeEventListener('click');
  //       document.querySelector('#docB').removeEventListener('click');
  //       document.querySelector('#docC').removeEventListener('click');
  //       document.querySelector('#docD').removeEventListener('click');
  //     };
  //   }
  // }, [tarifPdfModal, vviDocuments]);

  const downloadFile = async (base64File, fileName) => {
    const fileLink = `data:application/pdf;base64,${base64File}`;

    const base64Response = await fetch(fileLink);
    const blob = await base64Response.blob();

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = fileName || 'file.pdf';
    downloadLink.dispatchEvent(new MouseEvent('click', { bubbles: true, view: window }));
    window.URL.revokeObjectURL(downloadLink.href);
    downloadLink.remove();
  };

  // if (isLoading) {
  //   return <LoadingScreen />;
  // }

  return (
    <>
      {!isGenericError && (
        <div className="animate__animated animate__fadeIn container-page1 overflow-x-hidden">
          {tariffOptionSuccess.isSuccessful ? (
            <div className="container-md pb-0">
              <div className="row pt-1">
                <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12 mx-auto">
                  <div className="pt-10 pb-10 px-2 px-md-0">
                    <FeedbackScreen
                      icon="confetticolor"
                      type="success"
                      heading={getSuccessHeading(tariffOptionSuccess.type)}
                      buttonLabel={t('nc_tarif_suc_btn')}
                      onButtonClick={onSuccessClick}>
                      {tariffOptionSuccess.type === appTariffOptionSuccessType.TARIFF ? (
                        <div>
                          <p
                            className="m-0"
                            dangerouslySetInnerHTML={{ __html: t('nc_tarif_suc_title') }}
                          />
                          <p
                            className="py-8 m-0"
                            dangerouslySetInnerHTML={{ __html: t('nc_tarif_suc_txt') }}
                          />
                        </div>
                      ) : (
                        <div
                          className="pb-8"
                          dangerouslySetInnerHTML={{ __html: t('nc_tarif_options_suc_txt') }}
                        />
                      )}
                    </FeedbackScreen>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="container-md pb-0">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
                    <div className="">
                      {isOverview && (
                        <h1 className="animate__animated animate__fadeInDown nc-doomsday-h2 mb-0">
                          {t('nc_tarif_head_title')}
                        </h1>
                      )}
                    </div>
                    <div className="pb-4 row">
                      {!isTariffLoading && isTariffChange && (
                        <NavigationPageTitle
                          title={t('nc_tarif_details_hdl')}
                          variant="lg"
                          isBackButton
                          onButtonClick={() => navigate(appRoute.TARIFF_OVERVIEW)}
                        />
                      )}

                      {isAdditionalOptionBook && (
                        <NavigationPageTitle
                          title={t('nc_tarif_options_details_hdl')}
                          variant="lg"
                          isBackButton
                          onButtonClick={() => navigate(appRoute.OPTION_OVERVIEW)}
                        />
                      )}

                      {isOptionBook && (
                        <NavigationPageTitle
                          title={t('nc_tarif_options_details_hdl')}
                          variant="lg"
                          isBackButton
                          onButtonClick={() => navigate(appRoute.OPTION_OVERVIEW)}
                        />
                      )}
                      {isSpeedOnBook && (
                        <NavigationPageTitle
                          title={t('nc_tarif_speedon_details_hdl')}
                          variant="lg"
                          isBackButton
                          onButtonClick={() => navigate(appRoute.OPTION_OVERVIEW)}
                        />
                      )}
                      {isBookableOptionOnBook && (
                        <NavigationPageTitle
                          title={t('nc_tarif_options_extra_details_hdl')}
                          variant="lg"
                          isBackButton
                          onButtonClick={() => navigate(appRoute.OPTION_OVERVIEW)}
                        />
                      )}
                      {isOverview && (
                        <div className="col-lg-6 col-xs-12 ms-auto me-auto px-3 animate__animated animate__flipInX p-0 pt-8">
                          <TarifTabsNavigation defaultActive={activeNavTab} onChange={onClick} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {isLoading ? <LoadingScreen /> : <Outlet />}
            </>
          )}
        </div>
      )}

      <PopupSupport
        isOpen={tarifPdfModal}
        onClose={() => setTariffPdfModal(false)}
        title={t('nc_modal_vvi_hdl')}>
        <div>
          <ul className="list-unstyled mb-0">
            {vviDocuments && vviDocuments.vzf && (
              <li
                className="pb-4"
                type="button"
                onClick={() =>
                  downloadFile(vviDocuments.vzf, `${t('nc_tarif_modal_doc_txt1')}.pdf`)
                }>
                <div className="d-flex">
                  <Icons name="pdfcolor" />
                  <span className="nc-realtextpro-copy-bold-link ps-4">
                    {t('nc_tarif_modal_doc_txt1')}
                  </span>
                </div>
              </li>
            )}
            {vviDocuments && vviDocuments.agb && (
              <li
                className="pb-4"
                type="button"
                onClick={() =>
                  downloadFile(vviDocuments.agb, `${t('nc_tarif_modal_doc_txt2')}.pdf`)
                }>
                <div className="d-flex">
                  <Icons name="pdfcolor" />
                  <span className="nc-realtextpro-copy-bold-link ps-4">
                    {t('nc_tarif_modal_doc_txt2')}
                  </span>
                </div>
              </li>
            )}
            {vviDocuments && vviDocuments.preisliste && (
              <li
                className="pb-4"
                type="button"
                onClick={() =>
                  downloadFile(vviDocuments.preisliste, `${t('nc_tarif_modal_doc_txt3')}.pdf`)
                }>
                <div className="d-flex">
                  <Icons name="pdfcolor" />
                  <span className="nc-realtextpro-copy-bold-link ps-4">
                    {t('nc_tarif_modal_doc_txt3')}
                  </span>
                </div>
              </li>
            )}
            {vviDocuments && vviDocuments.leistungsbeschreibung && (
              <li
                className=""
                type="button"
                onClick={() =>
                  downloadFile(
                    vviDocuments.leistungsbeschreibung,
                    `${t('nc_tarif_modal_doc_txt4')}.pdf`
                  )
                }>
                <div className="d-flex">
                  <Icons name="pdfcolor" />
                  <span className="nc-realtextpro-copy-bold-link nav-link ps-4">
                    {t('nc_tarif_modal_doc_txt4')}
                  </span>
                </div>
              </li>
            )}
          </ul>
        </div>
      </PopupSupport>
      <PopupSupport
        isOpen={tariffFailModal !== ''}
        title={t('nc_tarif_modal_err_hdl')}
        rightButtonLabel={t('nc_tarif_modal_err_btn')}
        onClose={() => setTariffFailModal('')}
        onRightClick={onClickFailModal}
        bodyText={getFailModalText()}
      />
    </>
  );
}

export default TariffOptionLayout;
