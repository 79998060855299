// layout UI test

import {
  ActivationStep1,
  ActivationStep2,
  ActivationStep3,
  ActivationStep4,
  ActivationStep5,
  ActivationStep6
} from '@pages/Activation';
import Legitimation from '@pages/Activation/Step6/Legitimation';
import LegitimationFailed from '@pages/Activation/Step6/LegitimationFailed';
import LegitimationSuccess from '@pages/Activation/Step6/LegitimationSuccess';
import PaymentVerification from '@pages/Activation/Step6/PaymentVerification';
import SetAutoTopup from '@pages/Activation/Step6/SetAutoTopup';
import Home from '@pages/Home';
import AfterLegitimation from '@part/Legitimation';
import {
  AfterPaymentScreen,
  DashboardTariff,
  MainLayout,
  NavigationActivationBar,
  NavigationFooter
} from '@part/index';

import {
  appButtonTypes,
  appImages,
  appRoute,
  appTariffBulletGroup,
  appTariffPriceType
} from '@utils/globalConstant';
import { SpeedOnOption } from "@part/TariffOption";

export function Test2() {
  return (
    <>
      {/* test activation pages */}
      <div className="bg-mint-40 min-vh-100 d-flex flex-column animate__animated animate__fadeInUpBig">
        <NavigationActivationBar />
        <main className="flex-grow-1">
          <div className="container-page3">
            <div className="d-flex flex-column justify-content-center align-items-center">
              large speedon card
              <SpeedOnOption
                sizeVariant="large"
                // offerInfo={offer}
                priceType={appTariffPriceType.PRICE}
                showBulletGroup={[appTariffBulletGroup.DATA, appTariffBulletGroup.DESCRIPTION_ONE]}
              />
              <br></br>
              <br></br>
              small speedon card
              <SpeedOnOption
                sizeVariant="small"
                // offerInfo={offer}
                priceType={appTariffPriceType.PRICE}
                showBulletGroup={[appTariffBulletGroup.DATA, appTariffBulletGroup.DESCRIPTION_ONE]}
              />
            </div>
            {/* <ActivationStep1 /> */}
            {/* <ActivationStep2 /> */}
            {/* <ActivationStep3 /> */}
            {/* <ActivationStep6 /> */}
            {/* <ActivationStep4 /> */}
            {/* <ActivationStep5 /> */}
            {/* <ActivationStep6 /> */}
            {/* <Legitimation /> */}
            {/* <LegitimationSuccess /> */}
            {/* <AfterLegitimation heading={"test"} bodyText={"lorem ipsum"} /> */}
            {/* <LegitimationFailed /> */}
            {/* <PaymentVerification /> */}
          </div>
        </main>
        <NavigationFooter />
      </div>

      {/* text container and cols */}
      {/* <MainLayout showChildren>
        <div className="container-md container-page1 bg-mint-80">
          <div className="row">
            <div className="bg-accent col-lg-8 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
              test container-md, px-6 px-sm-0 px-md-3
            </div>
          </div>
        </div>
      </MainLayout> */}
    </>
  );
}
export default Test2;
