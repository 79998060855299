import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';

import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { StatusCodes, appAlert } from '@dom-digital-online-media/dom-app-config-sdk';
// import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne/Customer';
// import { useAlert } from '@context/Utils';
import { appTariffOptionSuccessType } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useTariff } from '../Tariff';
// import { useOption } from '../Option';

export const PassOfferContext = createContext({});

export function PassOfferContextProvider({ children }) {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [bookPassSuccess, setBookPassSuccess] = useState(false);

  // Context
  // const { t } = useStaticContent();
  // const { showAlert } = useAlert();
  // const { showLoader, hideLoader } = useLoader();
  const { getCustomerData } = useCustomer();
  const { showAlert, setIsGenericError } = useAlert();
  const { t } = useStaticContent();
  // const { getAllOptions, getBookableOptions, getBookedOptions } = useOption();
  const { onBookingPass } = useMobileOne();
  const { setTariffOptionSuccess } = useTariff();
  // const navigate = useNavigate();

  // Validations

  // Functions
  // Book pass offer if possible function for book option
  const bookPassOffer = async (Code) => {
    try {
      setIsLoading(true);
      // showLoader();
      const { data, success } = await onBookingPass({ passCode: Code });
      if (success) {
        setTariffOptionSuccess({ isSuccessful: true, type: appTariffOptionSuccessType.OPTION });
        // setBookPassSuccess(!bookPassSuccess);
        // showAlert({
        //   type: appAlert.SUCCESS,
        //   message: 'Successfully updated tariff'
        // });
      } else {
        // showAlert({
        //   type: appAlert.ERROR,
        //   message: 'Error in processing, try again after sometime.'
        // });
      }
      await getCustomerData();
      setIsLoading(false);
      // hideLoader();

      return data;
    } catch (error) {
      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        setIsGenericError(true);
        showAlert({
          type: appAlert.ERROR,
          message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
        });
        // showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      setIsLoading(false);
      return error;
    }
  };

  // Book pass offer if possible function for book option
  const bookPassOfferSpeedOn = async (Code, isAdditionalOption = false) => {
    try {
      setIsLoading(true);
      // showLoader();
      const { data, success } = await onBookingPass({ passCode: Code });
      if (success) {
        setTariffOptionSuccess({
          isSuccessful: true,
          type: isAdditionalOption
            ? appTariffOptionSuccessType.ADDITIONAL_OPTION
            : appTariffOptionSuccessType.SPEED_ON
        });
        // setBookPassSuccess(!bookPassSuccess);
        // showAlert({
        //   type: appAlert.SUCCESS,
        //   message: 'Successfully updated tariff'
        // });
      } else {
        // showAlert({
        //   type: appAlert.ERROR,
        //   message: 'Error in processing, try again after sometime.'
        // });
      }
      await getCustomerData();
      setIsLoading(false);
      // hideLoader();

      return data;
    } catch (error) {
      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        setIsGenericError(true);
        showAlert({
          type: appAlert.ERROR,
          message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
        });
        // showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      setIsLoading(false);
      return error;
    }
  };

  // Hooks

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      bookPassOffer,
      bookPassSuccess,
      setBookPassSuccess,
      bookPassOfferSpeedOn
    }),
    [
      // States
      isLoading,
      setIsLoading,
      bookPassOffer,
      bookPassSuccess,
      setBookPassSuccess,
      bookPassOfferSpeedOn
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <PassOfferContext.Provider value={contextPayload}>{children}</PassOfferContext.Provider>;
}
PassOfferContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const usePassOffer = () => useContext(PassOfferContext);

export default PassOfferContext;
