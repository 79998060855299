/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@core/Inputs';

export function PaymentProduct({
  id,
  key,
  amount,
  // productType,
  // minAmount,
  // maxAmount,

  // currency,
  // priceRange,

  // channel,
  // optionType,

  isSelected,
  onSelect
}) {
  return (
    <div key={key} onClick={() => onSelect(id)}>
      <Radio
        inline
        type="radio"
        label={`${amount} €`}
        id={`payment-product-${id}`}
        name="productId"
        isDefaultChecked={isSelected}
        onClick={() => onSelect(id)}
      />
    </div>
  );
}

PaymentProduct.propTypes = {
  id: PropTypes.number,
  // productType: PropTypes.string,
  // minAmount: PropTypes.number,
  // maxAmount: PropTypes.number,
  // currency: PropTypes.string,
  // priceRange: PropTypes.string,
  // channel: PropTypes.string,
  // optionType: PropTypes.string,
  amount: PropTypes.number,
  // isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func
};

PaymentProduct.defaultProps = {
  id: '',
  // productType: '',
  // minAmount: '',
  // maxAmount: '',
  // currency: '',
  // priceRange: '',
  // channel: '',
  // optionType: '',
  amount: '',
  onSelect: () => {}
};

export default PaymentProduct;
