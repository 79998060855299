import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAlphaComm } from '@dom-digital-online-media/dom-alphacomm-sdk';

import { LoadingScreen } from '@core/LoadingScreen';
import { AfterPaymentScreen } from '@part/TopUp';
import { useActivation } from '@context/MobileOne';
import { appPaymentOrderStatus, appRoute } from '@utils/globalConstant';

export function PaymentVerification() {
  // States
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCreationPending, setIsCreationPending] = useState(false);

  const [failureMsg, setFailureMsg] = useState('');

  // Contexts
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { onPaymentCommitOrder } = useAlphaComm();
  const { setCurrentStep, onTopupErrorClick } = useActivation();
  const { t } = useStaticContent();

  // Functions
  const orderVerification = async (params) => {
    setIsLoading(true);
    try {
      const { data = {}, success } = await onPaymentCommitOrder(params);

      setIsLoading(false);
      if (data && success) {
        setIsSuccess(true);
        if (data?.orderStatus === appPaymentOrderStatus.CREATION_PENDING) {
          setIsCreationPending(true);
        }
      } else {
        setIsSuccess(false);
      }
    } catch (error) {
      console.log(error);
      setFailureMsg(error?.error[0]?.messageBody);
      // setIsFailureModalOpen(true);
      setIsLoading(false);
    }
  };

  // Hooks
  useEffect(() => {
    setIsLoading(true);
    setCurrentStep(5);
    const queryParamsData = {
      paymentToken: queryParams.get('paymentTokenId'),
      orderNumber: queryParams.get('orderNumber'),
      cartName: queryParams.get('cartName')
    };

    if (queryParamsData.paymentToken || queryParamsData.orderNumber || queryParamsData.cartName) {
      orderVerification(queryParamsData);
    } else {
      setIsLoading(false);
    }
    // TODO REDIRECT TO HOME PAGE
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="container-md">
      <div className="row py-16">
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-8 col-sm-7 col-xs-12 mx-auto px-6 px-sm-0 px-md-3">
          {isSuccess ? (
            <div>
              <AfterPaymentScreen
                icon="layerdarkgreen"
                heading={t(isCreationPending ? 'nc_generic_autotopup_suc_hdl': 'nc_reg_step6_topup_suc_hdl')}
                bodyText={t(isCreationPending ? 'nc_generic_autotopup_suc_pending_title':'nc_reg_step6_topup_suc_desc')}
                buttonLabel={t(isCreationPending ? 'nc_generic_auto_topup_suc_pending_btn': 'nc_reg_step6_topup_suc_btn')}
                buttonIcon="arrowright"
                onButtonClick={() => {
                  navigate(appRoute.ACTIVATION_LEGITIMATION);
                }}
              />
            </div>
          ) : (
            <div>
              <AfterPaymentScreen
                icon="errorcolor"
                heading={t('nc_generic_err_hdl')}
                bodyText={failureMsg}
                buttonLabel={t('nc_generic_err_btn')}
                onButtonClick={onTopupErrorClick}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentVerification;
