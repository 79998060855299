import React, { useEffect, useState } from 'react';
import { appTariffBulletGroup, appTariffPriceType } from '@utils/globalConstant';

import './TariffProduct.scss';
import { Icons } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export function TariffProduct({
  tariffInfo: {
    // primaryColor,
    // secondaryColor,
    // backgroundColor,
    title,
    ribbonText,
    bullets,
    price
    // starterPackPrice
  },
  showRibbonText = false,
  showBulletGroup = [appTariffBulletGroup.DATA, appTariffBulletGroup.DESCRIPTION_ONE], // appTariffBulletGroup // PROMOTIONAL_DATA | DATA  | DESCRIPTION_ONE | DESCRIPTION_TWO | ROAMING_INCLUDE | OTHER
  // eslint-disable-next-line max-len
  // tariffStatus = false, // appTariffStatus // ACTIVATION_PENDING, ACTIVE, REJECTED, LOCKED, LOCK_REQUESTED, DEACTIVATION_REQUESTED, PAUSED, TERMINATION_REQUESTED, UNLOCK_REQUESTED, TERMINATED, ACTIVATION_REQUESTED, SENT, CANCELLATION_REQUESTED, CANCELLED, NOT_AVAILABLE, DEFECT, EXPORTED, UNKNOWN
  priceType = 'price', // appTariffPriceType // price | starterPackPrice
  sizeVariant = 'small', // large, small
  isActive = false,
  isPaused = false
}) {
  const { t } = useStaticContent();

  const [filterBulletGroup, setFilterBulletGroup] = useState([]);

  useEffect(() => {
    if (showBulletGroup.length > 0) {
      setFilterBulletGroup(
        bullets.filter((bullet) => showBulletGroup.includes(bullet.bulletGroup))
      );
    }
  }, [bullets, showBulletGroup]);

  const isCardLarge = sizeVariant === 'large';

  return (
    <div className="tariff-product h-100">
      {/* Size variant */}
      <div
        className={`${
          isCardLarge ? 'large-card' : 'default-card'
        } tariff-card position-relative flex-grow-1`}>
        <>
          {(isActive || isPaused) && (
            // <div className="active-tariff h-100 w-100 border border-2 border-gray-20 rounded-4 d-flex flex-column align-items-start justify-content-center">
            //   <div
            //     className="d-flex align-items-center justify-content-center"
            //     style={{
            //       backgroundImage: `url(data:image/svg+xml;utf8,${encodeURIComponent(
            //         title.content
            //       )})`,
            //       backgroundRepeat: 'no-repeat',
            //       backgroundPosition: 'center',
            //       backgroundSize: 'contain',
            //       width: '100%',
            //       // height: isCardLarge ? '78px' : '70px'
            //       height: isCardLarge ? '85px' : '72px'
            //     }}>
            //     {isCardLarge ? (
            //       <h3 className="nc-doomsday-h3 text-white w-100 text-center m-0 p-0 pt-1 mt-n4">
            //         {title.text}
            //       </h3>
            //     ) : (
            //       <h4 className="nc-doomsday-h4 text-white w-100 text-center m-0 p-0 pt-1 mt-n4">
            //         {title.text}
            //       </h4>
            //     )}
            //   </div>
            //   <div className="flex-grow-1 w-100 mt-n7 d-flex flex-column align-items-center justify-content-center">
            //     <div className="">
            //       <Icons name={isActive ? "checkdarkgreen" : "groupdarkgreen"} size={24} />
            //     </div>
            //     <div
            //       className="py-5 nc-doomsday-h4 fw-medium text-nowrap"
            //       dangerouslySetInnerHTML={{
            //         __html: isActive ? t('nc_tarif_active') : t('nc_tarif_paused')
            //       }}
            //     />
            //   </div>
            // </div>

            <div className="active-tariff py-4 h-100 w-100 border border-2 border-gray-20 rounded-4 d-flex flex-column align-items-center justify-content-center">
              {title?.tariffCardHead && (
                <div
                  className="tariff-logo"
                  dangerouslySetInnerHTML={{ __html: title?.tariffCardHead }}
                />
              )}

              <div className="active-tariff-content flex-grow-1 w-100 mt-n7 d-flex flex-column align-items-center justify-content-center">
                <div className="">
                  <Icons name={isActive ? 'checkdarkgreen' : 'groupdarkgreen'} size={24} />
                </div>
                <div
                  className="py-5 nc-doomsday-h4 fw-medium text-nowrap"
                  dangerouslySetInnerHTML={{
                    __html: isActive ? t('nc_tarif_active') : t('nc_tarif_paused')
                  }}
                />
              </div>
            </div>
          )}
        </>

        <div className="bg-white pt-4 border border-2 border-gray-20 rounded-4 h-100 w-100">
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            {title?.tariffCardHead && (
              <div
                className="tariff-logo"
                dangerouslySetInnerHTML={{ __html: title?.tariffCardHead }}
              />
            )}

            {/* Ribbon */}
            {showRibbonText && (
              <div
                className="ribbon-text d-flex align-items-center justify-content-center w-100 mt-4 text-center"
                style={{
                  backgroundColor: ribbonText.backgroundColor,
                  color: ribbonText.textColor
                }}
                dangerouslySetInnerHTML={{ __html: ribbonText.content }}
              />
            )}

            {/* Bullets */}
            {filterBulletGroup && filterBulletGroup.length > 0 && (
              <div className="tariff-info flex-grow-1 py-3 mx-2 w-100 text-center">
                {filterBulletGroup.map((bullet, index) => (
                  <div
                    key={index}
                    className="pt-1"
                    dangerouslySetInnerHTML={{ __html: bullet.content }}
                  />
                ))}
              </div>
            )}
            {/* Price */}
            {priceType === appTariffPriceType.PRICE ? (
              <div
                //  className="tariff-price w-100 text-center rounded-bottom-4"
                className="tariff-price w-100 text-center"
                style={{
                  borderBottomLeftRadius: '28px',
                  borderBottomRightRadius: '28px',
                  backgroundColor: price.backgroundColor,
                  color: price.textColor
                }}>
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: price.content
                  }}
                />
              </div>
            ) : (
              <div
                // className="tariff-price w-100 text-center rounded-bottom-4"
                className="tariff-price w-100 text-center"
                style={{
                  borderBottomLeftRadius: '28px',
                  borderBottomRightRadius: '28px',
                  backgroundColor: price.backgroundColor,
                  color: price.textColor
                }}>
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: price.content
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TariffProduct;
